import { getOnlyNumbers } from 'utils/currency';
import { parcelsAmountSum, parcelsHoursSum } from 'utils/parcels';
import moment from 'moment';
import { formatDateToISO8601 } from 'utils/date-time.utils';
import {
  calculateValuesForAssociations,
  calculateValuesForProjects,
  parsedDataForAssociations,
  parsedDataForProjects,
} from 'utils/calculate-balance';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';

const parcelsSum = parcels => {
  const numbers = parcels.map(parcel => parseInt(getOnlyNumbers(parcel.value)));
  const total = numbers.reduce((accumulator, value) => accumulator + value, 0);
  return total / 100;
};

const updatePenaltiesParser = (
  { pecuniary, ressarciment, materials, services, lectures, geralPenalties },
  { institutionType, institutionTypeOptions },
) => {
  let resarcimentDescription = '';
  let projects = [];
  let associations = [];
  const pecuniaryPenalties = [];
  const compensationToVictims = [];
  const materialsForAssociations = [];
  const communityServices = [];
  const socioEducationalLecturePenalties = [];
  const generalPenalties = [];

  if (pecuniary && pecuniary?.length > 0) {
    pecuniary.forEach(value => {
      let newPecuniaryPenalties = {
        id: value.id,
        associationId: value.association?.id,
        paymentDeadline: value.parcels[value.parcels.length - 1].date,
        dueDateType: 'VARIABLE',
        description: value.description,
        projectId: value.project?.id || undefined,
        value: [
          INSTALLMENT_TYPES.VARIABLE_PER_PERIOD,
          INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE,
        ].includes(value.installmentType)
          ? Number(value.totalValue)
          : Math.round(parcelsSum(value.parcels) * 100),
        type: value.type || undefined,
        installmentType: value.installmentType || undefined,
      };

      if (value.project) {
        newPecuniaryPenalties.projectId = value.project.id;
      }

      let installments = [];
      if (value.parcels.length > 0) {
        value.parcels.forEach(parcel => {
          let newInstallment = {
            value: Number(getOnlyNumbers(parcel.value)),
            initialDate:
              value.installmentType === INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE
                ? parcel.initialDate
                : parcel.date,
            dueDate: parcel.date,
            minimumValue: parcel.minimumValue ? Number(getOnlyNumbers(parcel.minimumValue)) : null,
            maximumValue: parcel.maximumValue ? Number(getOnlyNumbers(parcel.maximumValue)) : null,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newPecuniaryPenalties.installments = installments;
      }

      pecuniaryPenalties.push(newPecuniaryPenalties);
    });

    if (institutionType === institutionTypeOptions.final) {
      const pecuniaryPenaltiesWithProject = pecuniaryPenalties.filter(({ projectId }) => projectId);

      if (pecuniaryPenaltiesWithProject.length) {
        projects = projects.concat(
          calculateValuesForProjects(pecuniaryPenaltiesWithProject, 'pecuniaryValue'),
        );
      }
    }
  }

  if (ressarciment && ressarciment?.length > 0) {
    ressarciment.forEach(item => {
      let newRessarciment = { ...item };
      delete newRessarciment.notification;

      let newcompensationToVictims = {
        id: newRessarciment.id,
        description: newRessarciment.description,
        victimId: newRessarciment.victim?.id,
        estimatedValue: Number(getOnlyNumbers(newRessarciment.estimatedValue)),
        associationId: newRessarciment.association?.id,
        dueDateType: 'FIXED',
        numberInstallments: 1,
      };

      let installments = [];
      if (newRessarciment.installments?.length > 0) {
        newRessarciment.installments.forEach(item => {
          let newInstallment = {
            value: item.value,
            initialDate: item.initialDate,
            finalDate: item.date,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newcompensationToVictims.installments = installments;
      }

      if (newRessarciment.deliveryDeadline) {
        newcompensationToVictims.deliveryDeadline = newRessarciment.deliveryDeadline;
      }

      if (newRessarciment.dateVictimCanReceive) {
        newcompensationToVictims.dateVictimCanReceive = newRessarciment.dateVictimCanReceive;
      }

      compensationToVictims.push(newcompensationToVictims);

      if (services.length < 1 && materials.length < 1 && pecuniary.length < 1) {
        resarcimentDescription = newRessarciment.description;
      }
    });
  }

  if (materials && materials?.length > 0) {
    materials.forEach(item => {
      let newMaterials = { ...item };

      let newMaterialsObject = {
        id: newMaterials.id,
        targetNecessityId: newMaterials.relation?.id,
        dueDateType: 'VARIABLE',
        deliveryDeadline: newMaterials.parcels[newMaterials.parcels.length - 1].date || null,
        amount: parcelsAmountSum(newMaterials.parcels),
        materialName: newMaterials.material?.name,
        description: newMaterials.description,
        materialId: newMaterials.material?.id,
      };

      if (newMaterials?.relation?.association) {
        newMaterialsObject.associationId = newMaterials.relation.association.id;
        newMaterialsObject.associationName = newMaterials.relation.association.name;
      }

      if (newMaterials.project) {
        newMaterialsObject.projectId = newMaterials.project.id;
      }

      let installments = [];
      if (newMaterials.parcels.length > 0) {
        newMaterials.parcels.forEach(parcel => {
          const newInstallment = {
            value: parseInt(parcel.amount),
            initialDate: parcel.date || null,
            dueDate: parcel.date || null,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newMaterialsObject.installments = installments;
      }

      materialsForAssociations.push(newMaterialsObject);
    });
    if (institutionType === institutionTypeOptions.final) {
      const materialsForAssociationsWithProject = materialsForAssociations.filter(
        ({ projectId }) => projectId,
      );

      if (materialsForAssociationsWithProject.length) {
        projects = projects.concat(
          calculateValuesForProjects(materialsForAssociationsWithProject, 'materialQuantity'),
        );
      }

      associations = associations.concat(
        calculateValuesForAssociations(
          materialsForAssociations,
          'materialName',
          'materialQuantity',
        ),
      );
    }
  }

  if (services && services?.length > 0) {
    services.forEach(item => {
      let newServices = { ...item };

      let newServicesObject = {
        id: newServices.id,
        targetNecessityId: newServices.relation?.id,
        dueDateType: 'VARIABLE',
        description: newServices.description,
        inicialDate: newServices.parcels[0].date,
        finalDate: newServices.parcels[newServices.parcels.length - 1].date,
        hours: [
          INSTALLMENT_TYPES.VARIABLE_PER_PERIOD,
          INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE,
        ].includes(newServices.installmentType)
          ? Number(newServices.totalValue)
          : parcelsHoursSum(newServices.parcels),
        serviceName: newServices.service?.name,
        serviceId: newServices.service?.id,
        installmentType: newServices.installmentType || undefined,
      };

      let installments = [];
      if (newServices.parcels.length > 0) {
        newServices.parcels.forEach(parcel => {
          const newInstallment = {
            value: parseInt(parcel.hours) || 0,
            initialDate:
              newServices.installmentType === INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE
                ? parcel.initialDate
                : parcel.date,
            dueDate: parcel.date,
            minimumValue: parcel.minimumValue ? Number(getOnlyNumbers(parcel.minimumValue)) : null,
            maximumValue: parcel.maximumValue ? Number(getOnlyNumbers(parcel.maximumValue)) : null,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newServicesObject.installments = installments;
      }

      if (newServices?.relation?.association) {
        newServicesObject.associationId = newServices.relation.association.id;
        newServicesObject.associationName = newServices.relation.association.name;
      }

      if (newServices.project) {
        newServicesObject.projectId = newServices.project.id;
      }

      communityServices.push(newServicesObject);
    });

    if (institutionType === institutionTypeOptions.final) {
      const communityServicesWithProject = communityServices.filter(({ projectId }) => projectId);

      if (communityServicesWithProject.length) {
        projects = projects.concat(
          calculateValuesForProjects(communityServicesWithProject, 'serviceHour'),
        );
      }

      associations = associations.concat(
        calculateValuesForAssociations(communityServices, 'serviceName', 'serviceHour'),
      );
    }
  }

  if (lectures && lectures?.length > 0) {
    lectures.forEach(item => {
      let newLecture = { ...item };

      let newLectureObject = {
        id: newLecture.id,
        associationId: newLecture.association?.id,
        projectId: newLecture.project?.id,
        description: newLecture.description,
        expectedDate: newLecture.expectedDate,
        type: newLecture.type || undefined,
      };

      let installments = [];
      if (newLecture.installments?.length > 0) {
        newLecture.installments.forEach(item => {
          let newInstallment = {
            value: item.value,
            initialDate: item.initialDate,
            finalDate: item.date,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newLectureObject.installments = installments;
      }

      if (newLecture.link) {
        newLectureObject.link = newLecture.link;
      }

      socioEducationalLecturePenalties.push(newLectureObject);
    });
  }

  if (geralPenalties && geralPenalties?.length > 0) {
    geralPenalties.forEach(item => {
      let newGeralPenalties = { ...item };

      let newGeralPenaltiesObject = {
        id: newGeralPenalties.id,
        description: newGeralPenalties.description,
        associationId: newGeralPenalties.association?.id,
        projectId: newGeralPenalties.project?.id,
        type: newGeralPenalties.type || undefined,
      };

      let installments = [];
      if (newGeralPenalties.installments?.length > 0) {
        newGeralPenalties.installments.forEach(item => {
          let newInstallment = {
            value: item.value,
            initialDate: item.initialDate,
            finalDate: item.date,
          };
          installments.push(newInstallment);
        });
      }

      if (installments.length > 0) {
        newGeralPenaltiesObject.installments = installments;
      }

      generalPenalties.push(newGeralPenaltiesObject);
    });
  }

  if (projects.length) {
    projects = parsedDataForProjects(projects);
  }

  if (associations.length) {
    associations = parsedDataForAssociations(associations);
  }
  return {
    description: resarcimentDescription,
    pecuniaryPenalties,
    compensationToVictims,
    materialsForAssociations,
    communityServices,
    generalPenalties,
    projects,
    associations,
    socioEducationalLecturePenalties,
  };
};

export default updatePenaltiesParser;
