import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Paper, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import uuid from 'uuid/v1';
import colors from 'utils/colorsSteps';
import { AiOutlineFileWord, AiOutlineFilePdf, AiOutlineFile } from 'react-icons/ai';
import { FaFileVideo } from 'react-icons/fa';
import { FileTypes } from 'constants/file-types';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(() => ({
  content: {
    flexDirection: 'column',
    paddingLeft: '16px',
    '&:before': {
      padding: '0 8px',
    },
  },
  dotIcon: {
    fontSize: '5px',
    color: 'transparent',
  },
  row: {
    display: 'flex',
  },
  separator: {
    marginBottom: '-40px',
  },
  message: {
    marginBottom: '10px',
    padding: '10px',
    width: '100%',
    textAlign: 'left',
    font: ".8em 'Open Sans', sans-serif",
    borderRadius: '10px',
  },
  dateWrapper: {
    textAlign: 'center',
  },
  date: {
    textAlign: 'center',
    borderRadius: '10px',
    backgroundColor: '#d6d6d6',
    color: '#454545',
    padding: '5px',
    margin: '10px 0',
    display: 'inline-flex',
    font: ".7em 'Open Sans', sans-serif",
  },
  icon: {
    width: '20px',
    height: '20px',
    color: 'gray',
    verticalAlign: 'bottom',
    marginRight: '10px',
  },
  button: {
    textTransform: 'none',
    fontSize: '11px',
    textAlign: 'left',
  },
  hour: {
    color: 'gray',
    fontSize: '0.8em',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentFile: {
    flexDirection: 'column',
    paddingLeft: '16px',
    '&:before': {
      padding: '0 8px',
    },
    overflowX: 'hidden',
  },
}));

const FilesCard = props => {
  const { file, index, selectedIndex, handleClick, selected, last, openFileViewer } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const fileTypesColors = {
    Geral: colors.NAO_IDENTIFICADO,
    'Intimação Investigado': colors.INTIMACAO,
    'Arquivos da tarefa': colors.NAO_IDENTIFICADO,
    'Arquivos do acordo': colors.AUDIENCIA,
    [`Arquivos de audiências${
      enableHearingForJuvenileOffenders ? '/oitivas' : ''
    }`]: colors.AUDIENCIA,
    'Conformidade do acordo': colors.TERMO_DE_ENCAMINHAMENTO,
    'Super Certidão': colors.NAO_IDENTIFICADO,
    'Arquivos de requisitório': colors.REQUISITORIO,
    'Certificados de arquivamento': colors.NAO_IDENTIFICADO,
  };

  const classes = useStyles();

  const getFileType = file => {
    if (file.mimetype) {
      return file.mimetype.match(/\/([^/]+)$/)[1];
    }
    return file.name.match(/\.([^.]+)$/)[1].toLowerCase();
  };

  const getFileTypeIcon = file => {
    const fileType = getFileType(file);
    switch (fileType) {
      case FileTypes.DOC:
      case FileTypes.DOCX:
        return <AiOutlineFileWord className={classes.icon} />;
      case FileTypes.PDF:
        return <AiOutlineFilePdf className={classes.icon} />;
      case FileTypes.MP4:
        return <FaFileVideo className={classes.icon} />;
      default:
        return <AiOutlineFile className={classes.icon} />;
    }
  };

  return (
    <TimelineItem className={classes.content}>
      {index === 0 && file.length && (
        <div className={classes.dateWrapper}>
          <div key={`${index}-date`} className={classes.date}>
            {file[0].date}
          </div>
        </div>
      )}
      <div
        key={selectedIndex}
        style={{
          backgroundColor: selected === selectedIndex ? 'hsl(198.46deg 22.03% 88.43%)' : '#eaf0f3',
        }}
        className={classes.row}
      >
        <TimelineSeparator className={last ? '' : classes.separator}>
          <Tooltip title={file.length ? file[0].fileType?.description : ''} arrow placement="right">
            <TimelineDot style={{ backgroundColor: fileTypesColors[file[0].fileType?.description] || colors.NAO_IDENTIFICADO }}>
              <LaptopMacIcon className={classes.dotIcon} />
            </TimelineDot>
          </Tooltip>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.contentFile}>
          <Paper className={classes.message}>
            {file.map(subFile => (
              <Button
                key={subFile.id || uuid()}
                variant="text"
                onClick={() => {
                  handleClick(selectedIndex);
                  openFileViewer(subFile);
                }}
                className={classes.button}
              >
                {getFileTypeIcon(subFile)}
                {subFile.name}
              </Button>
            ))}
            {file.length && <div className={classes.hour}>{file[0].hour}</div>}
          </Paper>
        </TimelineContent>
      </div>
    </TimelineItem>
  );
};

export default FilesCard;
