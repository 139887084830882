import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, Typography, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { HeaderModal } from 'components';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SendIcon from '@material-ui/icons/Send';
import CancelSendIcon from '@material-ui/icons/CancelScheduleSend';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import Timeline from './components/Timeline';
import TimelineEvent from './components/TimelineEvent';
import { formatDateAndHoursToBrazilian } from 'utils/date-time.utils';
import { parseServiceMessage, parseSroMessage } from './constants';
import IntimationService from 'services/intimation/intimationService';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#00597b',
  },
  textTitle: {
    color: '#ffffff',
  },
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  closeButton: {
    color: '#ffffff',
  },
  header: {
    backgroundColor: '#00597b',
    color: '#ffffff',
    paddingLeft: 30,
  },
  body: {
    backgroundColor: '#ededed',
    borderRadius: 5,
    padding: 10,
    marginBottom: theme.spacing(1),
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: theme.spacing(2),
  },
  label2: {
    color: 'gray',
    fontWeight: 'noraml',
    fontSize: 12,
  },
}));

const ModalTrackingMail = props => {
  const { open, onClose, className, trackingCode, intimation, intimationAttempt, ...rest } = props;

  const [arSteps, setArSteps] = useState([]);
  const [loading, setLoading] = useState(false);

  const parseSteps = arStep => {
    switch (arStep.step) {
      case 'ARQUIVO_SERVICO':
        return {
          ...arStep,
          icon: FindInPageIcon,
          color: '#edc654',
          title: 'Em análise',
        };
      case 'NOTIFICACAO_ARQUIVO_SERVICO':
        return {
          ...arStep,
          icon: arStep?.inconsistencyType ? CancelSendIcon : SendIcon,
          color: arStep?.inconsistencyType ? '#dd9572' : '#72badd',
          title: arStep?.inconsistencyType ? 'Inconsistência' : 'A caminho',
          tooltip: parseServiceMessage(arStep),
        };
      case 'DEVOLUCAO_AR':
        return {
          ...arStep,
          icon: ContactMailIcon,
          color: arStep.devolutionSro !== '1' ? '#c3627a' : '#62c3ab',
          title: 'Retorno',
          tooltip: parseSroMessage(arStep.devolutionSro),
        };
      case 'ARQUIVO_CANCELAMENTO':
        return {
          ...arStep,
          icon: CancelScheduleSendIcon,
          color: '#de5c4d',
          title: 'Intimação cancelada',
        };
      default:
        return { ...arStep, icon: HelpOutlineIcon, color: 'grey', title: 'Indefinido' };
    }
  };

  const parseDate = date => {
    return date ? formatDateAndHoursToBrazilian(date) : '';
  };

  const subpoenaName = intimation => {
    const inquiryClasse = intimation.inquiryInvestigated.inquiry?.classe;
    const investigated = intimation.inquiryInvestigated.investigated;
    const useRepresentantLegalName = isInfraction(inquiryClasse);
    if (useRepresentantLegalName && investigated.representantLegal) {
      return investigated.representantLegal.name;
    }
    return investigated.name;
  };

  const calcPlaceholderCount = arSteps => {
    if (!arSteps?.length) {
      return 3;
    }
    if (arSteps[arSteps.length - 1].step === 'ARQUIVO_SERVICO') {
      return 2;
    }
    if (arSteps[arSteps.length - 1].step === 'NOTIFICACAO_ARQUIVO_SERVICO') {
      return arSteps[arSteps.length - 1].inconsistencyType ? 0 : 1;
    }
    if (
      arSteps[arSteps.length - 1].step === 'DEVOLUCAO_AR' ||
      arSteps[arSteps.length - 1].step === 'ARQUIVO_CANCELAMENTO'
    ) {
      return 0;
    }
  };

  useEffect(() => {
    setLoading(true);
    IntimationService.getArSteps(intimationAttempt.id)
      .then(response => {
        setArSteps(
          response.data
            .sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
            .map(arStep => parseSteps(arStep)),
        );
      })
      .finally(() => setLoading(false));
  }, [intimationAttempt.id]);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Acompanhamento de intimação</HeaderModal>
        <DialogContent>
          <div
            style={{
              width: '100%',
              paddingTop: 5,
            }}
          >
            <Typography className={classes.label}>
              {subpoenaName(intimation) || ''}
              <br />
              {trackingCode ? (
                <>
                  <span className={classes.label2}>{trackingCode}</span>
                  <Tooltip title="Copiar código de rastreamento" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(trackingCode);
                      }}
                    >
                      <FileCopyIcon style={{ height: '12px', color: 'gray' }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <span style={{ height: '16px', display: 'inline-block' }} />
              )}
            </Typography>
            {loading ? (
              <Timeline placeholderCounter={3} placeholder>
                <TimelineEvent icon={MarkunreadMailboxIcon} title="Carregando..." subtitle="" />
              </Timeline>
            ) : (
              <Timeline placeholderCounter={calcPlaceholderCount(arSteps)} placeholder>
                <TimelineEvent
                  icon={MarkunreadMailboxIcon}
                  title="Intimação solicitada"
                  subtitle={parseDate(intimation.createdAt)}
                />
                {arSteps.map((arStep, index) => (
                  <TimelineEvent
                    key={index}
                    color={arStep.color}
                    icon={arStep.icon}
                    title={arStep.title}
                    subtitle={
                      <span>
                        {arStep.tooltip && (
                          <Tooltip arrow title={arStep.tooltip} placement="top">
                            <HelpOutlineIcon
                              style={{
                                height: '16px',
                                color: arStep.devolutionSro !== '1' ? '#c3627a' : '#62c3ab',
                                verticalAlign: 'sub',
                              }}
                            />
                          </Tooltip>
                        )}
                        <Typography variant="caption">{parseDate(arStep.createdAt)}</Typography>
                        {arStep.url && (
                          <a
                            href={arStep.url}
                            style={{ position: 'relative', top: '-2px' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Tooltip title="Baixar carta AR" placement="top">
                              <IconButton size="small">
                                <GetAppIcon
                                  style={{
                                    height: '16px',
                                    color: arStep.devolutionSro !== '1' ? '#c3627a' : '#62c3ab',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </a>
                        )}
                      </span>
                    }
                  />
                ))}
              </Timeline>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalTrackingMail;
