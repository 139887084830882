import {
  listClassesAtosInfracionais,
  listClassesProJudInfracionais,
} from './constsFormInvestigateds';

export const isInfraction = classCode => {
  return (
    listClassesAtosInfracionais.some(act => act.code === classCode) ||
    listClassesProJudInfracionais.some(act => act.code === classCode)
  );
};
