/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  Grid,
  DialogContent,
  Box,
  IconButton,
  TextareaAutosize,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { HeaderModal, ButtonConsensus, InputConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import investigatedCertificateService from 'services/investigated-certificates/investigatedCertificateService';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  inputMaterial: {
    width: '100%',
  },
}));

const ModalAddJustification = props => {
  const { open, onClose, inquiryInvestigatedId, existingCertificates, selectedType } = props;
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [justification, setJustification] = useState(
    existingCertificates?.find(cert => cert.type === selectedType)?.justification || '',
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setJustification(
      existingCertificates?.find(cert => cert.type === selectedType)?.justification || '',
    );
  }, [existingCertificates, selectedType]);

  const handleChangeJustification = event => {
    setJustification(event.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSaveJustification = event => {
    event.preventDefault();
    setLoading(true);
    investigatedCertificateService
      .saveCertificateService({
        type: selectedType,
        certificates: [],
        inquiryInvestigatedId,
        justification,
      })
      .then(() => {
        toast.success('Justificativa adicionada com sucesso!');
        handleClose();
      })
      .catch(() => {
        toast.error('Erro ao adicionar justificativa');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={clsx(classes.root)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, handleClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        fullWidth
        maxWidth="md"
      >
        <HeaderModal
          backgroundColor={colors.navBar}
          colorIconTitle={colors.greySecundary}
          onClose={handleClose}
        >
          Adicionar Justificativa para Tipo {selectedType}
        </HeaderModal>
        <DialogContent>
          <form onSubmit={handleSaveJustification}>
            <Grid style={{ marginTop: 10 }} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextareaAutosize
                style={{ width: '100%', padding: 5, resize: 'none' }}
                rowsMax={4}
                rowsMin={4}
                aria-label="maximum height"
                placeholder="Justificativa"
                onChange={handleChangeJustification}
                name="justification"
                value={justification}
              />
            </Grid>
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ButtonConsensus title="Salvar Justificativa" type="submit" disabled={loading} />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalAddJustification;
