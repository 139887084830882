import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  colors,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewListIcon from '@material-ui/icons/ViewList';
import { Label } from 'components';
import Moment from 'react-moment';
import ModalSaveVictimIntimation from './ModalSaveVictimIntimation';
import ModalAddFile from './ModalAddFile';
import ModalListFiles from './ModalListFiles';
import ModalDeleteConfirm from './ModalDeleteConfirm';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { toast } from 'react-toastify';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import intimationService from 'services/intimation/intimationService';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#00597B',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  titulo: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingLeft: 6,
  },
});

const VictimIntimation = props => {
  const { inquiry, refreshPage, ...rest } = props;

  const classes = useStyles();

  const [selectedVictimIntimation, setSelectedVictimIntimation] = useState(null);
  const [saveVictimIntimation, setSaveVictimIntimation] = useState(false);
  const [openModalAddFile, setModalAddFile] = useState(false);
  const [openModalListFiles, setOpenModalListFiles] = useState(false);
  const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
  const [files, setFiles] = useState(null);

  const { isMPDFT } = useCustomerConfig();

  const editable =
    inquiry.step === STEPS_INQUIRY.INTIMACAO || inquiry.step === STEPS_INQUIRY.AUDIENCIA;

  const statusColors = {
    green: colors.green[600],
    red: colors.red[600],
  };

  const openDeleteConfirm = (victimIntimation, index) => {
    setSelectedVictimIntimation({ victimIntimation, index });
    setOpenModalDeleteConfirm(true);
  };

  const handleOpenAddVictimIntimation = () => {
    setSelectedVictimIntimation(null);
    setSaveVictimIntimation(true);
  };

  const handleOpenEditVictimIntimation = (victimIntimation, index) => {
    setSelectedVictimIntimation({ victimIntimation, index });
    setSaveVictimIntimation(true);
  };

  const handleCloseModalAddFile = () => {
    setModalAddFile(false);
  };

  const handleCloseModalListFiles = () => {
    setOpenModalListFiles(false);
  };

  const insertFiles = response => {
    const listFiles = {
      attachments: response.data.attachments,
    };

    setFiles(listFiles);
  };

  const getAttachments = async victimIntimationId => {
    await intimationService.getVictimIntimation(victimIntimationId).then(response => {
      insertFiles(response);
    });
  };

  const handleOpenModalListFiles = async victimIntimation => {
    await getAttachments(victimIntimation.id);
    setOpenModalListFiles(true);
  };

  const handleOpenModalFile = (_event, index, victimIntimation) => {
    setSelectedVictimIntimation({ victimIntimation, index });
    setModalAddFile(true);
  };

  const handleSaveVictimIntimation = victimIntimation => {
    let params = {
      ...victimIntimation,
    };

    if (!victimIntimation.id) {
      intimationService.addVictimIntimation(params).then(() => {
        toast.success('Intimação da vítima criada com sucesso!');
        setSaveVictimIntimation(false);
        refreshPage();
      });
    } else {
      intimationService.updateVictimIntimation(victimIntimation.id, params).then(() => {
        toast.success('Intimação da vítima alterada com sucesso!');
        setSaveVictimIntimation(false);
        refreshPage();
      });
    }
  };

  const handleCloseEditVictimIntimation = () => {
    setSaveVictimIntimation(false);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenModalDeleteConfirm(false);
  };

  const confirmDelete = victimIntimation => {
    intimationService.deleteVictimIntimation(victimIntimation.id).then(() => {
      toast.success('Intimação de vítima excluída com sucesso!');
      handleCloseDeleteConfirm();
      refreshPage();
    });
  };

  return (
    <Card {...rest} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.titulo}>{isMPDFT ? 'Contatos com a Vítima' : 'Intimações da Vítima'}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {editable ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingBottom: 10, paddingTop: 10 }}
            >
              <Button
                onClick={() => handleOpenAddVictimIntimation()}
                variant="contained"
                color="secondary"
              >
                {isMPDFT ? 'Novo Contato' : 'Nova Intimação'}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              {inquiry.inquiry.victimIntimations.length < 1 && (
                <caption style={{ textAlign: 'center' }}>Não há intimações de vítima</caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body1">Vítima</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Data/hora do contato</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Prazo para envio de documentos</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Status</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Documentos</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Ações</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inquiry.inquiry?.victimIntimations?.map((victimIntimation, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {victimIntimation.victim?.name || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {victimIntimation.contactedAt ? (
                            <Moment format="DD/MM/YYYY HH:mm">
                              {victimIntimation.contactedAt}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {victimIntimation.documentSendingDeadline ? (
                            <Moment format="DD/MM/YYYY">
                              {victimIntimation.documentSendingDeadline}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Label
                          color={
                            victimIntimation.wasContacted ? statusColors.green : statusColors.red
                          }
                          variant="outlined"
                        >
                          {victimIntimation.wasContacted ? 'Bem sucedida' : 'Pendente'}
                        </Label>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Tooltip arrow placement="top" title="Visualizar arquivos">
                          <IconButton onClick={() => handleOpenModalListFiles(victimIntimation)}>
                            <ViewListIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {editable && (
                          <Tooltip
                            placement="top"
                            arrow
                            title="Adicionar arquivo à intimação da vítima"
                          >
                            <IconButton
                              onClick={event => handleOpenModalFile(event, index, victimIntimation)}
                            >
                              <AttachFileIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      {editable ? (
                        <TableCell component="th" scope="row" align="center">
                          <Tooltip arrow placement="top" title="Alterar intimação da vítima">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleOpenEditVictimIntimation(victimIntimation, index)
                              }
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow placement="top" title="Excluir intimação da vítima">
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => openDeleteConfirm(victimIntimation, index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell component="th" scope="row" align="center">
                          <Tooltip arrow placement="top" title="Visualizar intimação da vítima">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleOpenEditVictimIntimation(victimIntimation, index)
                              }
                            >
                              <VisibilityIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {saveVictimIntimation && (
        <ModalSaveVictimIntimation
          open={saveVictimIntimation}
          onClose={handleCloseEditVictimIntimation}
          selectedItem={selectedVictimIntimation}
          victims={inquiry?.inquiry.victims}
          inquiryId={inquiry.inquiry.id}
          editable={editable}
          confirmSaveVictimIntimation={handleSaveVictimIntimation}
        />
      )}

      {openModalAddFile && (
        <ModalAddFile
          open={openModalAddFile}
          onClose={handleCloseModalAddFile}
          selectedItem={selectedVictimIntimation}
        />
      )}

      {openModalListFiles && (
        <ModalListFiles
          onClose={handleCloseModalListFiles}
          open={openModalListFiles}
          files={files}
          editable={editable}
        />
      )}

      {openModalDeleteConfirm && (
        <ModalDeleteConfirm
          open={openModalDeleteConfirm}
          onClose={handleCloseDeleteConfirm}
          victimIntimation={selectedVictimIntimation.victimIntimation}
          confirmDelete={confirmDelete}
        />
      )}
    </Card>
  );
};

export default VictimIntimation;
