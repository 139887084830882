import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { FilesCard } from './components';
import { Timeline } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  paper: {
    width: '80vw',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
  },
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#eaf0f3',
  },
  timeline: {
    padding: '0',
  },
}));

const FilesTimeline = props => {
  const { files = [], className, component: Component, openFileViewer, ...rest } = props;

  const filesTree = Object.values(
    files
      .sort((a, b) => {
        const valueA = new Date(a.createdAt);
        const valueB = new Date(b.createdAt);
        if (valueA > valueB) {
          return -1;
        } else if (valueA < valueB) {
          return 1;
        }
        return 0;
      })
      .reduce((acc, file) => {
        const date = new Date(file.createdAt)
          .toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
          .replace(/ de /g, ' ');
        const hour = new Date(file.createdAt).toLocaleTimeString('pt-BR', {
          hour: 'numeric',
          minute: 'numeric',
        });
        const type = file.fileType?.id || 1;
        if (!acc[date]) {
          acc[date] = { [type]: [] };
        }
        if (!acc[date][type]) {
          acc[date][type] = [];
        }
        acc[date][type].push({ ...file, date, hour });
        return acc;
      }, {}),
  );

  const classes = useStyles();

  const [selected, setSelected] = useState(null);

  const handleClick = key => {
    setSelected(key);
  };

  return (
    <Component {...rest} className={clsx(classes.container, className)}>
      <div className={classes.paper}>
        <Timeline className={classes.timeline}>
          {filesTree.map((files, index) => (
            <>
              {Object.values(files).map((file, subIndex) => {
                return (
                  <FilesCard
                    openFileViewer={openFileViewer}
                    key={`${index}-${subIndex}`}
                    handleClick={handleClick}
                    selected={selected}
                    selectedIndex={`${index}-${subIndex}`}
                    file={file}
                    index={subIndex}
                    last={index === filesTree.length - 1}
                  />
                );
              })}
            </>
          ))}
        </Timeline>
      </div>
    </Component>
  );
};

export default FilesTimeline;
