/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import { ButtonConsensus } from 'components';
import versionService from 'services/version/versionService';
import { toast } from 'react-toastify';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import HistoryIcon from '@mui/icons-material/History';
import history from 'utils/history';

const ModalVersion = props => {
  const { frontVersion, open, onClose } = props;

  const { viewMppeIntegrationsInModalVersion } = useCustomerConfig();

  const [versions, setVersions] = useState(null);
  const [loading, setLoading] = useState(false);

  const getApplicationVersions = async () => {
    await versionService
      .getVersions()
      .then(response => setVersions(response.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    try {
      getApplicationVersions();
      setLoading(true);
    } catch (error) {
      toast.error('Erro ao buscar as versões da aplicação');
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Versões do Consensus
          <Tooltip title="Ver histórico de atualizações do Consensus" arrow placement="right">
            <HistoryIcon style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => history.replace('historico-atualizacoes')} />
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress color="primary" size={20} />
          ) : versions ? (
            <>
              <DialogContentText>Front: {frontVersion}</DialogContentText>
              <DialogContentText>API: {versions.api}</DialogContentText>
              <DialogContentText>Task Scheduling: {versions.task}</DialogContentText>
              {viewMppeIntegrationsInModalVersion && (
                <DialogContentText>Crawler: {versions.crawler}</DialogContentText>
              )}
            </>
          ) : (
            <DialogContentText>Erro ao buscar as versões da aplicação.</DialogContentText>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <ButtonConsensus
            title="Fechar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalVersion;
