import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, Collapse, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import AssignmentIcon from '@material-ui/icons/Assignment';
import '../../../../font.css';
import { setClassesInquiry } from 'actions';
import {
  LinkingInquiryFromAuto,
  InputConsensus,
  ButtonConsensus,
  ResponsibleForInquiry,
} from 'components';
import ModalClasses from 'components/DivNumeroRequerimento/components/ModalClasses';
import { colors } from 'utils/colorsSteps';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import ListSurveysWithTheSameClass from 'components/ListSurveysWithTheSameClass';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    marginTop: 10,
    backgroundColor: '#ededed',
    boxShadow: 'none',
    width: '100%',
    fontFamily: 'roboto-regular',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  comValor: {
    backgroundColor: '#D7D7D7',
    borderRadius: 15,
    padding: 10,
    fontFamily: 'roboto-regular',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  semValor: {
    backgroundColor: 'transparent',
  },
  tituloReq: {
    color: '#00597b',
    fontSize: 14,
    fontFamily: 'roboto-regular',
  },
  tituloReqVazio: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
    textAlign: 'left',
  },
  numeroReq: {
    color: '#00597b',
    fontSize: 16,
    fontFamily: 'roboto-regular',
    '@media (max-width:800px)': {
      textAlign: 'center',
      marginBottom: 10,
    },
  },
  numeroReqVazio: {
    color: 'transparent',
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'roboto-regular',
  },
  button: {
    backgroundColor: '#00b1ac',
    color: '#ededed',
    '&:hover': {
      backgroundColor: '#00597b',
    },
  },
  inputMaterial: {
    width: '100%',
  },
}));

const RequerimentNumber = props => {
  const { allOriginNumbersAreNpu } = useCustomerConfig();
  const {
    numeroRequerimento,
    setOpenModalListFIle,
    setOpenModalConcatFiles,
    clearLinkingInquiry,
    setClearLinkingInquiryId,
    checkedPoliceInquiryNumberClass,
    inquiry,
    write,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openModalClasse, setOpenModalClasse] = useState(false);

  const [formatedRequerimentNumber, setFormatedRequerimentNumber] = useState(numeroRequerimento);

  const classesInquiryReducer = useSelector(state => state.classesInquiry);

  const initialValuesClasses = {
    code: '',
    description: '',
  };

  const [formStateClasses, setFormStateClasses] = useState({
    isValid: false,
    codeClasses: classesInquiryReducer ? classesInquiryReducer?.description : '',
    classesInquiry: classesInquiryReducer
      ? { ...classesInquiryReducer }
      : { ...initialValuesClasses },
    touched: {},
    errors: {},
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenModalClasse = () => {
    setOpenModalClasse(true);
  };

  const handleCloseModalClasse = () => {
    setOpenModalClasse(false);
  };

  const handleAddClasse = (_, classe) => {
    dispatch(
      setClassesInquiry({
        code: classe.code,
        description: classe.description,
      }),
    );

    setFormStateClasses(formStateClasses => ({
      ...formStateClasses,
      classesInquiry: classe,
      codeClasses: classe.description,
    }));

    if (classe?.code === '279.1') {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  };

  const handleChangeClasses = event => {
    event.persist();
    setFormStateClasses(formStateClasses => ({
      ...formStateClasses,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      touched: {
        ...formStateClasses.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    setFormatedRequerimentNumber(
      verifyMaskInquiryNumber(
        numeroRequerimento,
        classesInquiryReducer.description,
        allOriginNumbersAreNpu,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesInquiryReducer]);

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} className={classes.comValor}>
        <Grid item xs={10} sm={10} md={7} lg={7} xl={7} className={classes.numeroReq}>
          Número de Origem: {formatedRequerimentNumber}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item>
              <ButtonConsensus
                title="Ver Inquérito"
                margin={0}
                backgroundColor={colors.greenLight}
                hovercolor={colors.greenLight}
                startIcon={<AssignmentIcon />}
                onClick={() => setOpenModalListFIle(true)}
                size="medium"
              />
            </Grid>
            <Grid item>
              <Tooltip title="Baixar arquivo unificado">
                <IconButton color="primary" onClick={() => setOpenModalConcatFiles(true)}>
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Typography
            className={classes.title}
            component="h3"
            variant="h3"
            color="primary"
            align="left"
          >
            Classe *
          </Typography>
          <InputConsensus
            name="codeClasses"
            variant="standard"
            disabled={!write}
            value={formStateClasses.codeClasses}
            className={classes.inputMaterial}
            autoComplete="off"
            onClick={handleOpenModalClasse}
          />
        </Grid>

        {checkedPoliceInquiryNumberClass.length > 0 && write && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ListSurveysWithTheSameClass foundInquiries={checkedPoliceInquiryNumberClass[0]} />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ResponsibleForInquiry inquiry={inquiry} write={write} isPendingInquiry />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box width="100%">
            <Collapse in={openAlert}>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Ao selecionar a Classe <strong>Requisitório Inquérito Policial</strong>, é
                necessário vincular este inquérito.
              </Alert>
            </Collapse>
          </Box>
          <LinkingInquiryFromAuto
            clearLinkingInquiry={clearLinkingInquiry}
            setClearLinkingInquiryId={setClearLinkingInquiryId}
            findIntegration
            disabled={!write}
          />
        </Grid>
      </Grid>
      <ModalClasses
        open={openModalClasse}
        onClose={handleCloseModalClasse}
        onClick={handleAddClasse}
        onChange={handleChangeClasses}
        isNew={true}
        isPendingInquiry={true}
        isInfraction={isInfraction(inquiry?.classe)}
      />
    </Card>
  );
};

export default RequerimentNumber;
