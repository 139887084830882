import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { CurrencyTextField, InputConsensus } from 'components';
import { getOnlyNumbers } from 'utils/currency';
import PjeJudClassSubAutoComplete from './components/PjeJudClassSubAutocomplete';
import pjeIntegrationService from 'services/pje-integration/pjeIntegrationService';
import { makeStyles } from '@material-ui/styles';
import { OtherActivePolesAutoComplete } from './components/OtherActivePolesAutoComplete/OtherActivePolesAutoComplete';
import PjeNpus from '../../components/PjeNpus';
import { toast } from 'react-toastify';
import { NpuTypes } from 'constants/npu-types';

const useStyles = makeStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #000',
    },
    '& .MuiInput-input': {
      color: '#000',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& label.MuiFormLabel-root': {
      color: '#000',
      fontSize: 12,
    },
    '& .Mui-error': {
      fontSize: 10,
      color: 'red',
    },
  },
});

const FormPreRegisterPJE = props => {
  const {
    formState,
    setFormState,
    handleChange,
    isInAnalysis,
    isInPending,
    investigatedNPU,
    inquiryJudicialDistrictId,
  } = props;

  const handleRemoveNpu = npu => {
    const index = (formState?.npus || []).findIndex(item => item === npu);
    if (index !== -1) {
      setFormState(formState => ({
        ...formState,
        npus: formState?.npus.filter((value, _index, _arr) => value.npu !== npu.npu),
      }));
    }
  };

  const handleAddNpu = () => {
    const newNpu = formState.npu;
    if (newNpu == '') {
      toast.warn('Insira um número de vínculo');
      return;
    }
    if (typeof newNpu !== 'undefined') {
      const index = (formState?.npus || []).findIndex(item => item.npu === newNpu);
      if (index === -1) {
        const newNpusList = [
          ...(formState?.npus || []),
          { relationType: NpuTypes.DP, npu: newNpu },
        ];
        setFormState(formState => ({
          ...formState,
          npu: '',
          npus: newNpusList,
        }));
      } else {
        toast.warn(`Número ${newNpu} já adicionado`);
        setFormState(formState => ({
          ...formState,
          npu: '',
        }));
        return;
      }
    }
  };

  const changeNpuType = (event, index) => {
    setFormState(formState => ({
      ...formState,
      npus:
        formState?.npus.map((item, i) => {
          if (i === index) {
            return { ...item, relationType: event.target.value };
          }
          return item;
        }) || [],
    }));
  };

  const classes = useStyles();

  const [pjeSecretOptions, setPjeSecretOptions] = useState([]);

  const handleCauseValue = (event, value, maskedValue) => {
    event.persist();
    handleChange('causeValue', Number(getOnlyNumbers(maskedValue || event.target.value)) / 100);
  };

  const getPjeSecret = async () => {
    const params = { active: true };
    await pjeIntegrationService.getPjeSecretOptionsService(params).then(response => {
      setPjeSecretOptions(response.data);
    });
  };

  useEffect(() => {
    getPjeSecret();
  }, []);

  return (
    <>
      <PjeJudClassSubAutoComplete
        formState={formState}
        setFormState={setFormState}
        isInAnalysis={isInAnalysis}
        handleChange={handleChange}
      />

      <OtherActivePolesAutoComplete
        formState={formState}
        setFormState={setFormState}
        isInAnalysis={isInAnalysis}
        handleChange={handleChange}
        inquiryJudicialDistrictId={inquiryJudicialDistrictId}
      />

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <CurrencyTextField
          label="Valor da causa"
          name="causeValue"
          onChange={handleCauseValue}
          value={formState.causeValue || 0}
          fullWidth
          max={1000000000000}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <FormControl className={classes.root} fullWidth>
          <InputLabel>Nível de Sigilo</InputLabel>
          <Select
            onChange={event => {
              handleChange('pjeSecretId', event.target.value);
            }}
            value={formState.pjeSecretId}
            name="pjeSecretId"
            fullWidth
          >
            {pjeSecretOptions.map(pjeSecret => (
              <MenuItem key={pjeSecret.id} value={pjeSecret.id}>
                {pjeSecret.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ alignSelf: 'flex-end' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.freeJustice}
              onChange={(_, checked) => handleChange('freeJustice', checked)}
              name="freeJustice"
              color="primary"
              size="small"
            />
          }
          label={'Justiça gratuita'}
          style={{ padding: '0 4px' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.antecipationRequest}
              onChange={(_, checked) => handleChange('antecipationRequest', checked)}
              name="antecipationRequest"
              color="primary"
              size="small"
            />
          }
          label={'Pedido de liminar de antecipação de tutela'}
          style={{ textAlign: 'left' }}
        />
      </Grid>
      {investigatedNPU && !isInPending && (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', gap: '16px' }}>
          <FormControl className={classes.root} fullWidth>
            <InputLabel>NPU</InputLabel>
            <Select value={investigatedNPU} name="npu" fullWidth disabled>
              <MenuItem value={investigatedNPU}>{investigatedNPU}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {(!investigatedNPU || isInPending) && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PjeNpus
            npuList={formState.npus}
            handleRemoveNpu={handleRemoveNpu}
            handleAddNpu={handleAddNpu}
            handleChange={handleChange}
            formState={formState}
            changeNpuType={changeNpuType}
          />
        </Grid>
      )}
    </>
  );
};

export default FormPreRegisterPJE;
