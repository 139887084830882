import React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const NavigationList = props => {
  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const { pages, ...rest } = props;

  return (
    <>
      <List disablePadding>
        {pages.reduce(
          (items, page) => reduceChildRoutes({ items, page, permissionsReducer, ...rest }),
          [],
        )}
      </List>
    </>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = props => {
  const { router, items, page, depth, permissionsReducer } = props;

  let comparation = {
    read: false,
    write: false,
  };

  if (page.resource === 'inquiriesRead') {
    comparation.read = permissionsReducer?.inquiries?.read;
    comparation.write = false;
  } else if (page.resource === 'inquiriesWrite') {
    comparation.write = permissionsReducer?.inquiries?.write;
    comparation.read = false;
  } else if (page.resource === 'inquiries/triage') {
    comparation.read = permissionsReducer?.inquiriesTriagem?.read;
    comparation.write = permissionsReducer?.inquiriesTriagem?.write;
  } else if (page.resource === 'inquiries/distribution') {
    comparation.read = permissionsReducer?.inquiriesDistribuicao?.read;
    comparation.write = permissionsReducer?.inquiriesDistribuicao?.write;
  } else if (page.resource === 'inquiries/analysis') {
    comparation.read = permissionsReducer?.inquiriesAnalise?.read;
    comparation.write = permissionsReducer?.inquiriesAnalise?.write;
  } else if (page.resource === 'inquiries/intimation') {
    comparation.read = permissionsReducer?.inquiriesIntimacao?.read;
    comparation.write = permissionsReducer?.inquiriesIntimacao?.write;
  } else if (page.resource === 'inquiries/audience') {
    comparation.read = permissionsReducer?.inquiriesAudience?.read;
    comparation.write = permissionsReducer?.inquiriesAudience?.write;
  } else if (page.resource === 'configuration/intimation-modes') {
    comparation.read = permissionsReducer?.inquiriesConfigurarIntimacao?.read;
    comparation.write = permissionsReducer?.inquiriesConfigurarIntimacao?.write;
  } else if (page.resource === 'configuration/external-credentials') {
    comparation.read = permissionsReducer?.inquiriesSenhaSds?.read;
    comparation.write = permissionsReducer?.inquiriesSenhaSds?.write;
  } else if (page.resource === 'users') {
    comparation.read = permissionsReducer?.inquiriesUsuario?.read;
    comparation.write = permissionsReducer?.inquiriesUsuario?.write;
  } else if (page.resource === 'roles') {
    comparation.read = permissionsReducer?.inquiriesPapeis?.read;
    comparation.write = permissionsReducer?.inquiriesPapeis?.write;
  } else if (page.resource === 'prosecutor-office-groups') {
    comparation.read = permissionsReducer?.inquiriesGrupoOficio?.read;
    comparation.write = permissionsReducer?.inquiriesGrupoOficio?.write;
  } else if (page.resource === 'laws') {
    comparation.read = permissionsReducer?.lawsPermission?.read;
    comparation.write = permissionsReducer?.lawsPermission?.write;
  } else if (page.resource === 'inquiries/processing') {
    comparation.read = permissionsReducer?.inquiriesTasks?.read;
    comparation.write = permissionsReducer?.inquiriesTasks?.write;
  } else if (page.resource === 'crimes') {
    comparation.read = permissionsReducer?.crimesPermission?.read;
    comparation.write = permissionsReducer?.crimesPermission?.write;
  } else if (page.resource === 'projects') {
    comparation.read = permissionsReducer?.projects?.read;
    comparation.write = permissionsReducer?.projects?.write;
  } else if (page.resource === 'material-and-community-service') {
    comparation.read = permissionsReducer?.servicesAndMaterials?.read;
    comparation.write = permissionsReducer?.servicesAndMaterials?.write;
  } else if (page.resource === 'register/parameters') {
    comparation.read = permissionsReducer?.parameters?.read;
    comparation.write = permissionsReducer?.parameters?.write;
  } else if (page.resource === 'inquiries/forwarding-term') {
    comparation.read = permissionsReducer?.inquiriesForwardingTerm?.read;
    comparation.write = permissionsReducer?.inquiriesForwardingTerm?.write;
  } else if (page.resource === 'inquiries/agreement') {
    comparation.read = permissionsReducer?.inquiriesAgreement?.read;
    comparation.write = permissionsReducer?.inquiriesAgreement?.write;
  } else if (page.resource === 'report/out-of-date-requests') {
    comparation.read = permissionsReducer?.relatorioRequisitoriosForaDoPrazo?.read;
    comparation.write = permissionsReducer?.relatorioRequisitoriosForaDoPrazo?.write;
  } else if (page.resource === 'report/distributions-and-movements') {
    comparation.read = permissionsReducer?.relatorioDistribuiçõesEmovimentações?.read;
    comparation.write = permissionsReducer?.relatorioDistribuiçõesEmovimentações?.write;
  } else if (page.resource === 'report/returned-procedures') {
    comparation.read = permissionsReducer?.relatorioProcedimentosDevolvidos?.read;
    comparation.write = permissionsReducer?.relatorioProcedimentosDevolvidos?.write;
  } else if (page.resource === 'report/pending-and-registered') {
    comparation.read = permissionsReducer?.relatorioCadastradosEpendentes?.read;
    comparation.write = permissionsReducer?.relatorioCadastradosEpendentes?.write;
  } else if (page.resource === 'report/pending-or-triage-procedures') {
    comparation.read = permissionsReducer?.relatorioPendentesOuEmTriagem?.read;
    comparation.write = permissionsReducer?.relatorioPendentesOuEmTriagem?.write;
  } else if (page.resource === 'report/prosecutor-office-group-performance') {
    comparation.read = permissionsReducer?.prosecutorOfficeGroupPerformanceReport?.read;
    comparation.write = permissionsReducer?.prosecutorOfficeGroupPerformanceReport?.write;
  } else if (page.resource === 'report/inquiry') {
    comparation.read = permissionsReducer?.inquiryReport?.read;
    comparation.write = permissionsReducer?.inquiryReport?.write;
  } else if (page.resource === 'report/prosecutor-office-performance') {
    comparation.read = permissionsReducer?.prosecutorOfficePerformanceReport?.read;
    comparation.write = permissionsReducer?.prosecutorOfficePerformanceReport?.write;
  } else if (page.resource === 'report/apfd-deadline') {
    comparation.read = permissionsReducer?.apfdDeadlineReport?.read;
    comparation.write = permissionsReducer?.apfdDeadlineReport?.write;
  } else if (page.resource === 'report/apfd-custody') {
    comparation.read = permissionsReducer?.apfdCustodyReport?.read;
    comparation.write = permissionsReducer?.apfdCustodyReport?.write;
  } else if (page.resource === 'report/pcpe-integration') {
    comparation.read = permissionsReducer?.pcpeIntegrationReport?.read;
    comparation.write = permissionsReducer?.pcpeIntegrationReport?.write;
  } else if (page.resource === 'report/internal-affairs') {
    comparation.read = permissionsReducer?.internalAffairsReport?.read;
    comparation.write = permissionsReducer?.internalAffairsReport?.write;
  } else if (page.resource === 'report/procedure-audit') {
    comparation.read = permissionsReducer?.procedureAuditReport?.read;
    comparation.write = permissionsReducer?.procedureAuditReport?.write;
  } else if (page.resource === 'report/user-audit') {
    comparation.read = permissionsReducer?.userAuditReport?.read;
    comparation.write = permissionsReducer?.userAuditReport?.write;
  } else if (page.resource === 'prosecutor-lifetime') {
    comparation.read = permissionsReducer?.prosecutorLifetime?.read;
    comparation.write = permissionsReducer?.prosecutorLifetime?.write;
  } else if (page.resource === 'crime-subject') {
    comparation.read = permissionsReducer?.crimeSubjectPermission?.read;
    comparation.write = permissionsReducer?.crimeSubjectPermission?.write;
  } else if (page.resource === 'partner-associations') {
    comparation.read = permissionsReducer?.partnerAssociationsPermission?.read;
    comparation.write = permissionsReducer?.partnerAssociationsPermission?.write;
  } else if (page.resource === 'manual-synchronization-of-investigated') {
    comparation.read = permissionsReducer?.manualSynchronizationOfInvestigated?.read;
    comparation.write = permissionsReducer?.manualSynchronizationOfInvestigated?.write;
  } else if (page.resource === 'sema-and-cema-associations') {
    comparation.read = permissionsReducer?.semaAndCemaAssociationsPermission?.read;
    comparation.write = permissionsReducer?.semaAndCemaAssociationsPermission?.write;
  } else if (page.resource === 'audit-log-view') {
    comparation.read = permissionsReducer?.auditLogView?.read;
    comparation.write = permissionsReducer?.auditLogView?.write;
  } else if (page.resource === 'error-import-arquimedes-integration-view') {
    comparation.read = permissionsReducer?.errorImportArquimedesIntegrationView?.read;
    comparation.write = permissionsReducer?.errorImportArquimedesIntegrationView?.write;
  } else if (page.resource === 'report/inquiries-migration') {
    comparation.read = permissionsReducer?.inquiriesMigrationReport?.read;
    comparation.write = permissionsReducer?.inquiriesMigrationReports?.write;
  } else if (page.resource === 'sema-server-activities') {
    comparation.read = permissionsReducer?.semaServerActivities?.read;
    comparation.write = permissionsReducer?.semaServerActivities?.write;
  } else if (page.resource === 'report/prosecutor-office-balance') {
    comparation.read = permissionsReducer?.prosecutorOfficeBalanceReport?.read;
    comparation.write = permissionsReducer?.prosecutorOfficeBalanceReport?.write;
  } else if (page.resource === 'report/cvli') {
    comparation.read = permissionsReducer?.cvliReport?.read;
    comparation.write = permissionsReducer?.cvliReport?.write;
  } else if (page.resource === 'report/ai-audit-pandora') {
    comparation.read = permissionsReducer?.aiAuditPandoraReport?.read;
    comparation.write = permissionsReducer?.aiAuditPandoraReport?.write;
  } else if (page.resource === 'report/cnmp') {
    comparation.read = permissionsReducer?.cnmpReport?.read;
    comparation.write = permissionsReducer?.cnmpReport?.write;
  } else {
    comparation.read = true;
    comparation.write = true;
  }

  const handleOpenSnackbar = () => {
    return toast.warn('Você não tem permissão para acessar esta tela.');
  };

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false,
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList depth={depth + 1} pages={page.children} router={router} />
      </NavigationListItem>,
    );
  } else {
    page.resource === 'inquiriesRead'
      ? items.push(
          <NavigationListItem
            depth={depth}
            href={comparation.read ? page.href : '#'}
            icon={page.icon}
            key={page.title}
            label={page.label}
            title={page.title}
            onClick={() => (!comparation.read ? handleOpenSnackbar() : null)}
          />,
        )
      : page.resource === 'inquiriesWrite'
      ? items.push(
          <NavigationListItem
            depth={depth}
            href={comparation.write ? page.href : '#'}
            icon={page.icon}
            key={page.title}
            label={page.label}
            title={page.title}
            onClick={() => (!comparation.write ? handleOpenSnackbar() : null)}
          />,
        )
      : items.push(
          <NavigationListItem
            depth={depth}
            href={comparation.read || comparation.write ? page.href : '#'}
            icon={page.icon}
            key={page.title}
            label={page.label}
            title={page.title}
            onClick={() => (!comparation.read && !comparation.write ? handleOpenSnackbar() : null)}
          />,
        );
  }

  return items;
};

const Navigation = props => {
  const { pages, className, component: Component, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

Navigation.defaultProps = {
  component: 'nav',
};

export default Navigation;
