/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { HeaderModal, ButtonConsensus, InputConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import investigatedCertificateService from 'services/investigated-certificates/investigatedCertificateService';
import { Badge } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import history from 'utils/history';
import InquiryService from 'services/inquiries/inquiryService';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import ModalAddCertificates, { ModalAddJustification } from '../ModalAddCertificates';
import SubjectIcon from '@material-ui/icons/Subject';
import ModalFinalizationConfirm from '../ModalFinalizationConfirm';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  inputMaterial: {
    width: '100%',
  },
}));

const certificateTypes = {
  VITIMA: 'Vítima',
  INVESTIGADO: 'Investigado',
  DELEGADO: 'Delegado',
  JUIZO: 'Juízo',
};

const ModalHandleCertificates = props => {
  const { open, onClose, inquiryInvestigatedId, setWasCertificationFinalized } = props;
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [certificatesFiles, setCertificatesFiles] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [openAddCertificates, setOpenAddCertificates] = useState(false);
  const [openModalJustification, setOpenModalJustification] = useState(false);
  const [openModalFinalizationConfirm, setOpenModalFinalizationConfirm] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!openAddCertificates && !openModalJustification) {
      getCertificates();
    }
  }, [openAddCertificates, openModalJustification]);

  const getCertificates = async () => {
    setLoading(true);
    try {
      const response = await investigatedCertificateService.getCertificateWithJustificationService(
        inquiryInvestigatedId,
      );

      const groupedFiles = {
        Juízo: [],
        Investigado: [],
        Vítima: [],
        Delegado: [],
      };

      const certificates = [];

      response.data.forEach(certificate => {
        groupedFiles[certificate.type].push(...certificate.files);

        certificates.push(certificate);
      });

      setCertificates(certificates);
      setCertificatesFiles(groupedFiles);
    } catch (error) {
      toast.error('Erro ao buscar certidões');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenAddModal = type => {
    setSelectedType(type);
    setOpenAddCertificates(true);
  };

  const handleOpenModalJustification = type => {
    setSelectedType(type);
    setOpenModalJustification(true);
  };

  const handleConfirmFinalization = () => {
    setOpenModalFinalizationConfirm(false);
    finalizeCertificates();
  };

  const handleConfirmClose = () => {
    setOpenModalFinalizationConfirm(false);
  };

  const finalizeCertificates = () => {
    const certificateTypesArray = [
      certificateTypes.INVESTIGADO,
      certificateTypes.DELEGADO,
      certificateTypes.VITIMA,
    ];

    const isValidCertificate = type => {
      const hasFiles = certificatesFiles[type].length > 0;
      const hasJustification = certificates.some(cert => cert.type === type && cert.justification);
      return hasFiles || hasJustification;
    };

    const allValid = certificateTypesArray.every(type => isValidCertificate(type));

    if (!allValid) {
      toast.error('É necessário adicionar ao menos uma certidão ou justificativa de cada tipo');
      return;
    }

    toast.success('Certidões salvas com sucesso');
    setWasCertificationFinalized(true);
    onClose();
  };

  return (
    <div className={clsx(classes.root)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        fullWidth
        maxWidth="md"
      >
        <HeaderModal
          backgroundColor={colors.navBar}
          colorIconTitle={colors.greySecundary}
          onClose={onClose}
        >
          Visualizar Certidões
        </HeaderModal>
        <DialogContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">
                  Total de arquivos: {Object.values(certificatesFiles).flat().length}
                </Typography>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Número de Arquivos</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(certificatesFiles).map(([type, files]) => (
                  <TableRow key={type}>
                    <TableCell align="center">{type}</TableCell>
                    <TableCell align="center">{files.length} arquivo(s)</TableCell>
                    <TableCell align="center">
                      <Tooltip arrow placement="top" title="Adicionar justificativa">
                        <IconButton
                          onClick={() => handleOpenModalJustification(type)}
                          disabled={type === certificateTypes.JUIZO}
                        >
                          {type !== certificateTypes.JUIZO && (
                            <Badge
                              badgeContent={
                                certificates.some(cert => cert.type === type && cert.justification)
                                  ? 1
                                  : 0
                              }
                              color="error"
                              overlap="circular"
                              showZero
                            >
                              <SubjectIcon fontSize="small" />
                            </Badge>
                          )}
                          {type === certificateTypes.JUIZO && <SubjectIcon fontSize="small" />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          type === certificateTypes.JUIZO
                            ? 'Visualizar Arquivos'
                            : 'Adicionar Certidão'
                        }
                      >
                        <IconButton color="primary" onClick={() => handleOpenAddModal(type)}>
                          <AttachFileIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ButtonConsensus
                  title="Finalizar"
                  onClick={() => setOpenModalFinalizationConfirm(true)}
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <ModalAddCertificates
          open={openAddCertificates}
          onClose={() => setOpenAddCertificates(false)}
          inquiryInvestigatedId={inquiryInvestigatedId}
          existingCertificatesFiles={certificatesFiles[selectedType]}
          selectedType={selectedType}
        />
        <ModalAddJustification
          open={openModalJustification}
          onClose={() => setOpenModalJustification(false)}
          inquiryInvestigatedId={inquiryInvestigatedId}
          existingCertificates={certificates}
          selectedType={selectedType}
        />
        <ModalFinalizationConfirm
          open={openModalFinalizationConfirm}
          onClose={handleConfirmClose}
          onConfirm={handleConfirmFinalization}
          title="Confirmar Finalização"
          message="Você tem certeza de que deseja finalizar?"
        />
      </Dialog>
    </div>
  );
};
export default ModalHandleCertificates;
