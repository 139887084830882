import * as actionTypes from 'actions';

const initialState = {
  open: false,
  open1: false,
  open2: false,
  open3: false,
  open4: false,
  open5: false,
  batchDistribution: false,
  activeDashboard: false,
  activeInqueritos: false,
  activeInqueritosBusca: false,
  activeTriagem: false,
  activePesquisaDistribuicao: false,
  activeAnalise: false,
  activeProcessingControll: false,
  activeIntimacao: false,
  activeVisualizarIntimacao: false,
  activeVisualizarAudiencia: false,
  activeConfigIntimacao: false,
  activeConfigSds: false,
  activeConfigQuery: false,
  activeConfigConsensus: false,
  activeConfigGoogleApi: false,
  activeRegisterUser: false,
  activeRegisterPaper: false,
  activeRegisterParameters: false,
  activeConfigPapers: false,
  activeGroupOffice: false,
  activeAddInquiry: false,
  activeImportInquiry: false,
  activeAddService: false,
  activeAssociation: false,
  activeRequestService: false,
  activeVisualizarEmailDelegacias: false,
  activeReport: false,
  activeReportInquiry: false,
  activeSchedule: false,
  activeTypification: false,
  activeRegisterLaw: false,
  activeReportCenterInquiry: false,
  activeRegisterOrgans: false,
  activeReportApfd: false,
  activeApfdCustodyReport: false,
  activeReportOutOfDateRequests: false,
  activeReportDistributionsMove: false,
  activeReportReturnedProcedures: false,
  activeBaixaDeCarga: false,
  activeRegisterProject: false,
  activeAcordo: false,
  activeCumprimentoAcordo: false,
  activeReportPendingOrTriage: false,
  activeReportInternalAffairs: false,
  activeReportPcpeIntegration: false,
  activeProsecutorLifetime: false,
  activeRegisterSubject: false,
  activeRegisterPartnerAssociations: false,
  activeProcedureAuditReport: false,
  activeManualSynchronizationOfInvestigated: false,
  activeRegisterSemaCemaAssociations: false,
  activeAuditLogView: false,
  activeDecuplingArquimedesErrorReport: false,
  activeInquiriesMigrationReport: false,
  activeAIAuditReport: false,
  activeAIAuditPandoraReport: false,
  activeUserAuditReport: false,
  activeAIAuditPandoraReport: false,
  activeProsecutorOfficeBalanceReport: false,
  activeCvliReport: false,
  activeCnmpReport: false,
  activeSemaServersActivities: false,
};

const navBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPEN:
      return {
        ...state,
        open: true,
      };

    case actionTypes.SET_INACTIVE_OPEN:
      return {
        ...state,
        open: false,
      };

    case actionTypes.SET_OPEN1:
      return {
        ...state,
        open1: true,
      };

    case actionTypes.SET_INACTIVE_OPEN1:
      return {
        ...state,
        open1: false,
      };

    case actionTypes.SET_OPEN2:
      return {
        ...state,
        open2: true,
      };

    case actionTypes.SET_INACTIVE_OPEN2:
      return {
        ...state,
        open2: false,
      };

    case actionTypes.SET_OPEN3:
      return {
        ...state,
        open3: true,
      };

    case actionTypes.SET_INACTIVE_OPEN3:
      return {
        ...state,
        open3: false,
      };

    case actionTypes.SET_OPEN4:
      return {
        ...state,
        open4: true,
      };

    case actionTypes.SET_INACTIVE_OPEN4:
      return {
        ...state,
        open4: false,
      };

    case actionTypes.SET_OPEN5:
      return {
        ...state,
        open5: true,
      };

    case actionTypes.SET_INACTIVE_OPEN5:
      return {
        ...state,
        open5: false,
      };

    case actionTypes.SET_BATCH_DISTRIBUTION:
      return {
        ...initialState,
        batchDistribution: true,
      };

    case actionTypes.SET_BATCH_DEVOLUTION:
      return {
        ...initialState,
        batchDevolution: true,
      };

    case actionTypes.SET_ACTIVE_BAIXA_DE_CARGA:
      return {
        ...initialState,
        activeBaixaDeCarga: true,
      };

    case actionTypes.SET_ACTIVE_INQUERITOS:
      return {
        ...initialState,
        activeInqueritos: true,
      };

    case actionTypes.SET_ACTIVE_INQUERITOS_BUSCA:
      return {
        ...initialState,
        activeInqueritosBusca: true,
      };

    case actionTypes.SET_ACTIVE_TRIAGEM:
      return {
        ...initialState,
        open: true,
        activeTriagem: true,
      };

    case actionTypes.SET_ACTIVE_PESQUISA:
      return {
        ...initialState,
        open: true,
        activePesquisaDistribuicao: true,
      };

    case actionTypes.SET_AGREEMENT:
      return {
        ...initialState,
        open: true,
        activeAcordo: true,
      };

    case actionTypes.SET_FORWARDING_TERM:
      return {
        ...initialState,
        open: true,
        activeCumprimentoAcordo: true,
      };

    case actionTypes.SET_ACTIVE_ANALISE:
      return {
        ...initialState,
        open: true,
        activeAnalise: true,
      };

    case actionTypes.SET_ACTIVE_PROCESSING_CONTROLL:
      return {
        ...initialState,
        open: true,
        activeProcessingControll: true,
      };

    case actionTypes.SET_ACTIVE_INTIMACAO:
      return {
        ...initialState,
        open: true,
        activeIntimacao: true,
      };

    case actionTypes.SET_ACTIVE_VISUALIZAR_INTIMACAO:
      return {
        ...initialState,
        open1: true,
        activeVisualizarIntimacao: true,
      };

    case actionTypes.SET_ACTIVE_PROSECUTOR_LIFETIME: {
      return {
        ...initialState,
        open1: true,
        activeProsecutorLifetime: true,
      };
    }

    case actionTypes.SET_ACTIVE_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED: {
      return {
        ...initialState,
        open1: true,
        activeManualSynchronizationOfInvestigated: true,
      };
    }

    case actionTypes.SET_ACTIVE_AUDIT_LOG_VIEW: {
      return {
        ...initialState,
        open1: true,
        activeAuditLogView: true,
      };
    }

    case actionTypes.SET_ACTIVE_VISUALIZAR_AUDIENCIA:
      return {
        ...initialState,
        open: true,
        activeVisualizarAudiencia: true,
      };

    case actionTypes.SET_ACTIVE_CONFIG_INTIMACAO:
      return {
        ...initialState,
        open2: true,
        activeConfigIntimacao: true,
      };

    case actionTypes.SET_CONFIG_SDS: {
      return {
        ...initialState,
        open2: true,
        activeConfigSds: true,
      };
    }

    case actionTypes.SET_CONFIG_CONSENSUS: {
      return {
        ...initialState,
        open2: true,
        activeConfigConsensus: true,
      };
    }

    case actionTypes.SET_CONFIG_QUERY: {
      return {
        ...initialState,
        open2: true,
        activeConfigQuery: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_USER: {
      return {
        ...initialState,
        open3: true,
        activeRegisterUser: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_ORGAN: {
      return {
        ...initialState,
        open3: true,
        activeRegisterOrgans: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_SUBJECT: {
      return {
        ...initialState,
        open3: true,
        activeRegisterSubject: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_PAPERS: {
      return {
        ...initialState,
        open3: true,
        activeRegisterPaper: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_PARAMETERS: {
      return {
        ...initialState,
        open3: true,
        activeRegisterParameters: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_PARTNER_ASSOCIATIONS: {
      return {
        ...initialState,
        open3: true,
        activeRegisterPartnerAssociations: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_SEMA_AND_CEMA_ASSOCIATIONS: {
      return {
        ...initialState,
        open3: true,
        activeRegisterSemaAndCemaAssociations: true,
      };
    }

    case actionTypes.SET_ACTIVE_CONFIG_PAPERS: {
      return {
        ...initialState,
        open2: true,
        activeConfigPapers: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_GROUPE_OFFICE: {
      return {
        ...initialState,
        open3: true,
        activeGroupOffice: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_INQUIRY: {
      return {
        ...initialState,
        open3: true,
        activeAddInquiry: true,
      };
    }

    case actionTypes.SET_ACTIVE_IMPORT_INQUIRY: {
      return {
        ...initialState,
        open3: true,
        activeImportInquiry: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_SERVICE: {
      return {
        ...initialState,
        open3: true,
        activeAddService: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_ASSOCIATION: {
      return {
        ...initialState,
        open3: true,
        activeAssociation: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_PROJECT: {
      return {
        ...initialState,
        open3: true,
        activeRegisterProject: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_REQUEST_SERVICE: {
      return {
        ...initialState,
        open3: true,
        activeRequestService: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_INQUIRY: {
      return {
        ...initialState,
        open4: true,
        activeReportInquiry: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_PENDING_OR_TRIAGE: {
      return {
        ...initialState,
        open4: true,
        activeReportPendingOrTriage: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_INTERNAL_AFFAIRS: {
      return {
        ...initialState,
        open4: true,
        activeReportInternalAffairs: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_PCPE_INTEGRATION: {
      return {
        ...initialState,
        open4: true,
        activeReportPcpeIntegration: true,
      };
    }

    case actionTypes.SET_ACTIVE_PROCEDURE_AUDIT_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeProcedureAuditReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_SCHEDULE: {
      return {
        ...initialState,
        open5: true,
        activeSchedule: true,
      };
    }

    case actionTypes.SET_ACTIVE_CONFIG_LOGIN_GOOGLE_API: {
      return {
        ...initialState,
        open2: true,
        activeConfigGoogleApi: true,
      };
    }

    case actionTypes.SET_ACTIVE_EMAIL_DELEGACIAS: {
      return {
        ...initialState,
        open2: true,
        activeVisualizarEmailDelegacias: true,
      };
    }

    case actionTypes.SET_ACTIVE_TYPIFICATION: {
      return {
        ...initialState,
        open3: true,
        activeTypification: true,
      };
    }

    case actionTypes.SET_ACTIVE_REGISTER_LAW: {
      return {
        ...initialState,
        open3: true,
        activeRegisterLaw: true,
      };
    }

    case actionTypes.NAVBAR_LOGOUT: {
      return {
        ...initialState,
        activeDashboard: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_CENTER_INQUIRY: {
      return {
        ...initialState,
        open4: true,
        activeReportCenterInquiry: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_APFD: {
      return {
        ...initialState,
        open4: true,
        activeReportApfd: true,
      };
    }

    case actionTypes.SET_ACTIVE_APFD_CUSTODY_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeApfdCustodyReport: true,
      };
    }
    
    case actionTypes.SET_ACTIVE_REPORT_OUT_OF_DATE_REQUESTS: {
      return {
        ...initialState,
        open4: true,
        activeReportOutOfDateRequests: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_DISTRIBUTIONS_MOVE: {
      return {
        ...initialState,
        open4: true,
        activeReportDistributionsMove: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_RETURNED_PROCEDURES: {
      return {
        ...initialState,
        open4: true,
        activeReportReturnedProcedures: true,
      };
    }

    case actionTypes.SET_ACTIVE_REPORT_PENDING_NONPENDING: {
      return {
        ...initialState,
        open4: true,
        activeReportPendingNonpending: true,
      };
    }

    case actionTypes.SET_ACTIVE_DECUPLING_ARQUIMEDES_ERROR_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeDecuplingArquimedesErrorReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_INQUIRIES_MIGRATION_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeInquiriesMigrationReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_AI_AUDIT_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeAIAuditReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_USER_AUDIT_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeUserAuditReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_PROSECUTOR_OFFICE_BALANCE_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeProsecutorOfficeBalanceReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_CVLI_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeCvliReport: true,
      };
    }

    case actionTypes.SET_ACTIVE_CNMP_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeCnmpReport: true,
      };
    }

    case actionTypes.SET_SEMA_SERVER_ACTIVITIES: {
      return {
        ...initialState,
        open1: true,
        activeSemaServersActivities: true,
      };
    }

    case actionTypes.SET_ACTIVE_AI_AUDIT_PANDORA_REPORT: {
      return {
        ...initialState,
        open4: true,
        activeAIAuditPandoraReport: true,
      };
    }

    case actionTypes.SET_CLEAR_NAV_BAR: {
      return initialState;
    }

    default:
      return state;
  }
};

export default navBarReducer;
