import http from '../httpService';

const InvestigatedCertificateService = {
  getByInvestigatedService: inquiryInvestigatedId =>
    http.get(`investigated-certificates/investigated/${inquiryInvestigatedId}`),

  getCertificateWithJustificationService: inquiryInvestigatedId =>
    http.get(`investigated-certificates/${inquiryInvestigatedId}`),

  saveCertificateService: body => http.post(`investigated-certificates`, body),
};

export default InvestigatedCertificateService;
