import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 25,
    height: 360,
    padding: theme.spacing(3, 1),
    width: '100%',
    backgroundColor: '#ededed',
    border: `1px solid #707070`,
  },
}));

const ContainerCriminalsSocials = ({ type, children }) => {
  const { inquiryDisplayCriminalRecordItem, inquiryDisplaySocioEconomicDataItem } = useCustomerConfig();
  const classes = useStyles();

  const isVisible = 
    type === "criminalRecord" ? inquiryDisplayCriminalRecordItem : 
    type === "socioEconomic" ? inquiryDisplaySocioEconomicDataItem : 
    false;

  return (
    isVisible && (
      <div className={classes.container}>
        {children}
      </div>
    )
  );
};

export default ContainerCriminalsSocials;
