import React from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import ButtonConsensus from 'components/ButtonConsensus';
import { colors } from 'utils/colorsSteps';
import InputConsensus from 'components/InputConsensus';
import CurrencyTextField from 'components/CurrencyTextField';
import { KeyboardDatePicker } from 'components/DatePickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import { formatDateToBrazilianMonthYear, formatDateToISO8601 } from 'utils/date-time.utils';
import { parcelsHoursSum, parcelsSum } from 'utils/parcels';
import { currencyFormat } from 'utils/currency';
import DeleteIcon from '@material-ui/icons/Delete';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';
import { initialDate } from 'utils/DatesInMonth';

const InstallmentFixedPerPeriod = props => {
  const {
    formState,
    setFormState,
    handleChange,
    handleChangeValue,
    handleInputKeyPress,
    handleOpenDeleteParcelModalConfirm,
    handleParcelValueChange,
    handleParcelDateChange,
    selectedIndex,
    isServicePenalty = false,
  } = props;

  const handleAddParcel = () => {
    const {
      paymentDeadline,
      value,
      hours,
      numberOfParcels,
      periodicy,
      inicialDate,
    } = formState.values;

    if (isServicePenalty) {
      if (!inicialDate) {
        return toast.warn('Insira a data inicial');
      }
      if (!hours || hours == '0') {
        return toast.warn('Insira a quantidade de horas');
      }
    } else {
      if (!paymentDeadline) {
        return toast.warn('Insira a data inicial');
      }
      if (!value || value == '0') {
        return toast.warn('Insira um valor');
      }
    }
    if (!numberOfParcels) {
      return toast.warn('Insira a quantidade de parcelas');
    }
    if (!periodicy) {
      return toast.warn('Selecione a periodicidade');
    }

    const newParcels = [];
    let date = paymentDeadline ? moment(paymentDeadline) : moment(inicialDate);

    let monthsToAdd;
    switch (periodicy) {
      case 'Mensal':
        monthsToAdd = 1;
        break;
      case 'Bimestral':
        monthsToAdd = 2;
        break;
      case 'Trimestral':
        monthsToAdd = 3;
        break;
      case 'Quadrimestral':
        monthsToAdd = 4;
        break;
      case 'Semestral':
        monthsToAdd = 6;
        break;
    }

    if (isServicePenalty) {
      for (let i = 0; i < numberOfParcels; i++) {
        newParcels.push({
          date: date && formatDateToISO8601(date.clone().add(i * monthsToAdd, 'months')),
          hours: hours,
        });
      }
    } else {
      for (let i = 0; i < numberOfParcels; i++) {
        newParcels.push({
          date: date && formatDateToISO8601(date.clone().add(i * monthsToAdd, 'months')),
          value: value,
        });
      }
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: newParcels,
        inicialDate: '',
        paymentDeadline: '',
        value: 0,
        hours: '',
        installmentType: INSTALLMENT_TYPES.FIXED_PER_PERIOD,
      },
    }));
  };

  const orderParcelsByDate = parcels => {
    return parcels.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  };

  return (
    <>
      {formState.values.parcels.length === 0 && (
        <>
          {isServicePenalty ? (
            <>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                <KeyboardDatePicker
                  variant="inline"
                  name="inicialDate"
                  label="Data inicial*"
                  value={formState.values.inicialDate || null}
                  minDate={!selectedIndex ? initialDate() : undefined}
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      values: {
                        ...formState.values,
                        inicialDate: newDate,
                        inicialDateString: formatDateToBrazilianMonthYear(newDate),
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                <InputConsensus
                  label="Horas por mês*"
                  name="hours"
                  variant="standard"
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={handleChangeValue}
                  value={formState.values.hours || ''}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                <KeyboardDatePicker
                  variant="inline"
                  name="paymentDeadline"
                  label="Data inicial*"
                  value={formState.values.paymentDeadline || null}
                  minDate={!selectedIndex ? new Date() : undefined}
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      values: {
                        ...formState.values,
                        paymentDeadline: newDate,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                <CurrencyTextField
                  label="Valor por mês*"
                  name="value"
                  onChange={handleChangeValue}
                  value={formState.values.value || 0}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </>
      )}
      {formState.values.parcels && formState.values.parcels.length === 0 && (
        <>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <InputConsensus
              label="Periodicidade*"
              name="periodicy"
              variant="standard"
              onChange={handleChange}
              value={formState.values.periodicy || ''}
              fullWidth
              select
            >
              <MenuItem value={'Mensal'}>Mensal</MenuItem>
              <MenuItem value={'Bimestral'}>Bimestral</MenuItem>
              <MenuItem value={'Trimestral'}>Trimestral</MenuItem>
              <MenuItem value={'Quadrimestral'}>Quadrimestral</MenuItem>
              <MenuItem value={'Semestral'}>Semestral</MenuItem>
            </InputConsensus>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <InputConsensus
              label="Quantidade de parcelas*"
              name="numberOfParcels"
              variant="standard"
              type="number"
              inputProps={{
                min: 1,
              }}
              onChange={handleChange}
              onKeyPress={handleInputKeyPress}
              value={formState.values.numberOfParcels || 1}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title="Gerar Parcelas"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleAddParcel()}
              size="medium"
            />
          </Grid>
        </>
      )}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">{isServicePenalty ? 'Horas' : 'Valor'}</TableCell>
                <TableCell align="center" width={150}>
                  <Box display="flex" position="relative" justifyContent="center">
                    Ações
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderParcelsByDate(formState.values.parcels).map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row" align="center">
                    {isServicePenalty ? (
                      <KeyboardDatePicker
                        variant="inline"
                        value={item.date || null}
                        onChange={newDate => handleParcelDateChange(index, newDate)}
                      />
                    ) : (
                      <KeyboardDatePicker
                        variant="inline"
                        name="paymentDeadline"
                        value={item.date || null}
                        onChange={newDate => handleParcelDateChange(index, newDate)}
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {isServicePenalty ? (
                      <InputConsensus
                        name="hours"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onChange={e => handleParcelValueChange(index, e)}
                        value={item.hours || ''}
                        fullWidth
                      />
                    ) : (
                      <CurrencyTextField
                        name="value"
                        onChange={e => handleParcelValueChange(index, e)}
                        value={item.value / 100 || 0}
                        fullWidth
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleOpenDeleteParcelModalConfirm(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="left" colSpan={3} style={{ fontWeight: 'bold' }}>
                  Total:{' '}
                  {isServicePenalty
                    ? parcelsHoursSum(formState.values.parcels)
                    : currencyFormat(formState.values.totalValue / 100)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default InstallmentFixedPerPeriod;
