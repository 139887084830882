import React from 'react';
import { ButtonConsensus, HeaderModal } from 'components';
import { makeStyles } from '@material-ui/styles';
import { colors } from 'utils/colorsSteps';
import Moment from 'react-moment';
import BasicTable from '../BasicTable';
import { formatedPandoraFieldNames, treatPandoraKey } from '../../utils/pandoraParserForExibition';
import { Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import LogoPandora from '../../../../../../assets/images/pandora_logo.png';
import moment from 'moment';

const InvestigatedDataModal = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    dadosCriminais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 16,
    },
    containerDadosPessoais: {
      backgroundColor: '#ededed',
      marginTop: 5,
      marginBottom: 5,
      padding: 10,
      borderRadius: 25,
    },
    labelDadosPessoais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 12,
    },
    labelLastUpdate: {
      color: 'black',
      fontWeight: 'bold',
      fontSize: 12,
    },
    label: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 15,
      position: 'relative',
      marginTop: 10,
    },
  }));

  const classes = useStyles();

  const { open, handleClose, data, className, ...rest } = props;
  const { pessoa, updatedAt, ...otherData } = data?.pessoa
    ? data
    : {
        pessoa: {
          nome: 'não informado',
          nomeMae: 'não informado',
          cpf: 'não informado',
          municipio: 'não informado',
          uf: 'não informado',
          dataNascimento: 'não informado',
          sexo: 'não informado',
          fonte: ['não informado'],
          municipio: 'não informado',
          situacaoCadastral: 'não informado',
          residenteExterior: 'não informado',
          nomePaisExterior: 'não informado',
          estrangeiro: 'não informado',
          naturezaOcupacao: 'não informado',
          ocupacaoPrincipal: 'não informado',
          nomePaisExterior: 'não informado',
          anoExercicioOcupacao: 'não informado',
          anoObito: 'não informado',
          rank: 'não informado',
          relevancia: 'não informado',
        },
      };
  return (
    <div className={classes.root}>
      <Dialog open={open} fullWidth maxWidth="lg">
        <HeaderModal colorIconTitle="#fff" backgroundColor={colors.navBar} onClose={handleClose}>
          {pessoa?.nome}
        </HeaderModal>
        <DialogContent>
          <Grid container spacing={1} className={classes.containerDadosPessoais}>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography className={classes.label}>Dados Pessoais</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <img style={{ height: 70 }} src={LogoPandora} />
            </Grid>
            {pessoa?.nome && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Nome:</b> {pessoa?.nome}
                </Typography>
              </Grid>
            )}
            {pessoa?.nomeMae && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Nome da Mãe:</b> {pessoa?.nomeMae}
                </Typography>
              </Grid>
            )}
            {pessoa?.cpf && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Cpf:</b> {pessoa?.cpf}
                </Typography>
              </Grid>
            )}
            {pessoa?.municipio && (
              <Grid
                item
                xs={12}
                sm={12}
                md={parseInt(pessoa.municipio.length / 5) * 3}
                lg={parseInt(pessoa.municipio.length / 5) * 3}
                xl={parseInt(pessoa.municipio.length / 5) * 3}
              >
                <Typography className={classes.labelDadosPessoais}>
                  <b>Município:</b> {pessoa?.municipio}
                </Typography>
              </Grid>
            )}
            {pessoa?.uf && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>UF:</b> {pessoa?.uf}
                </Typography>
              </Grid>
            )}
            {pessoa?.dataNascimento && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Data de Nascimento:</b>{' '}
                  <Moment format="DD/MM/YYYY">
                    {pessoa?.dataNascimento
                      ? moment(pessoa?.dataNascimento).add(3, 'hours')
                      : pessoa?.dataNascimento}
                  </Moment>
                </Typography>
              </Grid>
            )}
            {pessoa?.sexo && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Sexo:</b> {pessoa?.sexo}
                </Typography>
              </Grid>
            )}
            {pessoa?.fonte && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Fontes:</b> {pessoa?.fonte.join(', ')}
                </Typography>
              </Grid>
            )}
            {pessoa?.situacaoCadastral && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Situação Cadastral:</b> {pessoa?.situacaoCadastral}
                </Typography>
              </Grid>
            )}
            {pessoa?.residenteExterior && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Residente no Exterior:</b>{' '}
                  {pessoa?.residenteExterior === 'NAO' ? 'NÃO' : pessoa?.residenteExterior}
                </Typography>
              </Grid>
            )}
            {pessoa?.nomePaisExterior && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Nome do País em que reside:</b> {pessoa?.nomePaisExterior}
                </Typography>
              </Grid>
            )}
            {pessoa?.estrangeiro && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Estrangeiro:</b> {pessoa?.estrangeiro}
                </Typography>
              </Grid>
            )}
            {pessoa?.naturezaOcupacao && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Natureza da Ocupação:</b> {pessoa?.naturezaOcupacao}
                </Typography>
              </Grid>
            )}
            {pessoa?.ocupacaoPrincipal && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Ocupação Principal:</b> {pessoa?.ocupacaoPrincipal}
                </Typography>
              </Grid>
            )}
            {pessoa?.anoExercicioOcupacao && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Ano de Exercício da Ocupação:</b> {pessoa?.anoExercicioOcupacao}
                </Typography>
              </Grid>
            )}
            {pessoa?.anoObito && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Ano de Óbito:</b> {pessoa?.anoObito}
                </Typography>
              </Grid>
            )}
            {pessoa?.rank && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Rank:</b> {pessoa?.rank}
                </Typography>
              </Grid>
            )}
            {pessoa?.relevancia && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelDadosPessoais}>
                  <b>Relevância:</b> {pessoa?.relevancia}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography className={classes.labelLastUpdate}>
                  <b>Última atualização:</b>{' '}
                  {updatedAt
                    ? moment(updatedAt)
                        .add(3, 'hours')
                        .format('DD/MM/YYYY')
                    : moment().format('DD/MM/YYYY')}
                </Typography>
            </Grid>
          </Grid>
          {Object.keys(otherData).map((key, index) => (
            <BasicTable
              key={index}
              fieldNames={Object.keys(otherData[key][0])}
              tableData={otherData[key]}
              tableName={formatedPandoraFieldNames[key] || treatPandoraKey(key)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Typography style={{ color: 'red' }}>
            *Informações obtidas do Pandora e alguns dados podem estar desatualizados
          </Typography>
          <ButtonConsensus
            autoFocus
            onClick={handleClose}
            title="Fechar"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            backgroundColor="#fff"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvestigatedDataModal;
