export const configScenario1 = {
  "inquiryManualRegistration": true,
  "inquiryImport": false,
  "inquiryDisplayCrimes": false,
  "inquiryDisplaySubjects": false,
  "displayTriageStep": false,
  "displayDistributionStep": false,
  "victimIntimation": false,
  "enableForwardButton": false,
  "allOriginNumbersAreNpu": true,
  "enableHearingForJuvenileOffenders": false,
  "allowManualHml": false,
  "automaticMovements": null,
  "viewPcpeIntegrationReport": true,
  "viewProcedureAuditReport": true,
  "viewUserAuditReport": true,
  "enableRedistributionButton": true,
  "enablePartnerAssociationRegistration": false,
  "enableSemaAndCemaAssociationRegistration": false,
  "allowManualSynchronizationOfInvestigated": false,
  "allowCancelAgreement": false,
  "viewModalBalances": false,
  "viewMppeIntegrationsInModalVersion": false,
  "inquiryDisplayInternalSystemNumber": false,
  "inquiryDisplayCriminalRecordItem": false,
  "inquiryDisplaySocioEconomicDataItem": false,
  "showGeneralPenalties": false,
  "inquiryDisplayBoundDocuments": false,
  "inquiryDisplayBoundInquiry": false,
  "downloadSplitDocument": false,
  "inquiryDisplaySuspectButton": false,
  "isMPDFT": false,
  "enablePenaltyTypes": false,
  "enablePreRegisterPje": true
};
