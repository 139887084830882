import http from '../httpService';

const AiAssistantService = {
  generateCrimeDescription: crimeId => http.get(`ai-assistant/crimes/${crimeId}/description`),
  generateAnswerForQuestion: data => http.post(`ai-assistant/chat-ai`, data),
  getSystems: params => http.get(`ai-assistant/systems`, { params }),
  getUsers: params => http.get(`ai-assistant/users`, { params }),
  getModels: params => http.get(`ai-assistant/models`, { params }),
  getPandoraPersonalData: investigatedCpf => http.get(`ai-assistant/personal/pandora-data?cpf=${investigatedCpf}`),
  getPandoraPersonalDataPdf: payload => http.put('ai-assistant/personal/pandora-pdf', payload),
};

export default AiAssistantService;