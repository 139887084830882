import * as actionTypes from 'actions';

const initialState = {
  page: 1,
  key: '',
  type: '',
  value: '',
};

const registerParametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGINATION_REGISTER_PARAMETERS: {
      return { ...action.payload };
    }

    case actionTypes.CLEAR_PAGINATION_REGISTER_PARAMETERS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default registerParametersReducer;
