import http from '../httpService';

const ReleasedVersionService = {
  signReleasedVersionService: releasedVersionsIds =>
    http.put('released-version/sign', { releasedVersionsIds }),

  getReleasedVersionService: () =>
    http.get('released-version'),
};

export default ReleasedVersionService;
