import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Divider,
  Box,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClassIcon from '@material-ui/icons/Class';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import {
  listClassesMedidasCautelares,
  listClassesMedidasGarantidoras,
  listClassesGenericas,
  listClassesProcessosJudiciais,
  listClassesProJudCautelares,
  listClassesMedidasIncidentais,
  listClassesAtosInfracionais,
  listClassesProJudInfracionais,
  listClassesProJudRecursos,
} from 'utils/constsFormInvestigateds';
import { useSelector } from 'react-redux';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
}));

const ModalClasses = props => {
  const { onClose, open, onClick, isPM, isNew, isInfraction, isRegister, isPendingInquiry, isTriagem } = props;

  const classes = useStyles();

  const { institution } = useSelector(state => state.logedUser);

  const disableChangeClassByInfraction = isInfraction && !isPendingInquiry && !isTriagem;
  const disableChangeToInfraction = !isInfraction && isNew && !isRegister && !(isPendingInquiry || isTriagem);

  const [openCautelar, setOpenCautelar] = useState(false);
  const [openGarantidora, setOpenGarantidora] = useState(false);
  const [openProcessosJudiciais, setOpenProcessosJudiciais] = useState(false);
  const [openProcJudCautelares, setOpenProcJudCautelares] = useState(false);
  const [openProcJudInfracionais, setOpenProcJudInfracionais] = useState(false);
  const [openProcJudRecursos, setOpenProcJudRecursos] = useState(false);
  const [openIncidental, setOpenIncidental] = useState(false);
  const [openInfracionais, setOpenInfracionais] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndexCautelar, setSelectedIndexCautelar] = useState(null);
  const [selectedIndexGarantidora, setSelectedIndexGarantidora] = useState(null);
  const [selectedIndexProcessos, setSelectedIndexProcessos] = useState(null);
  const [selectedIndexInfracionais, setSelectedIndexInfracionais] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleOpenCautelar = () => {
    setOpenCautelar(!openCautelar);
    setOpenGarantidora(false);
    setOpenProcessosJudiciais(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
    setOpenIncidental(false);
    setOpenInfracionais(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenGarantidora = () => {
    setOpenGarantidora(!openGarantidora);
    setOpenCautelar(false);
    setOpenProcessosJudiciais(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
    setOpenIncidental(false);
    setOpenInfracionais(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenProcessosJudiciais = () => {
    setOpenProcessosJudiciais(!openProcessosJudiciais);
    setOpenCautelar(false);
    setOpenGarantidora(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
    setOpenIncidental(false);
    setOpenInfracionais(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenInfracionais = () => {
    setOpenInfracionais(!openInfracionais);
    setOpenProcessosJudiciais(false);
    setOpenCautelar(false);
    setOpenGarantidora(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
    setOpenIncidental(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenIncidental = () => {
    setOpenIncidental(!openIncidental);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenProcJudCautelares = () => {
    setOpenIncidental(false);
    setOpenProcJudCautelares(!openProcJudCautelares);
    setOpenProcJudInfracionais(false);
    setOpenProcJudRecursos(false);
  };

  const handleOpenProcJudInfracionais = () => {
    setOpenIncidental(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(!openProcJudInfracionais);
    setOpenProcJudRecursos(false);
  };

  const handleOpenProcJudRecursos = () => {
    setOpenProcJudRecursos(!openProcJudRecursos);
    setOpenIncidental(false);
    setOpenProcJudCautelares(false);
    setOpenProcJudInfracionais(false);
  };

  const handleClick = (event, classe, index, list) => {
    switch (list) {
      case '0':
        setSelectedIndex(index);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        break;
      case '1':
        setSelectedIndex(null);
        setSelectedIndexCautelar(index);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        break;
      case '2':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(index);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        break;
      case '3':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Medidas Cautelares', classes: classe };
        break;
      case '4':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Medidas Garantidoras', classes: classe };
        break;
      case '5':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Processos Judiciais', classes: classe };
        break;
      case '6':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(index);
        setSelectedIndexInfracionais(null);
        break;
      case '7':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Medidas Cautelares (Processos Judiciais)', classes: classe };
        break;
      case '8':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Medidas Incidentais (Processos Judiciais)', classes: classe };
        break;
      case '9':
        setSelectedIndex(null);
        setSelectedIndexCautelar(null);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(index);
        break;
      case '10':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Atos Infracionais', classes: classe };
        break;
      case '11':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Atos Infracionais (Processos Judiciais)', classes: classe };
        break;
      case '12':
        setSelectedIndex(null);
        setSelectedIndexCautelar(-1);
        setSelectedIndexGarantidora(null);
        setSelectedIndexProcessos(null);
        setSelectedIndexInfracionais(null);
        classe = { description: 'Recursos (Processos Judiciais)', classes: classe };
        break;
      default:
        break;
    }
    onClick(event, classe);
    onClose();
  };

  const handleVerifyDisabledItem = ({ code, description }) => {
    return !(
      institution.documentClasses.includes(code.split('.')?.[0]) &&
      description !== 'Auto de Prisão em Flagrante'
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle onClose={handleClose} id="simple-dialog-title">
        Classes
      </DialogTitle>
      <Divider />
      <List component="div">
        {listClassesGenericas
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((classe, index) => (
            <Box key={classe.description}>
              <ListItem
                selected={selectedIndex === index}
                button
                onClick={event => handleClick(event, classe, index, '0')}
                disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
              >
                <ListItemIcon>
                  <ClassIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={classe.description} />
              </ListItem>
              <Divider />
            </Box>
          ))}
        <ListItem
          button
          onClick={handleOpenInfracionais}
          disabled={disableChangeToInfraction}
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Atos Infracionais" />
          {!isNew && (
            <ListItemSecondaryAction style={{ left: '155px' }}>
              <IconButton
                disabled={disableChangeToInfraction}
                onClick={event => handleClick(event, listClassesAtosInfracionais, null, '10')}
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {openInfracionais ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openInfracionais} timeout="auto">
          <List component="div" disablePadding>
            {listClassesAtosInfracionais
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((classe, index) => (
                <Box key={classe.description}>
                  <ListItem
                    selected={selectedIndexInfracionais === index}
                    button
                    className={classes.nested}
                    onClick={event => handleClick(event, classe, index, '9')}
                    disabled={isPM ? handleVerifyDisabledItem(classe) : false}
                  >
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={classe.description} />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
          </List>
        </Collapse>
        <ListItem button disabled={disableChangeClassByInfraction} onClick={handleOpenCautelar}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Medidas Cautelares" />
          {!isNew && (
            <ListItemSecondaryAction style={{ left: '155px' }}>
              <IconButton
                disabled={disableChangeClassByInfraction}
                onClick={event => handleClick(event, listClassesMedidasCautelares, null, '3')}
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {openCautelar ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openCautelar} timeout="auto">
          <List component="div" disablePadding>
            {listClassesMedidasCautelares
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((classe, index) => (
                <Box key={classe.description}>
                  <ListItem
                    selected={selectedIndexCautelar === index}
                    button
                    className={classes.nested}
                    onClick={event => handleClick(event, classe, index, '1')}
                    disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                  >
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={classe.description} />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
          </List>
        </Collapse>
        <ListItem button disabled={disableChangeClassByInfraction} onClick={handleOpenGarantidora}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Medidas Garantidoras" />
          {!isNew && (
            <ListItemSecondaryAction style={{ left: '165px' }}>
              <IconButton
                disabled={disableChangeClassByInfraction}
                onClick={event => handleClick(event, listClassesMedidasGarantidoras, null, '4')}
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {openGarantidora ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {openGarantidora && <Divider />}
        <Collapse in={openGarantidora} timeout="auto">
          <List component="div" disablePadding>
            {listClassesMedidasGarantidoras
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((classe, index) => (
                <Box key={classe.description}>
                  <ListItem
                    selected={selectedIndexGarantidora === index}
                    button
                    className={classes.nested}
                    onClick={event => handleClick(event, classe, index, '2')}
                    disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                  >
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={classe.description} />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleOpenProcessosJudiciais}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Processos Judiciais" />
          {!isNew && (
            <ListItemSecondaryAction style={{ left: '165px' }}>
              <IconButton
                disabled={disableChangeClassByInfraction}
                onClick={event =>
                  handleClick(
                    event,
                    listClassesProcessosJudiciais.concat(listClassesProJudCautelares),
                    null,
                    '5',
                  )
                }
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {openProcessosJudiciais ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {openProcessosJudiciais && <Divider />}
        <Collapse in={openProcessosJudiciais} timeout="auto">
          <List component="div" disablePadding>
            {listClassesProcessosJudiciais
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((classe, index) => (
                <Box key={classe.description}>
                  <ListItem
                    selected={selectedIndexProcessos === index}
                    button
                    className={classes.nested}
                    onClick={event => handleClick(event, classe, index, '6')}
                    disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                  >
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={classe.description} />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            <ListItem
              button
              disabled={disableChangeClassByInfraction}
              onClick={handleOpenProcJudCautelares}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Medidas Cautelares (Processos Judiciais)" />
              {!isNew && (
                <ListItemSecondaryAction style={{ left: '280px' }}>
                  <IconButton
                    disabled={disableChangeClassByInfraction}
                    onClick={event => handleClick(event, listClassesProJudCautelares, null, '7')}
                  >
                    <FiberManualRecordIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {openProcJudCautelares ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {openProcJudCautelares && <Divider />}
            <Collapse in={openProcJudCautelares} timeout="auto">
              <List component="div" disablePadding>
                {listClassesProJudCautelares
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((classe, index) => (
                    <Box key={classe.description}>
                      <ListItem
                        selected={selectedIndexProcessos === index + 1}
                        button
                        className={classes.subNested}
                        onClick={event => handleClick(event, classe, index + 1, '6')}
                        disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={classe.description} />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </List>
            </Collapse>
            <ListItem
              button
              disabled={disableChangeClassByInfraction}
              onClick={handleOpenIncidental}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Medidas Incidentais (Processos Judiciais)" />
              {!isNew && (
                <ListItemSecondaryAction style={{ left: '280px' }}>
                  <IconButton
                    disabled={disableChangeClassByInfraction}
                    onClick={event => handleClick(event, listClassesMedidasIncidentais, null, '8')}
                  >
                    <FiberManualRecordIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {openIncidental ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={openIncidental} timeout="auto">
              <List component="div" disablePadding>
                {listClassesMedidasIncidentais
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((classe, index) => (
                    <Box key={classe.description}>
                      <ListItem
                        selected={selectedIndexProcessos === index}
                        button
                        className={classes.subNested}
                        onClick={event => handleClick(event, classe, index, '6')}
                        disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={classe.description} />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </List>
            </Collapse>
            <ListItem
              button
              disabled={disableChangeToInfraction}
              onClick={handleOpenProcJudInfracionais}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Atos Infracionais (Processos Judiciais)" />
              {!isNew && (
                <ListItemSecondaryAction style={{ left: '270px' }}>
                  <IconButton
                    disabled={disableChangeToInfraction}
                    onClick={event => handleClick(event, listClassesProJudInfracionais, null, '11')}
                  >
                    <FiberManualRecordIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {openProcJudInfracionais ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={openProcJudInfracionais} timeout="auto">
              <List component="div" disablePadding>
                {listClassesProJudInfracionais
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((classe, index) => (
                    <Box key={classe.description}>
                      <ListItem
                        selected={selectedIndexProcessos === index}
                        button
                        className={classes.subNested}
                        onClick={event => handleClick(event, classe, index, '6')}
                        disabled={isPM ? handleVerifyDisabledItem(classe) : false}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={classe.description} />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </List>
            </Collapse>
            <ListItem
              button
              disabled={disableChangeClassByInfraction}
              onClick={handleOpenProcJudRecursos}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Recursos (Processos Judiciais)" />
              {!isNew && (
                <ListItemSecondaryAction style={{ left: '280px' }}>
                  <IconButton
                    disabled={disableChangeClassByInfraction}
                    onClick={event => handleClick(event, listClassesProJudRecursos, null, '12')}
                  >
                    <FiberManualRecordIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {openProcJudRecursos ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={openProcJudRecursos} timeout="auto">
              <List component="div" disablePadding>
                {listClassesProJudRecursos
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((classe, index) => (
                    <Box key={classe.description}>
                      <ListItem
                        selected={selectedIndexProcessos === index}
                        button
                        className={classes.subNested}
                        onClick={event => handleClick(event, classe, index, '6')}
                        disabled={disableChangeClassByInfraction || (isPM ? handleVerifyDisabledItem(classe) : false)}
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={classe.description} />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
              </List>
            </Collapse>
          </List>
        </Collapse>
      </List>
    </Dialog>
  );
};

export default ModalClasses;
