/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { HeaderModal, ButtonConsensus, InputConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import investigatedCertificateService from 'services/investigated-certificates/investigatedCertificateService';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  inputMaterial: {
    width: '100%',
  },
}));

const ModalAddFile = props => {
  const { open, onClose, inquiryInvestigatedId, existingCertificatesFiles, selectedType } = props;
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [files, setFiles] = useState([]);
  const [certificatesFiles, setLocalCertificatesFiles] = useState(existingCertificatesFiles || []);
  const [savedCertificates, setSavedCertificates] = useState(existingCertificatesFiles || []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLocalCertificatesFiles(existingCertificatesFiles || []);
    setSavedCertificates(existingCertificatesFiles || []);
  }, [existingCertificatesFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: files => getBase64(files),
  });

  const getBase64 = files => {
    files.forEach(file => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFiles(files => [
          ...files,
          {
            name: file.name,
            base64,
          },
        ]);
      };
      reader.onerror = function(error) {
        toast.warn(error);
      };
    });
  };

  const handleClose = () => {
    const hasChanges = !(
      (!files.length && !certificatesFiles.length) ||
      loading ||
      (certificatesFiles.length > 0 &&
        files.length === 0 &&
        certificatesFiles.every(certificateFile =>
          savedCertificates.some(
            c => c.name === certificateFile.name && c.type === certificateFile.type,
          ),
        ) &&
        savedCertificates.every(certificateFile =>
          certificatesFiles.some(
            c => c.name === certificateFile.name && c.type === certificateFile.type,
          ),
        ))
    );
    if (hasChanges) {
      toast.error('Você possui alterações que não foram salvas!');
      return;
    }

    setFiles([]);
    onClose();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!selectedType && !!files.length) {
      toast.warning('É necessário selecionar o tipo de certidão');
      return;
    }
    setLocalCertificatesFiles(certificatesFiles => [
      ...certificatesFiles,
      ...files.map(file => ({ ...file, type: selectedType })),
    ]);
    setFiles([]);
  };

  const saveCertificatesList = event => {
    event.preventDefault();
    setLoading(true);
    investigatedCertificateService
      .saveCertificateService({
        justification: null,
        type: selectedType,
        certificates: certificatesFiles,
        inquiryInvestigatedId,
      })
      .then(() => {
        setLocalCertificatesFiles(certificatesFiles);
        setSavedCertificates(certificatesFiles);
        toast.success('Certidões adicionadas com sucesso!');
        setFiles([]);
        onClose();
      })
      .catch(() => {
        toast.error('Erro ao adicionar certidões');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={clsx(classes.root)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, handleClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        fullWidth
        maxWidth="md"
      >
        <HeaderModal
          backgroundColor={colors.navBar}
          colorIconTitle={colors.greySecundary}
          onClose={handleClose}
        >
          Adicionar Certidões para tipo {selectedType}
        </HeaderModal>
        <DialogContent>
          <form
            onSubmit={
              (certificatesFiles.length > 0 && files.length === 0) ||
              (certificatesFiles.length === 0 && savedCertificates.length > 0)
                ? saveCertificatesList
                : handleSubmit
            }
          >
            <Grid container spacing={2}>
              {selectedType !== 'Juízo' && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ minHeight: 150, padding: 20 }}
                >
                  <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                    <input {...getInputProps()} disabled={loading} />
                    <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">Total de arquivos: {certificatesFiles.length}</Typography>
              </Grid>
            </Grid>
            {certificatesFiles.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {certificatesFiles.map((certificate, index) => (
                    <TableRow key={certificate.name}>
                      <TableCell align="center">{certificate.name}</TableCell>
                      <TableCell align="center">{selectedType}</TableCell>
                      <TableCell align="center">
                        <Tooltip arrow placement="top" title="Remover arquivo">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setLocalCertificatesFiles(certificatesFiles => [
                                ...certificatesFiles.filter((_item, i) => i !== index),
                              ]);
                            }}
                            disabled={selectedType === 'Juízo'}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {files.map((file, i) => (
              <Box display="flex" alignItems="center" key={i} width="100%">
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box display="flex" alignItems="center">
                    <PictureAsPdfIcon style={{ marginRight: 5 }} />
                    <Typography variant="body1">{file?.name}</Typography>
                  </Box>
                  <IconButton
                    onClick={() =>
                      setFiles(files => files.filter((_item, fileIndex) => fileIndex !== i))
                    }
                  >
                    <HighlightOffIcon style={{ color: '#e81224' }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {selectedType !== 'Juízo' && (
                  <ButtonConsensus
                    title={
                      (certificatesFiles.length > 0 && files.length === 0) ||
                      (certificatesFiles.length === 0 && savedCertificates.length > 0)
                        ? 'Salvar Certidões'
                        : 'Adicionar'
                    }
                    type="submit"
                    disabled={
                      (!files.length && !certificatesFiles.length && !savedCertificates.length) ||
                      loading ||
                      (certificatesFiles.length > 0 &&
                        files.length === 0 &&
                        certificatesFiles.every(certificate =>
                          savedCertificates.some(
                            c => c.name === certificate.name && c.type === certificate.type,
                          ),
                        ) &&
                        savedCertificates.every(certificate =>
                          certificatesFiles.some(
                            c => c.name === certificate.name && c.type === certificate.type,
                          ),
                        ))
                    }
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalAddFile;
