import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Card,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  colors,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ViewListIcon from '@material-ui/icons/ViewList';
import SubjectIcon from '@material-ui/icons/Subject';
import Alert from '@material-ui/lab/Alert';
import {
  ModalAddFile,
  ModalConfirm,
  ModalListFiles,
  ModalChangeNpu,
  ModalChangeDeadline,
  ModalJustification,
} from './components';
import { Label } from 'components';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import pmService from 'services/PM/pmService';
import movementTaskService from 'services/movement-task/movementTaskService';
import { useUserTypes } from 'hooks/useUserTypes';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ModalResponsible from 'components/ModalChangeLevelForm/components/ModalResponsible/ModalResponsible';
import pjeIntegrationService from 'services/pje-integration/pjeIntegrationService';
import InfoIcon from '@material-ui/icons/Info';
import ReplayIcon from '@material-ui/icons/Replay';
import * as _ from 'lodash';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import LogoPje from '../../assets/images/logo-pje.png';
import { formatNpuMask } from 'masks/masksImask';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ededed',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  titulo: {
    color: '#1c6b88',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingLeft: 6,
  },
  inactiveTask: {
    backgroundColor: '#DCDCDC',
    hover: '#DCDCDC',
  },
  iconpje: {
    backgroundColor: 'transparent',
    height: 25,
    width: 25,
  },
}));

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#1C6B88',
  },
}))(Tooltip);

const ProcessingTasks = props => {
  const {
    inquiry,
    setProcessing,
    refreshPage,
    canEdit = false,
    needNpuAndLocal,
    setNeedNpuAndLocal,
    disabled = false,
    ...rest
  } = props;

  const { isPM } = useUserTypes();
  const { enablePreRegisterPje, allOriginNumbersAreNpu } = useCustomerConfig();

  const classes = useStyles();

  //const [fileList, setFileList] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openModalAddFile, setModalAddFile] = useState(false);
  //const [selectedIndex, setSelectedIndex] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openModalListFiles, setOpenModalListFiles] = useState(false);
  const [files, setFiles] = useState(null);
  const [editNpu, setEditNpu] = useState(false);
  const [editDeadline, setEditDeadline] = useState(false);
  const [loadingUpdateDeadline, setLoadingUpdateDeadline] = useState(false);

  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const [responsibleTask, setResponsibleTask] = useState(false);

  const [openModalJustification, setOpenModalJustification] = useState(false);
  const [taskJustification, setTaskJustification] = useState(null);

  const [undoResponse, setUndoResponse] = useState(false);
  const [undoVerification, setUndoVerification] = useState(false);

  const getLastManifestation = manifestations => {
    return manifestations?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
  };

  const getLastManifestationError = manifestation => {
    return manifestation?.errors?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
  };

  const getLastManifestationErrorFromTask = task => {
    const lastManifestation = getLastManifestation(task.manifestations);
    return getLastManifestationError(lastManifestation);
  };

  const tasksWithPjeManifestationStatus = inquiry.movementTasks.filter(
    task =>
      task.integrateWithPJE &&
      task.isActive &&
      task.manifestations?.length > 0 &&
      (['DONE', 'ERROR', 'WAITING', 'PROCESSING_VERIFICATION', 'PROCESSING_RESPONSE'].includes(
        task.manifestations?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          ?.integrationStatus,
      ) ||
        getLastManifestationErrorFromTask(task)?.errorType === 'ORGAN_NOT_FOUND'),
  );
  const statusInPjeManifestations = tasksWithPjeManifestationStatus
    .map(task => {
      const lastManifestation = getLastManifestation(task.manifestations);
      const lastError = getLastManifestationError(lastManifestation);
      let message = '';
      if (['DONE', 'ERROR'].includes(lastManifestation.integrationStatus)) {
        message = lastError?.errorDescription?.split(';')[0];
      } else {
        message = 'Aguardando integração';
      }
      if (lastManifestation.integrationStatus === 'DONE' && inquiry.judgmentPlace) {
        message = '';
      }
      return { task, message };
    })
    .filter(task => task.message);

  const statusColors = {
    green: colors.green[600],
    red: colors.red[600],
  };

  const insertFiles = response => {
    let listFiles = {
      requestDocument: response.data.requestDocuments,
      responseDocument: response.data.responseDocuments,
      pjeManifestation: response.data.pjeFiles.filter(file => file.description === 'Manifestação'),
      pjeFiles: response.data.pjeFiles.filter(file => file.description !== 'Manifestação'),
    };

    setFiles(listFiles);
  };

  const getFilesTasks = async taskId => {
    if (isPM) {
      await pmService.getFilesTasksPmService(taskId).then(response => {
        insertFiles(response);
      });
    } else {
      await movementTaskService.getFilesTasksService(taskId).then(async response => {
        const responsePjeFilesProcess = await pjeIntegrationService.getPjeManifestationDocuments(
          taskId,
        );
        response.data.pjeFiles = responsePjeFilesProcess.data;
        insertFiles(response);
      });
    }
  };

  const showUndoVerification = task => {
    return (
      !task.awaitingVerification &&
      task.awaitingResponse &&
      task.isActive &&
      inquiry?.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE &&
      task.fulfilledByUser
    );
  };

  const showUndoResponse = task => {
    return (
      !task.awaitingResponse &&
      task.isActive &&
      inquiry?.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE &&
      task.respondedByUser
    );
  };

  const handleOpenModalResponsible = task => {
    setResponsibleTask(task);
    setOpenModalResponsible(true);
  };

  const handleOpenModalListFiles = async task => {
    await getFilesTasks(task.id);
    setOpenModalListFiles(true);
  };

  const handleCloseModalListFiles = () => {
    setOpenModalListFiles(false);
  };

  const handleCloseModalAddFile = () => {
    setModalAddFile(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedTask(null);
  };

  const handleOpenModalJustification = task => {
    setTaskJustification(task.justification);
    setOpenModalJustification(true);
  };

  const handleCloseModalJustification = () => {
    setOpenModalJustification(false);
    setTaskJustification(null);
  };

  useEffect(() => {
    if (inquiry.movementTasks?.length === 0) {
      setProcessing(true);
    } else {
      let count = 0;

      inquiry.movementTasks &&
        inquiry.movementTasks.forEach(task => {
          if (task.awaitingResponse) {
            count += 1;
          }

          if (
            task.type === 'DENUNCIA' ||
            task.type === 'ARQUIVAR' ||
            task.subType?.name === 'Manifestação ao Judiciário' ||
            task.subType?.name === 'Ciência de decisão'
          ) {
            if (!inquiry.npu || inquiry.npu === '' || !inquiry.judgmentPlace) {
              setNeedNpuAndLocal(true);
              count += 1;
            } else {
              setNeedNpuAndLocal(false);
            }
          }
        });

      count > 0 ? setProcessing(true) : setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiry.movementTasks]);

  useEffect(() => {}, [inquiry.movementTasks?.manifestations]);

  const handleOpenModalFile = (_event, index, task) => {
    //setSelectedIndex(index);
    setSelectedTask({ task, index });
    setModalAddFile(true);
  };

  const handleConfirmTask = (event, _index, task) => {
    event.preventDefault();

    if (isPM) {
      const { id } = task;

      pmService.sendMovementTasksPmService(id).then(() => {
        toast.success('Ação realizada com sucesso');
        refreshPage();
      });

      return;
    }

    const {
      id,
      npu,
      date,
      place,
      awaitingResponse,
      awaitingVerification,
      type,
      justification,
      subType,
      justificationForUndoing,
    } = task;

    let newTask = {
      id,
      justification,
      type,
      subType,
      justificationForUndoing,
      ignoreChangeFiles: true,
    };

    if (npu && npu !== '') {
      newTask.npu = npu;
      delete newTask.date;
    }

    if (date && date !== '') {
      newTask.date = date;
      delete newTask.npu;
    }

    if (place && place !== '') {
      newTask.place = place;
    }

    if (undoVerification) {
      newTask.awaitingVerification = true;
      return confirmTask(newTask);
    }

    if (undoResponse) {
      newTask.awaitingResponse = true;
      return confirmTask(newTask);
    }

    if (awaitingVerification && awaitingResponse) {
      newTask.awaitingVerification = false;
      newTask.awaitingResponse = true;
      confirmTask(newTask);
      return;
    }

    if (!awaitingVerification && awaitingResponse) {
      newTask.awaitingVerification = false;
      newTask.awaitingResponse = false;
      confirmTask(newTask);
    }
  };

  const confirmTask = newTask => {
    movementTaskService.updateMovementTasksService(newTask.id, newTask).then(() => {
      toast.success('Ação realizada com sucesso');
      refreshPage();
    });
  };

  const handleOpenModalConfirm = (
    _,
    index,
    task,
    undoVerification = false,
    undoResponse = false,
  ) => {
    setUndoVerification(undoVerification);
    setUndoResponse(undoResponse);
    setSelectedTask({ task: task, index });
    setOpenConfirm(true);
  };

  const handleOpenEditDeadline = (task, index) => {
    setSelectedTask({ task, index });
    setEditDeadline(true);
  };

  const handleUpdateDeadline = task => {
    setLoadingUpdateDeadline(true);

    let params = {
      date: task.date,
    };

    movementTaskService
      .updateMovementTasksService(task.id, params)
      .then(() => {
        toast.success('Prazo alterado com sucesso!');
        setEditDeadline(false);
        refreshPage();
        //refreshPage();
      })
      .finally(() => setLoadingUpdateDeadline(false));
  };

  const handleUpdateNpu = task => {
    let params = {
      npu: task.npu,
    };

    movementTaskService.updateMovementTasksService(task.id, params).then(() => {
      toast.success('NPU alterado com sucesso!');
      setEditNpu(false);
      refreshPage();
    });
  };

  const handleCloseChangeNpu = () => {
    setEditNpu(false);
  };

  const handleCloseChangeDeadline = () => {
    setEditDeadline(false);
  };

  return (
    <Card {...rest} className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        {inquiry.step !== STEPS_INQUIRY.MIGRACAO ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className={classes.titulo}>Tarefas</Typography>
          </Grid>
        ) : (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
            <Grid item>
              <Typography className={classes.titulo}>Tarefas</Typography>
            </Grid>
            <Grid item>
              <HtmlTooltip
                arrow
                title={
                  <>
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      ID da Tarefa Original:{' '}
                      {inquiry.inquiry?.fromMigratedInquiry?.movementTaskOriginalId ||
                        'Não Informado'}
                    </Typography>
                    <Typography variant="body2" className={classes.prosecutorTooltip}>
                      Descrição da Tarefa Original:{' '}
                      {inquiry.inquiry?.fromMigratedInquiry?.movementTaskName || 'Não Informado'}
                    </Typography>
                  </>
                }
                placement="right"
              >
                <InfoIcon fontSize="small" style={{ marginLeft: '8px', color: '#00597B' }} />
              </HtmlTooltip>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              {inquiry.movementTasks?.length < 1 && (
                <caption style={{ textAlign: 'center' }}>Não há tarefas adicionadas</caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body1">Tarefa</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">NPU</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Prazo</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Local</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Verificação</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Resposta</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Arquivos / Responsável / Justificativa</Typography>
                  </TableCell>
                  {[STEPS_INQUIRY.TRAMITACAO_CONTROLE, STEPS_INQUIRY.MIGRACAO].includes(
                    inquiry.step,
                  ) &&
                    !isPM &&
                    !disabled && (
                      <>
                        <TableCell align="center">
                          <Typography variant="body1">Anexar arquivo</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">Concluir tarefa</Typography>
                        </TableCell>
                      </>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {inquiry.movementTasks?.map((task, index) => {
                  return (
                    <Tooltip
                      key={index}
                      title={task.isActive ? '' : 'Tarefa Inativa'}
                      arrow
                      placement="top"
                    >
                      <TableRow
                        key={index}
                        hover={task.isActive}
                        className={!task.isActive && classes.inactiveTask}
                      >
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="body2">{task.subType.name}</Typography>
                          {enablePreRegisterPje && task.integrateWithPJE && (
                            <Typography>
                              <Tooltip
                                arrow
                                placement="top"
                                title="Tarefa marcada para integrar com o PJE"
                              >
                                <img className={classes.iconpje} src={LogoPje} />
                              </Tooltip>
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="body2">
                            {inquiry.npu ? formatNpuMask(inquiry.npu) : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <Typography variant="body2">
                                {task.date ? (
                                  <Moment format="DD/MM/YYYY">{task.date}</Moment>
                                ) : (
                                  'sem prazo'
                                )}
                              </Typography>
                            </Box>
                            {inquiry.step !== STEPS_INQUIRY.ANALISE &&
                              (task.awaitingVerification || task.awaitingResponse) &&
                              ((!isPM && canEdit) || canEdit) && (
                                <Tooltip arrow placement="top" title="Alterar Prazo">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleOpenEditDeadline(task, index)}
                                  >
                                    <EditIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="body2">{task.place?.name || '-'}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Label
                            color={
                              task.awaitingVerification ? statusColors.red : statusColors.green
                            }
                            variant="outlined"
                          >
                            {task.awaitingVerification ? 'Aguardando' : 'Realizado'}
                          </Label>
                          {showUndoVerification(task) && (
                            <Tooltip arrow placement="top" title="Desfazer Verificação">
                              <ReplayIcon
                                style={{ position: 'relative', top: 9, color: statusColors.red }}
                                size="sm"
                                onClick={event =>
                                  handleOpenModalConfirm(event, index, task, true, false)
                                }
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Label
                            color={task.awaitingResponse ? statusColors.red : statusColors.green}
                            variant="outlined"
                          >
                            {task.awaitingResponse ? 'Aguardando' : 'Realizado'}
                          </Label>
                          {showUndoResponse(task) && (
                            <Tooltip arrow placement="top" title="Desfazer Resposta">
                              <ReplayIcon
                                style={{ position: 'relative', top: 9, color: statusColors.red }}
                                size="sm"
                                onClick={event =>
                                  handleOpenModalConfirm(event, index, task, false, true)
                                }
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Tooltip arrow placement="top" title="Visualizar arquivos">
                              <IconButton onClick={() => handleOpenModalListFiles(task)}>
                                <ViewListIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="top" title="Visualizar responsável">
                              <IconButton onClick={() => handleOpenModalResponsible(task)}>
                                <AssignmentIndIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="top" title="Visualizar justificativa">
                              <IconButton onClick={() => handleOpenModalJustification(task)}>
                                <SubjectIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {[STEPS_INQUIRY.TRAMITACAO_CONTROLE, STEPS_INQUIRY.MIGRACAO].includes(
                          inquiry.step,
                        ) && !disabled ? (
                          <>
                            <TableCell component="th" scope="row" align="center">
                              {!task.awaitingVerification && !task.awaitingResponse ? (
                                <Tooltip placement="top" arrow title="Tarefa já concluída">
                                  <span>
                                    <IconButton disabled>
                                      <AttachFileIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip placement="top" arrow title="Adicionar arquivo à resposta">
                                  <IconButton
                                    onClick={event => handleOpenModalFile(event, index, task)}
                                  >
                                    <AttachFileIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {!task.awaitingVerification && !task.awaitingResponse ? (
                                <Tooltip placement="top" arrow title="Tarefa Concluída">
                                  <span>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      disabled={true}
                                    >
                                      Concluída
                                    </Button>
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title={
                                    task.awaitingVerification && task.awaitingResponse
                                      ? 'Cumprir Verificação'
                                      : !task.awaitingVerification && task.awaitingResponse
                                      ? 'Confirmar Resposta'
                                      : 'Confirmar'
                                  }
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={event => handleOpenModalConfirm(event, index, task)}
                                    disabled={!task.awaitingVerification && !task.awaitingResponse}
                                  >
                                    {task.awaitingVerification && task.awaitingResponse
                                      ? 'CUMPRIR'
                                      : !task.awaitingVerification && task.awaitingResponse
                                      ? 'RESPOSTA'
                                      : 'CONCLUÍDA'}
                                  </Button>
                                </Tooltip>
                              )}
                            </TableCell>
                          </>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {inquiry.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE && needNpuAndLocal && (
        <Box my={2} mx={1}>
          <Alert variant="filled" severity="warning">
            Necessário cadastrar n° de NPU e Vara/Local no item (Dados da Justiça) para prosseguir
            com a tramitação.
          </Alert>
        </Box>
      )}

      {inquiry.step === STEPS_INQUIRY.ANALISE && statusInPjeManifestations.length ? (
        <Box my={2} mx={1}>
          <Alert variant="filled" severity="warning">
            {statusInPjeManifestations.map((lastManifestation, index) => (
              <Typography key={index} variant="body1">
                {lastManifestation.task.subType.name}: {lastManifestation.message}
              </Typography>
            ))}
          </Alert>
        </Box>
      ) : null}

      {openModalAddFile && (
        <ModalAddFile
          open={openModalAddFile}
          onClose={handleCloseModalAddFile}
          selectedTask={selectedTask}
        />
      )}

      {openModalListFiles && (
        <ModalListFiles
          onClose={handleCloseModalListFiles}
          open={openModalListFiles}
          files={files}
        />
      )}

      {openConfirm && (
        <ModalConfirm
          open={openConfirm}
          onClose={handleCloseConfirm}
          task={selectedTask}
          confirmTask={handleConfirmTask}
          undoVerification={undoVerification}
          undoResponse={undoResponse}
        />
      )}

      {editNpu && (
        <ModalChangeNpu
          open={editNpu}
          onClose={handleCloseChangeNpu}
          task={selectedTask}
          confirmUpdateNpu={handleUpdateNpu}
        />
      )}

      {editDeadline && (
        <ModalChangeDeadline
          open={editDeadline}
          onClose={handleCloseChangeDeadline}
          task={selectedTask}
          confirmUpdateDeadline={handleUpdateDeadline}
          loading={loadingUpdateDeadline}
        />
      )}

      {openModalResponsible && (
        <ModalResponsible
          handleClose={() => setOpenModalResponsible(false)}
          open={openModalResponsible}
        >
          <>
            <Typography variant="h6" color="black">
              Promotor: {responsibleTask?.prosecutor?.name}
            </Typography>
            <br />
            <Typography variant="h6" color="black">
              Promotoria: {responsibleTask?.prosecutorOffice?.name}
            </Typography>
          </>
        </ModalResponsible>
      )}

      {openModalJustification && (
        <ModalJustification
          handleClose={handleCloseModalJustification}
          open={openModalJustification}
        >
          <Typography variant="h6" color="black">
            {taskJustification || 'Não informado'}
          </Typography>
        </ModalJustification>
      )}
    </Card>
  );
};

export default ProcessingTasks;
