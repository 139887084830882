import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { InputConsensus } from 'components';
import userService from 'services/user/userService';
import prosecutorOfficeService from 'services/prosecutor-office/prosecutorOfficeService';
import LazyAutocomplete from 'components/LazyAutocomplete';
import { AutoCompleteConfig } from 'constants/autocomplete';

const useStyles = makeStyles(() => ({
  prosecutorOfficeAndProsecutor: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

const ProsecutorOfficeAndProsecutorForm = props => {
  const {
    edit = true,
    prosecutor,
    prosecutorOffice,
    onProsecutorChange,
    onProsecutorOfficeChange,
    prosecutorOfficeGroup,
    isProsecutor,
    smaller,
  } = props;

  const classes = useStyles();

  const prosecutorDisabled = !prosecutorOffice || isProsecutor || !edit;

  const handleChangeProsecutor = (_event, newValue) => {
    onProsecutorChange(_event, newValue);
  };

  const fetchProsecutorOffices = async (search, page) => {
    const params = {
      prosecutorOfficeGroupId: prosecutorOfficeGroup?.id,
      name: search,
      page,
      size: AutoCompleteConfig.PAGINATION_SIZE,
    };

    const result = await prosecutorOfficeService.getProsecutorOfficesService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  const fetchProsecutors = async (search, page) => {
    const params = {
      prosecutorOfficeId: prosecutorOffice.id,
      name: search,
      page,
    };

    const result = await userService.getProsecutorsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    onProsecutorOfficeChange(_event, newValue);
    handleChangeProsecutor('', null);
  };

  const gridSize = smaller ? 5 : 6;

  return (
    <>
      <Grid container spacing={2} classes={classes.prosecutorOfficeAndProsecutor}>
        <Grid
          item
          xs={gridSize * 2}
          sm={gridSize * 2}
          md={gridSize * 2}
          lg={gridSize * 2}
          xl={gridSize * 2}
        >
          <Box mt={3}>
            <Typography variant="h6">Responsável pela Movimentação:</Typography>
          </Box>
        </Grid>
        <Grid item xs={gridSize * 2} sm={gridSize} md={gridSize} lg={gridSize} xl={gridSize}>
          <LazyAutocomplete
            autoHighlight
            onChange={handleChangeProsecutorOffice}
            value={prosecutorOffice}
            getOptionLabel={option => option.name || ''}
            renderOption={option => <>{option?.name}</>}
            enablePagination
            fetchOptions={fetchProsecutorOffices}
            disabled={isProsecutor || !edit}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Promotoria *"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={gridSize * 2} sm={gridSize} md={gridSize} lg={gridSize} xl={gridSize}>
          <LazyAutocomplete
            disabled={prosecutorDisabled}
            value={prosecutor}
            getOptionLabel={option => option.name}
            onChange={handleChangeProsecutor}
            renderOption={option => <>{option?.name}</>}
            fetchOptions={fetchProsecutors}
            refreshKey={prosecutorOffice?.id}
            enablePagination
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Promotor *"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProsecutorOfficeAndProsecutorForm;
