import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { makeStyles } from '@material-ui/styles';
import { Box, Pagination } from '@mui/material';
import releasedVersionService from 'services/released-version/releasedVersionService';

const ModalReleasedVersionsInfo = props => {
  const { releasedVersions, handleClose, open, onSignVersions } = props;

  const useStyles = makeStyles(theme => ({
    header: {
      width: '100%',
      backgroundColor: '#00597b',
      height: 50,
      color: '#ffffff',
      paddingLeft: 30,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [page, setPage] = useState(1);

  const handleConfirm = () => {
    releasedVersionService
      .signReleasedVersionService(releasedVersions.map(version => version.id))
      .then(() => {
        onSignVersions();
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleChangePage = (event, nextPage) => {
    if (nextPage !== page) {
      setPage(nextPage);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        scroll="paper"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal colorIconTitle="#fff" className={classes.header}>
          Informações da nova versão
        </HeaderModal>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: releasedVersions[page - 1].description }} />
        </DialogContent>
        <DialogActions>
          {releasedVersions.length > 1 && (
            <Box display="flex" justifyContent="center" pt={2}>
              <Pagination
                count={releasedVersions.length}
                page={page}
                onChange={handleChangePage}
                color="primary"
                size="small"
              />
            </Box>
          )}
          <ButtonConsensus
            title="Estou Ciente"
            disabled={page !== releasedVersions.length}
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalReleasedVersionsInfo;
