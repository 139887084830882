/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import CompanyDashboard from './layouts/CompanyDashboard';
import Investigated from './layouts/Investigated';
import Newlogin from './layouts/Newlogin';
import PmLayout from './layouts/PM';

const routes = [
  {
    path: '/',
    exact: true,
    component: Newlogin,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/Newlogin')),
      },
    ],
  },
  {
    path: '/newlogin',
    exact: true,
    component: Newlogin,
    routes: [
      {
        path: '/newlogin',
        exact: true,
        component: lazy(() => import('views/Newlogin')),
      },
    ],
  },
  {
    path: '/pm',
    component: PmLayout,
    routes: [
      {
        path: '/pm/dashboard',
        exact: true,
        component: lazy(() => import('views/PM/Dashboard')),
      },
      {
        path: '/pm/cadastro/inquerito',
        exact: true,
        component: lazy(() => import('views/PM/NewInquiry')),
      },
      {
        path: '/pm/cadastro/usuario',
        exact: true,
        component: lazy(() => import('views/PM/RegisterUserPm')),
      },
      {
        path: '/pm/cadastro-usuario-alterar',
        exact: true,
        component: lazy(() => import('views/PM/UpdateUserPm')),
      },
      {
        path: '/pm/inqueritos',
        exact: true,
        component: lazy(() => import('views/PM/ViewInquiriesPmDetails')),
      },
      {
        path: '/pm/inqueritos/fases',
        exact: true,
        component: lazy(() => import('views/PM/ViewInquiriesPm')),
      },
      {
        path: '/pm/detalhe/:id',
        exact: true,
        component: lazy(() => import('views/PM/DetailInquiryPm')),
      },
      {
        path: '/pm/configuracao-consensus',
        exact: true,
        component: lazy(() => import('views/ChangePasswordConsensus')),
      },
      {
        path: '/pm/inqueritos-devolvidos',
        exact: true,
        component: lazy(() => import('views/PM/ReturnedInquiries')),
      },
      {
        path: '/pm/detalhe/outras-fases/:id/:step',
        exact: true,
        component: lazy(() => import('views/DetailOthersSteps')),
      },
      {
        path: '/pm/inqueritos-pendentes/:id',
        exact: true,
        component: lazy(() => import('views/DetailPendingInquiry')),
      },
    ],
  },
  {
    path: '/company',
    component: CompanyDashboard,
    routes: [
      {
        path: '/company/dashboard',
        exact: true,
        component: lazy(() => import('views/AssociationDashboard')),
      },
      {
        path: '/company/profile',
        exact: true,
        component: lazy(() => import('views/ProfileCompany')),
      },
      {
        path: '/company/profile/edit',
        exact: true,
        component: lazy(() => import('views/ProfileEditCompany')),
      },
      {
        path: '/company/agreements',
        exact: true,
        component: lazy(() => import('views/CardsAgreementsCompany')),
      },
      {
        path: '/company/agreements/:id',
        exact: true,
        component: lazy(() => import('views/DetailCompanyAgreement')),
      },
      {
        path: '/company/forwarding-term',
        exact: true,
        component: lazy(() => import('views/ViewSearchForwardingTermCompany')),
      },
      {
        path: '/company/agreement-compliance',
        exact: true,
        component: lazy(() => import('views/ViewSearchAgreementComplianceCompany')),
      },
      {
        path: '/company/partner-institution',
        exact: true,
        component: lazy(() => import('views/RegisterPartners')),
      },
      {
        path: '/company/partner-institution/edit',
        exact: true,
        component: lazy(() => import('views/UpdatePartners')),
      },
      {
        path: '/company/service-solicitation',
        exact: true,
        component: lazy(() => import('views/CompanyService')),
      },
      {
        path: '/company/service-solicitation/edit',
        exact: true,
        component: lazy(() => import('views/UpdateCompanyService')),
      },
      {
        path: '/company/material-solicitation/edit',
        exact: true,
        component: lazy(() => import('views/UpdateCompanyMaterial')),
      },
      {
        path: '/company/register-materials/edit',
        exact: true,
        component: lazy(() => import('views/UpdateMaterial')),
      },
      {
        path: '/company/register-services',
        exact: true,
        component: lazy(() => import('views/RegisterServices')),
      },
      {
        path: '/company/register-services/edit',
        exact: true,
        component: lazy(() => import('views/UpdateServices')),
      },
      {
        path: '/company/register-projects',
        exact: true,
        component: lazy(() => import('views/RegisterProjects')),
      },
      {
        path: '/company/register-projects/edit',
        exact: true,
        component: lazy(() => import('views/UpdateProject')),
      },
    ],
  },
  {
    path: '/investigated',
    component: Investigated,
    routes: [
      {
        path: '/investigated/dashboard',
        exact: true,
        component: lazy(() => import('views/Investigated/Home')),
      },
      {
        path: '/investigated/hearing',
        exact: true,
        component: lazy(() => import('views/Investigated/Hearing')),
      },
      {
        path: '/investigated/chat',
        exact: true,
        component: lazy(() => import('views/Investigated/Chat')),
      },
    ],
  },
  {
    path: '/inquerito',
    component: DashboardLayout,
    routes: [
      {
        path: '/inquerito/cadastro',
        exact: true,
        component: lazy(() => import('views/NewInquiry')),
      },
      {
        path: '/inquerito/arquivo',
        exact: true,
        component: lazy(() => import('views/ViewPoliceInquiry')),
      },
      {
        path: '/inquerito/importar',
        exact: true,
        component: lazy(() => import('views/ImportInquiries')),
      },
    ],
  },
  {
    path: '/relatorio',
    component: DashboardLayout,
    routes: [
      {
        path: '/relatorio/performance-grupo-oficio',
        exact: true,
        component: lazy(() => import('views/Reports/ProsecutorReport')),
      },
      {
        path: '/relatorio/inqueritos-investigados',
        exact: true,
        component: lazy(() => import('views/Reports/InquiryReport')),
      },
      {
        path: '/relatorio/inqueritos-central',
        exact: true,
        component: lazy(() => import('views/Reports/CentralInquiryReport')),
      },
      {
        path: '/relatorio/cadastrados-pendentes',
        exact: true,
        component: lazy(() => import('views/Reports/PendingAndNonpendingReport')),
      },
      {
        path: '/relatorio/prazos-apfd',
        exact: true,
        component: lazy(() => import('views/Reports/ApfdReport')),
      },
      {
        path: '/relatorio/custodia-apfd',
        exact: true,
        component: lazy(() => import('views/Reports/ApfdCustodyReport')),
      },
      {
        path: '/relatorio/requisitorios-fora-do-prazo',
        exact: true,
        component: lazy(() => import('views/Reports/OutOfDateRequests')),
      },
      {
        path: '/relatorio/distribuicoes-e-movimentacoes',
        exact: true,
        component: lazy(() => import('views/Reports/DistributionsMoveReport')),
      },
      {
        path: '/relatorio/procedimentos-devolvidos',
        exact: true,
        component: lazy(() => import('views/Reports/ReturnedProceduresReport')),
      },
      {
        path: '/relatorio/pendentes-ou-em-triagem',
        exact: true,
        component: lazy(() => import('views/Reports/PendingOrTriageReport')),
      },
      {
        path: '/relatorio/integracao-pcpe',
        exact: true,
        component: lazy(() => import('views/Reports/PcpeIntegrationReport')),
      },
      {
        path: '/relatorio/corregedoria',
        exact: true,
        component: lazy(() => import('views/Reports/InternalAffairsReport')),
      },
      {
        path: '/relatorio/auditoria-procedimento',
        exact: true,
        component: lazy(() => import('views/Reports/ProcedureAuditReport')),
      },
      {
        path: '/relatorio/erros-importacao-arquimedes',
        exact: true,
        component: lazy(() => import('views/Reports/DecouplingArquimedesErrorReport')),
      },
      {
        path: '/relatorio/inqueritos-migrados',
        exact: true,
        component: lazy(() => import('views/Reports/InquiriesMigrationReport')),
      },
      {
        path: '/relatorio/auditoria-ia',
        exact: true,
        component: lazy(() => import('views/Reports/AiAuditReport')),
      },
      {
        path: '/relatorio/auditoria-usuario',
        exact: true,
        component: lazy(() => import('views/Reports/UserAuditReport')),
      },
      {
        path: '/relatorio/saldo-promotoria',
        exact: true,
        component: lazy(() => import('views/Reports/ProsecutorOfficeBalanceReport')),
      },
      {
        path: '/relatorio/cvli',
        exact: true,
        component: lazy(() => import('views/Reports/CvliReport')),
      },
      {
        path: '/relatorio/cnmp',
        exact: true,
        component: lazy(() => import('views/Reports/CnmpReport')),
      },
      {
        path: '/relatorio/auditoria-ai-pandora',
        exact: true,
        component: lazy(() => import('views/Reports/AiAuditPandoraReport')),
      },
    ],
  },

  {
    path: '/google/login',
    component: ErrorLayout,
    routes: [
      {
        path: '/google/login/success',
        exact: true,
        component: lazy(() =>
          import('views/CalendarProsecutor/ConfirmationLoginCalendar/SuccessLogin'),
        ),
      },
      {
        path: '/google/login/error',
        exact: true,
        component: lazy(() =>
          import('views/CalendarProsecutor/ConfirmationLoginCalendar/ErrorLogin'),
        ),
      },
    ],
  },
  {
    path: '/agenda-promotor',
    component: DashboardLayout,
    routes: [
      {
        path: '/agenda-promotor/calendario',
        exact: true,
        component: lazy(() => import('views/CalendarProsecutor/ViewCalendar')),
      },
    ],
  },
  {
    path: '/configuracao-google-autenticacao',
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-google-autenticacao',
        exact: true,
        component: lazy(() => import('views/CalendarProsecutor/LoginGoogleApi')),
      },
    ],
  },
  {
    path: '/triagem',
    component: DashboardLayout,
    routes: [
      {
        path: '/triagem/nao-persecucao',
        component: lazy(() => import('views/DetailScreeningNotPursuit')),
      },
    ],
  },
  {
    path: '/inqueritos-pendentes',
    component: DashboardLayout,
    routes: [
      {
        path: '/inqueritos-pendentes',
        exact: true,
        component: lazy(() => import('views/ViewPendingInquiries')),
      },
      {
        path: '/inqueritos-pendentes/:id',
        exact: true,
        component: lazy(() => import('views/DetailPendingInquiry')),
      },
    ],
  },
  {
    path: '/devolucao',
    component: DashboardLayout,
    routes: [
      {
        path: '/devolucao/lote',
        exact: true,
        component: lazy(() => import('views/BatchReturn')),
      },
    ],
  },
  {
    path: '/configuracao-consultas',
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-consultas/editar',
        exact: true,
        component: lazy(() => import('views/ConfigQuery/EditConfigQuery')),
      },
      {
        path: '/configuracao-consultas',
        exact: true,
        component: lazy(() => import('views/ConfigQuery/ListCreateConfigQuery')),
      },
    ],
  },
  {
    path: '/configuracao-email-delegacias',
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-email-delegacias',
        exact: true,
        component: lazy(() => import('views/EmailProsecutionConfig')),
      },
    ],
  },
  {
    path: '/Dashboard',
    component: DashboardLayout,
    routes: [
      {
        path: '/Dashboard',
        exact: true,
        component: lazy(() => import('views/Dashboard')),
      },
    ],
  },
  {
    path: '/inqueritos',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/inqueritos',
        exact: true,
        component: lazy(() => import('views/ViewInquiries')),
      },
    ],
  },
  {
    path: '/inqueritos-busca',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/inqueritos-busca',
        exact: true,
        component: lazy(() => import('views/SearchInquiries')),
      },
    ],
  },
  {
    path: '/inqueritos-vinculados/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/inqueritos-vinculados/:id',
        exact: true,
        component: lazy(() => import('views/ViewLinkingInquiries')),
      },
    ],
  },
  {
    path: '/pesquisa-e-distribuicao',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/pesquisa-e-distribuicao',
        exact: true,
        component: lazy(() => import('views/ViewSearchDistribution')),
      },
    ],
  },
  {
    path: '/migracao',
    component: DashboardLayout,
    routes: [
      {
        path: '/migracao',
        component: lazy(() => import('views/ViewSearchMigration')),
      },
    ],
  },
  {
    path: '/detalhe/migracao/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/migracao/:id',
        exact: true,
        component: lazy(() => import('views/DetailMigration')),
      },
    ],
  },
  {
    path: '/distribuicao',
    component: DashboardLayout,
    routes: [
      {
        path: '/distribuicao/lote',
        exact: true,
        component: lazy(() => import('views/BatchDistribution')),
      },
    ],
  },
  {
    path: '/detalhe/corregedoria',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/corregedoria',
        exact: true,
        component: lazy(() => import('views/DetailLifetime')),
      },
    ],
  },
  {
    path: '/sincronizacao-manual-de-investigados',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/sincronizacao-manual-de-investigados',
        exact: true,
        component: lazy(() => import('views/ManualSynchronizationOfInvestigated')),
      },
    ],
  },
  {
    path: '/sincronizacao-manual-de-investigados/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/sincronizacao-manual-de-investigados/:id',
        exact: true,
        component: lazy(() => import('views/ExecuteManualSynchronizationOfInvestigated')),
      },
    ],
  },
  {
    path: '/log-de-auditoria',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/log-de-auditoria',
        exact: true,
        component: lazy(() => import('views/AuditLog')),
      },
    ],
  },
  {
    path: '/baixa-de-carga',
    component: DashboardLayout,
    routes: [
      {
        path: '/baixa-de-carga',
        exact: true,
        component: lazy(() => import('views/LoadDrop')),
      },
    ],
  },
  {
    path: '/detalhe/intimacao/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/intimacao/:id',
        exact: true,
        component: lazy(() => import('views/DetailIntimation')),
      },
    ],
  },
  {
    path: '/detalhe/audiencia/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/audiencia/:id',
        exact: true,
        component: lazy(() => import('views/DetailHearing')),
      },
    ],
  },
  {
    path: '/acordo/:id/:hearingId',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/acordo/:id/:hearingId',
        exact: true,
        component: lazy(() => import('views/Agreement')),
      },
    ],
  },
  {
    path: '/acompanhar-medidas/:agreementId',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/acompanhar-medidas/:agreementId',
        exact: true,
        component: lazy(() => import('views/MonitorMeasures')),
      },
    ],
  },
  {
    path: '/oitiva/:id/:hearingId',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/oitiva/:id/:hearingId',
        exact: true,
        component: lazy(() => import('views/ChildInterview')),
      },
    ],
  },
  {
    path: '/detalhe/acordo/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/acordo/:id',
        exact: true,
        component: lazy(() => import('views/DetailAgreement')),
      },
    ],
  },
  {
    path: '/detalhe/termo-de-encaminhamento/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/termo-de-encaminhamento/:id',
        exact: true,
        component: lazy(() => import('views/DetailForwardingTerm')),
      },
    ],
  },
  {
    path: '/detalhe/tramitacao/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/tramitacao/:id',
        exact: true,
        component: lazy(() => import('views/DetailProcessingAndControl')),
      },
    ],
  },
  {
    path: '/detalhe/pesquisa-e-distribuicao/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/pesquisa-e-distribuicao/:id',
        exact: true,
        component: lazy(() => import('views/DetailSearchDistribuiton')),
      },
    ],
  },
  {
    path: '/detalhe/analise/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/analise/:id',
        exact: true,
        component: lazy(() => import('views/DetailAnalyze')),
      },
    ],
  },
  {
    path: '/detalhe/outras-fases/:id/:step',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/outras-fases/:id/:step',
        exact: true,
        component: lazy(() => import('views/DetailOthersSteps')),
      },
    ],
  },
  {
    path: '/triagem-nao-persecucao',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/triagem-nao-persecucao',
        exact: true,
        component: lazy(() => import('views/ViewscreeningNotPursuit')),
      },
    ],
  },
  {
    path: '/acompanhar-intimacoes',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/acompanhar-intimacoes',
        exact: true,
        component: lazy(() => import('views/ViewSearchIntimations')),
      },
    ],
  },
  {
    path: '/visualizar-audiencias',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/visualizar-audiencias',
        exact: true,
        component: lazy(() => import('views/ViewSearchHearing')),
      },
    ],
  },
  {
    path: '/visualizar-inquerito/:id/:step',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/visualizar-inquerito/:id/:step',
        exact: true,
        component: lazy(() => import('views/VisualizeOnlyInquiry')),
      },
    ],
  },
  {
    path: '/corregedoria',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/corregedoria',
        exact: true,
        component: lazy(() => import('views/ViewProsecutorLifetime')),
      },
    ],
  },
  {
    path: '/analise',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/analise',
        exact: true,
        component: lazy(() => import('views/ViewAnalyze')),
      },
    ],
  },
  {
    path: '/intimacao',
    component: DashboardLayout,
    routes: [
      {
        path: '/intimacao',
        exact: true,
        component: lazy(() => import('views/ViewIntimation')),
      },
      {
        path: '/intimacao/arquivo-confirmacao',
        exact: true,
        component: lazy(() => import('views/ViewIntimationConfirmFile')),
      },
    ],
  },
  {
    path: '/tramitacao-e-controle',
    component: DashboardLayout,
    routes: [
      {
        path: '/tramitacao-e-controle',
        exact: true,
        component: lazy(() => import('views/ViewSearchProcessingAndControl')),
      },
    ],
  },
  {
    path: '/acordo',
    component: DashboardLayout,
    routes: [
      {
        path: '/acordo',
        exact: true,
        component: lazy(() => import('views/ViewSearchAgreement')),
      },
    ],
  },
  {
    path: '/termo-de-encaminhamento',
    component: DashboardLayout,
    routes: [
      {
        path: '/termo-de-encaminhamento',
        exact: true,
        component: lazy(() => import('views/ViewSearchForwardingTerm')),
      },
    ],
  },
  {
    path: '/antecedentes-criminais',
    component: DashboardLayout,
    routes: [
      {
        path: '/antecedentes-criminais',
        exact: true,
        component: lazy(() => import('views/ViewCriminalAntecedentsFile')),
      },
    ],
  },
  {
    path: '/configuracao-intimacao',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-intimacao',
        exact: true,
        component: lazy(() => import('views/ViewIntimationConfig')),
      },
    ],
  },
  {
    path: '/configuracao-sds',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-sds',
        exact: true,
        component: lazy(() => import('views/SdsCredentials')),
      },
    ],
  },
  {
    path: '/configuracao-consensus',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/configuracao-consensus',
        exact: true,
        component: lazy(() => import('views/ChangePasswordConsensus')),
      },
    ],
  },
  {
    path: '/detalhe/triagem/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/detalhe/triagem/:id',
        exact: true,
        component: lazy(() => import('views/DetailScreeningNotPursuit')),
      },
    ],
  },
  {
    path: '/certidao/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/certidao/:id',
        exact: true,
        component: lazy(() => import('views/Certificate')),
      },
    ],
  },
  {
    path: '/cadastro-tipificacao',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-tipificacao',
        exact: true,
        component: lazy(() => import('views/RegisterTypification')),
      },
    ],
  },
  {
    path: '/cadastro-tipificacao/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-tipificacao/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateTypification')),
      },
    ],
  },
  {
    path: '/cadastro-lei',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-lei',
        exact: true,
        component: lazy(() => import('views/RegisterLaw')),
      },
    ],
  },
  {
    path: '/cadastro-lei/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-lei/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateLaw')),
      },
    ],
  },
  {
    path: '/cadastro-usuario',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-usuario',
        exact: true,
        component: lazy(() => import('views/RegisterUser')),
      },
    ],
  },
  {
    path: '/cadastro-usuario/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-usuario/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateUser')),
      },
    ],
  },
  {
    path: '/cadastro-grupo-oficio',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-grupo-oficio',
        exact: true,
        component: lazy(() => import('views/RegisterProsecutorOffices')),
      },
    ],
  },
  {
    path: '/cadastro-grupo-oficio/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-grupo-oficio/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateProsecutorOffices')),
      },
    ],
  },
  {
    path: '/cadastro-papeis',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-papeis',
        exact: true,
        component: lazy(() => import('views/RegisterPapers')),
      },
    ],
  },
  {
    path: '/cadastro-servicos',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-servicos',
        exact: true,
        component: lazy(() => import('views/RegisterServices')),
      },
    ],
  },
  {
    path: '/cadastro-materiais/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-materiais/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateMaterial')),
      },
    ],
  },
  {
    path: '/cadastro-servicos/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-servicos/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateServices')),
      },
    ],
  },
  {
    path: '/cadastro-instituicao',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-instituicao',
        exact: true,
        component: lazy(() => import('views/RegisterCompany')),
      },
    ],
  },
  {
    path: '/cadastro-instituicao/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-instituicao/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateCompany')),
      },
    ],
  },
  {
    path: '/cadastro-parametros',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-parametros',
        exact: true,
        component: lazy(() => import('views/RegisterParameters')),
      },
    ],
  },
  {
    path: '/cadastro-parametros/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-parametros/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateParameters')),
      },
    ],
  },
  {
    path: '/solicitacao-servico',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/solicitacao-servico',
        exact: true,
        component: lazy(() => import('views/CompanyService')),
      },
    ],
  },
  {
    path: '/solicitacao-servico/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/solicitacao-servico/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateCompanyService')),
      },
    ],
  },
  {
    path: '/solicitacao-material/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/solicitacao-material/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateCompanyMaterial')),
      },
    ],
  },
  {
    path: '/cadastro-delegacias',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-delegacias',
        exact: true,
        component: lazy(() => import('views/RegisterOrgans')),
      },
    ],
  },
  {
    path: '/cadastro-delegacias/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-delegacias/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateOrgans')),
      },
    ],
  },
  {
    path: '/configurar-papeis',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/configurar-papeis',
        exact: true,
        component: lazy(() => import('views/ConfigPapers')),
      },
    ],
  },
  {
    path: '/cadastro-projeto',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-projeto',
        exact: true,
        component: lazy(() => import('views/RegisterProjects')),
      },
    ],
  },
  {
    path: '/cadastro-projeto/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-projeto/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateProject')),
      },
    ],
  },
  {
    path: '/cadastro-assuntos',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-assuntos',
        exact: true,
        component: lazy(() => import('views/RegisterSubjects')),
      },
    ],
  },
  {
    path: '/cadastro-assuntos/registrar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-assuntos/registrar',
        exact: true,
        component: lazy(() => import('views/UpdateSubjects')),
      },
    ],
  },
  {
    path: '/cadastro-assuntos/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-assuntos/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateSubjects')),
      },
    ],
  },
  {
    path: '/cadastro-parceiros',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-parceiros',
        exact: true,
        component: lazy(() => import('views/RegisterPartners')),
      },
    ],
  },
  {
    path: '/cadastro-parceiros/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-parceiros/alterar',
        exact: true,
        component: lazy(() => import('views/UpdatePartners')),
      },
    ],
  },
  {
    path: '/cadastro-sema-cema',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-sema-cema',
        exact: true,
        component: lazy(() => import('views/RegisterSemasAndCemas')),
      },
    ],
  },
  {
    path: '/cadastro-sema-cema/alterar',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/cadastro-sema-cema/alterar',
        exact: true,
        component: lazy(() => import('views/UpdateSemasAndCemas')),
      },
    ],
  },
  {
    path: '/confirmacao-intimacao',
    exact: true,
    component: ErrorLayout,
    routes: [
      {
        path: '/confirmacao-intimacao',
        exact: true,
        component: lazy(() => import('views/ConfirmationIntimation')),
      },
    ],
  },
  {
    path: '/confirmacao-intimacao-falha',
    exact: true,
    component: ErrorLayout,
    routes: [
      {
        path: '/confirmacao-intimacao-falha',
        exact: true,
        component: lazy(() => import('views/ConfirmationIntimationFiled')),
      },
    ],
  },
  {
    path: '/visualizar-arquivos/:id',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/visualizar-arquivos/:id',
        exact: true,
        component: lazy(() => import('views/FileViewer')),
      },
    ],
  },
  {
    path: '/atividades-servidores-sema',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/atividades-servidores-sema',
        exact: true,
        component: lazy(() => import('views/SemaServerActivities')),
      },
    ],
  },
  {
    path: '/historico-atualizacoes',
    exact: true,
    component: DashboardLayout,
    routes: [
      {
        path: '/historico-atualizacoes',
        exact: true,
        component: lazy(() => import('views/ReleasedVersionsTimeline')),
      },
    ],
  },
  {
    path: '/termos-de-uso',
    exact: true,
    component: ErrorLayout,
    routes: [
      {
        path: '/termos-de-uso',
        exact: true,
        component: lazy(() => import('views/TermsOfUse')),
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        component: () => <Redirect to="/place" />,
      },
    ],
  },
  {
    route: '*',
    component: AuthLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('views/Dashboard')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
