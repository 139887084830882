import React from 'react';
import clsx from 'clsx';
import { Dialog, Paper, Grid, Typography, DialogContent, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DescriptionIcon from '@material-ui/icons/Description';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import { HeaderModal } from 'components';
import history from 'utils/history';
import ModalTrackingMail from 'components/ModalTrackingMail/ModalTrackingMail';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import intimationService from 'services/intimation/intimationService';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#00597b',
  },
  textTitle: {
    color: '#ffffff',
  },
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  closeButton: {
    color: '#ffffff',
  },
  header: {
    backgroundColor: '#00597b',
    //height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    //fontSize: 20,
    //display: 'flex',
    //justifyContent: 'space-between',
    //alignItems: 'center'
  },
  body: {
    backgroundColor: '#ededed',
    borderRadius: 5,
    padding: 10,
    marginBottom: theme.spacing(1),
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  table: {
    minWidth: 450,
  },
}));

const ModalHistoryIntimation = props => {
  const { open, onClose, className, intimations, ...rest } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [trackingIntimation, setTrackingIntimation] = React.useState(null);
  const [trackingIntimationAttempt, setTrackingIntimationAttempt] = React.useState(null);
  const [trackingCode, setTrackingCode] = React.useState('');

  const getUrlConfirmIntimationManual = intimationId => {
    intimationService.getIntimationLetter(intimationId).then(response => {
      history.push('/intimacao/arquivo-confirmacao', response.data.url);
    });
  };

  const getUrlConfirmExternalIntimationManual = intimationId => {
    intimationService.getIntimationConfirmationCertificates(intimationId).then(response => {
      response.data.certificates.forEach(certificate => {
        window.open(certificate.url, '_blank');
      });
    });
  };

  const handleGoToConfirmIntimationManual = intimation => {
    if (intimation.isExternal) {
      getUrlConfirmExternalIntimationManual(intimation.id);
    } else {
      getUrlConfirmIntimationManual(intimation.id);
    }
  };

  const handleTraking = (intimation, attempt) => {
    getTrackingCode(intimation, attempt);
  };

  const handleCloseTracking = () => {
    setTrackingCode('');
    setTrackingIntimation(null);
    setTrackingIntimationAttempt(null);
  };

  const getTrackingCode = async (intimation, attempt) => {
    setTrackingIntimation(intimation);
    setTrackingIntimationAttempt(attempt);
    const response = await intimationService.getTrackingCode(attempt.id);
    setTrackingCode(response.data);
  };

  const subpoenaName = intimation => {
    const inquiryClasse = intimation.inquiryInvestigated.inquiry?.classe;
    const investigated = intimation.inquiryInvestigated.investigated;
    const useRepresentantLegalName = isInfraction(inquiryClasse);
    if (useRepresentantLegalName && investigated.representantLegal) {
      return investigated.representantLegal.name;
    }
    return investigated.name;
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Histórico de Intimações</HeaderModal>
        <DialogContent>
          <div
            style={{
              width: '100%',

              paddingTop: 5,
            }}
          >
            <Typography className={classes.label}>
              {intimations.length > 0 ? subpoenaName(intimations[0]) : ''}
            </Typography>
          </div>
          {intimations.length > 0 ? (
            intimations.map((intimation, intimationIdx) => {
              return (
                <Paper elevation={2} key={intimationIdx} className={classes.body}>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.label}>
                        Tipo da Intimação: {''}
                        {intimation.type === 'AGREEMENT_COMPLIANCE'
                          ? 'acordo'
                          : intimation.type === 'HEARING'
                          ? `audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`
                          : intimation.type}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.label}>
                        Status: {''}
                        {intimation.status === 'AWAITING_RESPONSE'
                          ? 'Aguardando resposta da intimação'
                          : intimation.status === 'CANCELED'
                          ? 'Intimação não executada'
                          : intimation.status === 'FAILED'
                          ? 'Intimação não executada'
                          : intimation.status === 'SUCCESS'
                          ? 'Intimação realizada com sucesso'
                          : 'Não intimado'}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.label}>
                        Data da Intimação: {''}
                        <Moment format="DD/MM/YYYY" local>
                          {intimation.createdAt}
                        </Moment>
                      </Typography>
                    </Grid>
                    {intimation.type === 'HEARING' && (
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography className={classes.label}>
                          Data da Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''}:{' '}
                          <Moment format="DD/MM/YYYY" local>
                            {intimation.hearing?.date}
                          </Moment>{' '}
                          {' às '}
                          <Moment format="HH:mm">{intimation.hearing?.date}</Moment>
                        </Typography>
                      </Grid>
                    )}
                    {/*<Grid
                                            item
                                            xl={6} lg={6} md={6} sm={6} xs={6}
                                        >
                                            <Typography className={classes.label}>Data da criação da Intimação: {''}
                                                <Moment format="DD/MM/YYYY" local>{v.createdAt}</Moment>
                                            </Typography>
                                        </Grid>*/}
                    {intimation.attempts.length > 0 ? (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: 10 }}>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Meio de contato</TableCell>
                                <TableCell align="center">Contato</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Data</TableCell>
                                <TableCell align="center">Anexo</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {intimation.attempts.map(attempt => (
                                <TableRow key={attempt.id}>
                                  <TableCell align="center">{attempt.mode}</TableCell>
                                  <TableCell align="center">{attempt.contactInfo}</TableCell>
                                  <TableCell align="center">
                                    {attempt.status === 'AWAITING_RESPONSE'
                                      ? 'Aguardando resposta da intimação'
                                      : attempt.status === 'CANCELED'
                                      ? 'Intimação não executada'
                                      : attempt.status === 'FAILED'
                                      ? 'Intimação não executada'
                                      : attempt.status === 'SUCCESS'
                                      ? 'Intimação realizada com sucesso'
                                      : 'Não intimado'}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Moment format="DD/MM/YYYY" local>
                                      {attempt.createdAt}
                                    </Moment>
                                  </TableCell>
                                  <TableCell align="center">
                                    {attempt.mode === 'MANUAL' && intimation.status === 'SUCCESS' ? (
                                      <IconButton
                                        aria-label="file"
                                        onClick={() => handleGoToConfirmIntimationManual(intimation)}
                                      >
                                        <DescriptionIcon />
                                      </IconButton>
                                    ) : attempt.mode === 'CORREIOS_AR' ? (
                                      <Tooltip title="Acompanhar intimação">
                                        <IconButton
                                          aria-label="mail"
                                          onClick={() => handleTraking(intimation, attempt)}
                                        >
                                          <MailOutlineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      'não anexado'
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : (
                      <Typography
                        style={{ paddingLeft: 10, paddingTop: 5 }}
                        className={classes.label}
                      >
                        Não houve tentativas
                      </Typography>
                    )}
                  </Grid>
                </Paper>
              );
            })
          ) : (
            <div className={classes.body}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} direction="column">
                  <Typography className={classes.label}>Não há informações anteriores</Typography>
                </Grid>
              </Grid>
            </div>
          )}
          {/*<Grid
                        container
                        style={{ padding: 15 }}
                    >
                        <Grid
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}
                        >
                            <ButtonCustom
                                backgroundColor='#00b1ac'
                                hovercolor="#0dd1cc"
                                borderColor="#7d7d7d"
                                color="#ffffff"
                                tittle="FECHAR"
                                height="60px"
                                width="170px"
                                mediaWidth="100%"
                                onClick={handleBack}
                            />
                        </Grid>
                    </Grid>*/}
        </DialogContent>
      </Dialog>
      {trackingIntimation && trackingIntimationAttempt && (
        <ModalTrackingMail
          open={open}
          intimation={trackingIntimation}
          intimationAttempt={trackingIntimationAttempt}
          trackingCode={trackingCode}
          onClose={handleCloseTracking}
        />
      )}
    </div>
  );
};

export default ModalHistoryIntimation;
