import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import SearchIcon from '@material-ui/icons/Search';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import AssignmentReturn from '@material-ui/icons/AssignmentReturn';
import SettingsIcon from '@material-ui/icons/Settings';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TodayIcon from '@material-ui/icons/Today';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  activeConfigSdsReducer,
  activeConfigConsensusReducer,
  setActiveConfigQuery,
  logout,
  navbarLogout,
  activeInqueritosReducer,
  activeInqueritosBuscaReducer,
  activeBatchDistribution,
  activeBatchDevolution,
  activeTriagemNaoPersecucao,
  activePesquisaDistribuicaoReducer,
  activeAnaliseReducer,
  activeProcessingControllReducer,
  activeIntimacaoReducer,
  activeVisualizarIntimacaoReducer,
  activeVisualizarAudienciaReducer,
  activeVisualizarConfiguracoesIntimacao,
  activeVisualizarRegistroUsuario,
  activeVisualizarRegistroPapeis,
  activeVisualizarRegisterServices,
  activeVisualizarRegisterParameters,
  activeVisualizarRegistroGrupoOficio,
  activeVisualizarRegistroAssociation,
  activeVisualizarRegistroRequestService,
  activeVisualizarRelatorio,
  activeVisualizarRelatorioInquerito,
  setActiveAddInquerito,
  setActiveImportInquerito,
  setActiveConfigLoginGoogleApi,
  setActiveSchedule,
  setActiveTypification,
  activeOpenReducer,
  activeOpen1Reducer,
  activeOpen2Reducer,
  activeOpen3Reducer,
  activeOpen4Reducer,
  activeOpen5Reducer,
  inactiveOpenReducer,
  inactiveOpen1Reducer,
  inactiveOpen2Reducer,
  inactiveOpen3Reducer,
  inactiveOpen4Reducer,
  inactiveOpen5Reducer,
  setSelectedTabIndexMaterialService,
  setSelectedTabIndexMaterialServiceSolicitation,
  activeRelatorioCentralInquerito,
  setActiveRegisterOrgan,
  setActiveApfdCustodyReport,
  activeRelatorioApfd,
  activeRelatorioRequisitorioForaDoPrazo,
  setActiveReportDistributionsMove,
  activeRelatorioPendentesCadastrados,
  setActiveBaixaDeCarga,
  setActiveReportReturnedProcedures,
  activeVisualizarRegisterProject,
  activeAcordoReducer,
  activeCumprimentoAcordoReducer,
  setActiveReportPendingOrTriage,
  setActiveReportPcpeIntegration,
  setActiveProsecutorLifetime,
  setActiveReportInternalAffairs,
  setActiveRegisterLaw,
  setActiveRegisterSubject,
  setActiveRegisterPartnerAssociations,
  setActiveProcedureAuditReport,
  setActiveUserAuditReport,
  setActiveManualSynchronizationOfInvestigated,
  setActiveRegisterSemaAndCemaAssociations,
  setActiveAuditLogView,
  setActiveDecuplingArquimedesErrorReport,
  setActiveInquiriesMigrationReport,
  setActiveAIAuditReport,
  setActiveAIAuditPandoraReport,
  setActiveProsecutorOfficeBalanceReport,
  setActiveSemaServersActivities,
  setActiveCvliReport,
  setActiveCnmpReport,
  clearAiMessages,
} from 'actions';
import { toast } from 'react-toastify';
import { useFilterInquiry } from 'hooks/useFilterInquiry';
import history from 'utils/history';
import clsx from 'clsx';
import { CUSTOMER_CONFIG } from 'constants/customerConfig';
import { CustomerDisplayValidator } from 'components';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'transparent',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconColor: {
    color: '#ffffff',
  },
  labelColor: {
    color: '#c1c6c9',
    width: '100%',
  },
  iconActive: {
    color: '#ffffff',
  },
  labelActive: {
    color: '#ffffff',
    width: '100%',
  },
  containerListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerDivListItem: {
    display: 'flex',
    alignItems: 'center',
  },
  activebg: {
    backgroundColor: '#024463',
  },
  bg: {
    backgroundColor: 'transparent',
  },
  selectedMenu: {
    boxShadow: `3px 0px 0px 0px #ffffff inset`,
    transition: 1,
  },
}));

export default function NestedList(_props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    enableHearingForJuvenileOffenders,
    enableSemaAndCemaAssociationRegistration,
  } = useCustomerConfig();

  const {
    open,
    open1,
    open2,
    open3,
    open4,
    open5,
    activeDashboard,
    batchDistribution,
    batchDevolution,
    activeInqueritos,
    activeInqueritosBusca,
    activeTriagem,
    activePesquisaDistribuicao,
    activeAnalise,
    activeProcessingControll,
    activeIntimacao,
    activeVisualizarIntimacao,
    activeVisualizarAudiencia,
    activeConfigIntimacao,
    activeConfigSds,
    activeConfigQuery,
    activeConfigConsensus,
    activeRegisterUser,
    activeRegisterPaper,
    activeRegisterParameters,
    activeGroupOffice,
    activeAddService,
    activeAddInquiry,
    activeImportInquiry,
    activeAssociation,
    activeRequestService,
    activeReport,
    activeReportInquiry,
    activeConfigGoogleApi,
    activeSchedule,
    activeTypification,
    activeRegisterLaw,
    activeReportCenterInquiry,
    activeReportDistributionsMove,
    activeReportReturnedProcedures,
    activeRegisterOrgans,
    activeReportApfd,
    activeApfdCustodyReport,
    activeReportOutOfDateRequests,
    activeReportPendingNonpending,
    activeBaixaDeCarga,
    activeRegisterProject,
    activeAcordo,
    activeCumprimentoAcordo,
    activeReportPendingOrTriage,
    activeReportInternalAffairs,
    activeReportPcpeIntegration,
    activeProsecutorLifetime,
    activeRegisterSubject,
    activeProcedureAuditReport,
    activeUserAuditReport,
    activeManualSynchronizationOfInvestigated,
    activeRegisterPartnerAssociations,
    activeRegisterSemaAndCemaAssociations,
    activeAuditLogView,
    activeDecuplingArquimedesErrorReport,
    activeInquiriesMigrationReport,
    activeAIAuditReport,
    activeAIAuditPandoraReport,
    activeProsecutorOfficeBalanceReport,
    activeSemaServersActivities,
    activeCvliReport,
    activeCnmpReport,
  } = useSelector(state => state.navbar);

  const { clearStatesFilterInquiryContext } = useFilterInquiry();

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const handleOpenSnackbar = (message = 'Você não tem permissão para acessar esta tela.') => {
    toast.warn(message);
    return;
  };

  const handleLogout = () => {
    dispatch(navbarLogout());
    dispatch(clearAiMessages());
    dispatch(logout());
  };

  const handleOpen = () => {
    if (open) {
      dispatch(inactiveOpenReducer());
    } else {
      dispatch(activeOpenReducer());
    }
  };

  const handleOpen1 = () => {
    if (open1) {
      dispatch(inactiveOpen1Reducer());
    } else {
      dispatch(activeOpen1Reducer());
    }
  };

  const handleOpen2 = () => {
    if (open2) {
      dispatch(inactiveOpen2Reducer());
    } else {
      dispatch(activeOpen2Reducer());
    }
  };

  const handleOpen3 = () => {
    if (open3) {
      dispatch(inactiveOpen3Reducer());
    } else {
      dispatch(activeOpen3Reducer());
    }
  };

  const handleOpen4 = () => {
    if (open4) {
      dispatch(inactiveOpen4Reducer());
    } else {
      dispatch(activeOpen4Reducer());
    }
  };

  const handleOpen5 = () => {
    if (open5) {
      dispatch(inactiveOpen5Reducer());
    } else {
      dispatch(activeOpen5Reducer());
    }
  };

  const handleClickInquirysMenu = () => {
    if (permissionsReducer.inquiries?.read) {
      clearStatesFilterInquiryContext();
      dispatch(activeInqueritosReducer());
    } else {
      handleOpenSnackbar('Você não tem permissão para acessar a tela de Visualizar Inquéritos.');
    }
  };

  const handleClickInquirysSearch = () => {
    if (permissionsReducer.searchInquiries?.read) {
      clearStatesFilterInquiryContext();
      dispatch(activeInqueritosBuscaReducer());
    } else {
      handleOpenSnackbar('Você não tem permissão para acessar a tela de Buscar Inquéritos.');
    }
  };

  return (
    <>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <RouterLink to={`/Dashboard`}>
          <ListItem
            button
            onClick={() => {
              clearStatesFilterInquiryContext();
              dispatch(navbarLogout());
            }}
            className={activeDashboard ? classes.selectedMenu : null}
          >
            <ListItemIcon className={activeDashboard ? classes.iconActive : classes.iconColor}>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={activeDashboard ? classes.iconActive : classes.iconColor}>
              Visão Geral
            </Typography>
          </ListItem>
        </RouterLink>

        <RouterLink to={permissionsReducer?.inquiries?.read ? `/inqueritos` : '#'}>
          <ListItem
            button
            onClick={handleClickInquirysMenu}
            className={activeInqueritos ? classes.selectedMenu : null}
          >
            <ListItemIcon className={activeInqueritos ? classes.iconActive : classes.iconColor}>
              <ViewListIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={activeInqueritos ? classes.iconActive : classes.iconColor}>
              Inquéritos
            </Typography>
          </ListItem>
        </RouterLink>

        <RouterLink to={permissionsReducer?.searchInquiries?.read ? `/inqueritos-busca` : '#'}>
          <ListItem
            button
            onClick={handleClickInquirysSearch}
            className={activeInqueritosBusca ? classes.selectedMenu : null}
          >
            <ListItemIcon
              className={activeInqueritosBusca ? classes.iconActive : classes.iconColor}
            >
              <SearchIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={activeInqueritosBusca ? classes.iconActive : classes.iconColor}>
              Buscar Inquérito
            </Typography>
          </ListItem>
        </RouterLink>
        <ListItem button onClick={handleOpen} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <ViewStreamIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Fases</Typography>
          </div>
          <div>
            {open ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={open ? classes.activebg : classes.bg}
        >
          <List component="div" disablePadding>
            <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.DISPLAY_TRIAGE_STEP}>
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeTriagem,
                })}
                onClick={() => {
                  if (
                    permissionsReducer?.inquiriesTriagem?.read ||
                    permissionsReducer?.inquiriesTriagem?.write
                  ) {
                    clearStatesFilterInquiryContext();
                    dispatch(activeTriagemNaoPersecucao());
                    history.push('/triagem-nao-persecucao', { statusInquiries: '' });
                  } else {
                    handleOpenSnackbar('Você não tem permissão para acessar a tela de Triagem.');
                  }
                }}
              >
                <Typography className={activeTriagem ? classes.iconActive : classes.iconColor}>
                  Triagem
                </Typography>
              </ListItem>
            </CustomerDisplayValidator>

            <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.DISPLAY_DISTRIBUTION_STEP}>
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activePesquisaDistribuicao,
                })}
                onClick={() => {
                  if (
                    permissionsReducer?.inquiriesDistribuicao?.read ||
                    permissionsReducer?.inquiriesDistribuicao?.write
                  ) {
                    clearStatesFilterInquiryContext();
                    dispatch(activePesquisaDistribuicaoReducer());
                    history.push('/pesquisa-e-distribuicao', { statusInquiries: '' });
                  } else {
                    handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Pesquisa e Distribuição.',
                    );
                  }
                }}
              >
                <Typography
                  className={activePesquisaDistribuicao ? classes.iconActive : classes.iconColor}
                >
                  Pesquisa e Distribuição
                </Typography>
              </ListItem>
            </CustomerDisplayValidator>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAnalise,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesAnalise?.read ||
                  permissionsReducer?.inquiriesAnalise?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeAnaliseReducer());
                  history.push('/analise', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar('Você não tem permissão para acessar a tela de Análise.');
                }
              }}
            >
              <Typography className={activeAnalise ? classes.iconActive : classes.iconColor}>
                Análise
              </Typography>
            </ListItem>

            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeProcessingControll,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesTasks?.read ||
                  permissionsReducer?.inquiriesTasks?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeProcessingControllReducer());
                  history.push('/tramitacao-e-controle', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar(
                    'Você não tem permissão para acessar a tela de Tramitação e Controle.',
                  );
                }
              }}
            >
              <Typography
                className={activeProcessingControll ? classes.iconActive : classes.iconColor}
              >
                Tramitação e Controle
              </Typography>
            </ListItem>

            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeIntimacao,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesIntimacao?.read ||
                  permissionsReducer?.inquiriesIntimacao?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeIntimacaoReducer());
                  history.push('/intimacao', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar('Você não tem permissão para acessar a tela de Intimação.');
                }
              }}
            >
              <Typography className={activeIntimacao ? classes.iconActive : classes.iconColor}>
                Intimação
              </Typography>
            </ListItem>

            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeVisualizarAudiencia,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesAudience?.read ||
                  permissionsReducer?.inquiriesAudience?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeVisualizarAudienciaReducer());
                  history.push('/visualizar-audiencias', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar(
                    `Você não tem permissão para acessar a tela de Audiência${
                      enableHearingForJuvenileOffenders ? '/Oitiva' : ''
                    }.`,
                  );
                }
              }}
            >
              <Typography
                className={activeVisualizarAudiencia ? classes.iconActive : classes.iconColor}
              >
                Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''}
              </Typography>
            </ListItem>

            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAcordo,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesForwardingTerm?.read ||
                  permissionsReducer?.inquiriesForwardingTerm?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeAcordoReducer());
                  history.push('/termo-de-encaminhamento', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar(
                    'Você não tem permissão para acessar a tela de Termo de Encaminhamento.',
                  );
                }
              }}
            >
              <Typography className={activeAcordo ? classes.iconActive : classes.iconColor}>
                Termo de Encaminhamento
              </Typography>
            </ListItem>

            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeCumprimentoAcordo,
              })}
              onClick={() => {
                if (
                  permissionsReducer?.inquiriesAgreement?.read ||
                  permissionsReducer?.inquiriesAgreement?.write
                ) {
                  clearStatesFilterInquiryContext();
                  dispatch(activeCumprimentoAcordoReducer());
                  history.push('/acordo', { statusInquiries: '' });
                } else {
                  handleOpenSnackbar(
                    'Você não tem permissão para acessar a tela de Cumprimento de Acordo.',
                  );
                }
              }}
            >
              <Typography
                className={activeCumprimentoAcordo ? classes.iconActive : classes.iconColor}
              >
                Cumprimento de Acordo
              </Typography>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleOpen1} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <ViewModuleIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Módulos</Typography>
          </div>
          <div className={classes.containerDivListItem}>
            {open1 ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open1}
          timeout="auto"
          unmountOnExit
          className={open1 ? classes.activebg : classes.bg}
        >
          <RouterLink
            to={permissionsReducer?.intimationView?.read ? `/acompanhar-intimacoes` : '#'}
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeVisualizarIntimacao,
              })}
              onClick={() =>
                permissionsReducer?.intimationView?.read
                  ? dispatch(activeVisualizarIntimacaoReducer())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Visualizar Intimações.',
                    )
              }
            >
              <Typography
                className={activeVisualizarIntimacao ? classes.iconActive : classes.iconColor}
              >
                Visualizar Intimações
              </Typography>
            </ListItem>
          </RouterLink>
          {permissionsReducer?.prosecutorLifetime?.read && (
            <RouterLink to={permissionsReducer?.prosecutorLifetime?.read ? `/corregedoria` : '#'}>
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeProsecutorLifetime,
                })}
                onClick={() =>
                  permissionsReducer?.prosecutorLifetime?.read
                    ? dispatch(setActiveProsecutorLifetime())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Corregedoria.',
                      )
                }
              >
                <Typography
                  className={activeProsecutorLifetime ? classes.iconActive : classes.iconColor}
                >
                  Corregedoria
                </Typography>
              </ListItem>
            </RouterLink>
          )}
          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED}
          >
            <RouterLink
              to={
                permissionsReducer?.manualSynchronizationOfInvestigated?.read
                  ? `/sincronizacao-manual-de-investigados`
                  : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeManualSynchronizationOfInvestigated,
                })}
                onClick={() =>
                  permissionsReducer?.manualSynchronizationOfInvestigated?.read
                    ? dispatch(setActiveManualSynchronizationOfInvestigated())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Sincronização Manual de Investigados.',
                      )
                }
              >
                <Typography
                  className={
                    activeManualSynchronizationOfInvestigated
                      ? classes.iconActive
                      : classes.iconColor
                  }
                >
                  Sincronização de Investigados
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>
          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.ENABLE_AUDIT_LOG_VIEW}>
            <RouterLink to={permissionsReducer?.auditLogView?.read ? `/log-de-auditoria` : '#'}>
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeAuditLogView,
                })}
                onClick={() =>
                  permissionsReducer?.auditLogView?.read
                    ? dispatch(setActiveAuditLogView())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Log de Auditoria.',
                      )
                }
              >
                <Typography className={activeAuditLogView ? classes.iconActive : classes.iconColor}>
                  Consultar Log de Auditoria
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>
          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ENABLE_SEMA_SERVER_ACTIVITIES_VIEW}
          >
            <RouterLink
              to={
                permissionsReducer?.semaServerActivities?.read ? `/atividades-servidores-sema` : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeSemaServersActivities,
                })}
                onClick={() =>
                  permissionsReducer?.semaServerActivities?.read
                    ? dispatch(setActiveSemaServersActivities())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Atividades Servidores SEMA.',
                      )
                }
              >
                <Typography
                  className={activeSemaServersActivities ? classes.iconActive : classes.iconColor}
                >
                  Atividades Servidores SEMA
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>
        </Collapse>

        <ListItem button onClick={handleOpen2} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Configurações</Typography>
          </div>
          <div>
            {open2 ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open2}
          timeout="auto"
          unmountOnExit
          className={open2 ? classes.activebg : classes.bg}
        >
          <RouterLink
            to={
              permissionsReducer?.inquiriesConfigurarIntimacao?.read ||
              permissionsReducer?.inquiriesConfigurarIntimacao?.write
                ? `/configuracao-intimacao`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeConfigIntimacao,
              })}
              onClick={() =>
                permissionsReducer.inquiriesConfigurarIntimacao.read ||
                permissionsReducer.inquiriesConfigurarIntimacao.write
                  ? dispatch(activeVisualizarConfiguracoesIntimacao())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela Configuração de Intimações.',
                    )
              }
            >
              <Typography
                className={activeConfigIntimacao ? classes.iconActive : classes.iconColor}
              >
                Intimação
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.inquiriesSenhaSds?.read ||
              permissionsReducer?.inquiriesSenhaSds?.write
                ? `/configuracao-sds`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeConfigSds,
              })}
              onClick={() =>
                permissionsReducer.inquiriesSenhaSds.read ||
                permissionsReducer.inquiriesSenhaSds.write
                  ? dispatch(activeConfigSdsReducer())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Configurar acesso à SDS.',
                    )
              }
            >
              <Typography className={activeConfigSds ? classes.iconActive : classes.iconColor}>
                Acesso SDS
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink to={`/configuracao-consensus`}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeConfigConsensus,
              })}
              onClick={() => {
                dispatch(activeConfigConsensusReducer());
              }}
            >
              <Typography
                className={activeConfigConsensus ? classes.iconActive : classes.iconColor}
              >
                Senha Consensus
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.socioeconomicSearch?.read ||
              permissionsReducer?.socioeconomicSearch?.write
                ? `/configuracao-consultas`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeConfigQuery,
              })}
              onClick={() =>
                permissionsReducer.socioeconomicSearch.read ||
                permissionsReducer.socioeconomicSearch.write
                  ? dispatch(setActiveConfigQuery())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Consultas Dados Socioeconômicos.',
                    )
              }
            >
              <Typography className={activeConfigQuery ? classes.iconActive : classes.iconColor}>
                Consultas Dados Socioeconômicos
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink to={`/configuracao-google-autenticacao`}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeConfigGoogleApi,
              })}
              onClick={() => {
                dispatch(setActiveConfigLoginGoogleApi());
              }}
            >
              <Typography
                className={activeConfigGoogleApi ? classes.iconActive : classes.iconColor}
              >
                Google Autenticação
              </Typography>
            </ListItem>
          </RouterLink>
        </Collapse>

        <ListItem button onClick={handleOpen5} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <TodayIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Agenda</Typography>
          </div>
          <div>
            {open5 ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open5}
          timeout="auto"
          unmountOnExit
          className={open5 ? classes.activebg : classes.bg}
        >
          <RouterLink
            to={
              permissionsReducer?.schedule?.read || permissionsReducer?.schedule?.write
                ? `/agenda-promotor/calendario`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeSchedule,
              })}
              onClick={() =>
                permissionsReducer.schedule.read || permissionsReducer.schedule.write
                  ? dispatch(setActiveSchedule())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Agenda Promotor.',
                    )
              }
            >
              <Typography className={activeSchedule ? classes.iconActive : classes.iconColor}>
                Agenda Promotor
              </Typography>
            </ListItem>
          </RouterLink>
        </Collapse>

        <ListItem button onClick={handleOpen3} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Cadastros</Typography>
          </div>
          <div>
            {open3 ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open3}
          timeout="auto"
          unmountOnExit
          className={open3 ? classes.activebg : classes.bg}
        >
          <RouterLink
            to={
              permissionsReducer?.inquiriesUsuario?.read ||
              permissionsReducer?.inquiriesUsuario?.write
                ? `/cadastro-usuario`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeRegisterUser,
              })}
              onClick={() =>
                permissionsReducer.inquiriesUsuario.read ||
                permissionsReducer.inquiriesUsuario.write
                  ? dispatch(activeVisualizarRegistroUsuario())
                  : handleOpenSnackbar('Você não tem permissão para acessar a tela de Usuários.')
              }
            >
              <Typography className={activeRegisterUser ? classes.iconActive : classes.iconColor}>
                Cadastro de Usuário
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.inquiriesPapeis?.read ||
              permissionsReducer?.inquiriesPapeis?.write
                ? `/cadastro-papeis`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeRegisterPaper,
              })}
              onClick={() =>
                permissionsReducer.inquiriesPapeis.read || permissionsReducer.inquiriesPapeis.write
                  ? dispatch(activeVisualizarRegistroPapeis())
                  : handleOpenSnackbar('Você não tem permissão para acessar a tela de Papéis.')
              }
            >
              <Typography className={activeRegisterPaper ? classes.iconActive : classes.iconColor}>
                Cadastro de Papéis
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.parameters?.read || permissionsReducer?.parameters?.write
                ? `/cadastro-parametros`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeRegisterParameters,
              })}
              onClick={() =>
                permissionsReducer.parameters.read || permissionsReducer.parameters.write
                  ? dispatch(activeVisualizarRegisterParameters())
                  : handleOpenSnackbar('Você não tem permissão para acessar a tela de Parâmetros.')
              }
            >
              <Typography
                className={activeRegisterParameters ? classes.iconActive : classes.iconColor}
              >
                Cadastro de Parâmetros
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.inquiriesGrupoOficio?.read ||
              permissionsReducer?.inquiriesGrupoOficio?.write
                ? '/cadastro-grupo-oficio'
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeGroupOffice,
              })}
              onClick={() =>
                permissionsReducer.inquiriesGrupoOficio.read ||
                permissionsReducer.inquiriesGrupoOficio.write
                  ? dispatch(activeVisualizarRegistroGrupoOficio())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Grupo Ofício.',
                    )
              }
            >
              <Typography className={activeGroupOffice ? classes.iconActive : classes.iconColor}>
                Cadastro de Grupo Ofício
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.crimesPermission?.read ||
              permissionsReducer?.crimesPermission?.write
                ? '/cadastro-tipificacao'
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeTypification,
              })}
              onClick={() =>
                permissionsReducer.crimesPermission.read ||
                permissionsReducer.crimesPermission.write
                  ? dispatch(setActiveTypification())
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar a tela de Tipificações.',
                    )
              }
            >
              <Typography className={activeTypification ? classes.iconActive : classes.iconColor}>
                Cadastro de Tipificações
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.servicesAndMaterials?.read ||
              permissionsReducer?.servicesAndMaterials?.write
                ? '/cadastro-servicos'
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAddService,
              })}
              onClick={() => {
                if (
                  permissionsReducer.servicesAndMaterials.read ||
                  permissionsReducer.servicesAndMaterials.write
                ) {
                  dispatch(activeVisualizarRegisterServices());
                  dispatch(setSelectedTabIndexMaterialService(0));
                } else {
                  handleOpenSnackbar(
                    'Você não tem permissão para acessar a tela de Serviços e Materiais.',
                  );
                }
              }}
            >
              <Typography className={activeAddService ? classes.iconActive : classes.iconColor}>
                Cadastro de Serviços e Materiais
              </Typography>
            </ListItem>
          </RouterLink>

          {!enableSemaAndCemaAssociationRegistration && (
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAssociation,
              })}
              onClick={() => {
                permissionsReducer?.associations?.read
                  ? dispatch(activeVisualizarRegistroAssociation()) &&
                    history.push('/cadastro-instituicao')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o Cadastro de Instituições.',
                    );
              }}
            >
              <Typography className={activeAssociation ? classes.iconActive : classes.iconColor}>
                Cadastro de Instituições
              </Typography>
            </ListItem>
          )}

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeRegisterProject,
            })}
            onClick={() => {
              permissionsReducer?.projects?.read
                ? dispatch(activeVisualizarRegisterProject()) && history.push('/cadastro-projeto')
                : handleOpenSnackbar('Você não tem permissão para acessar o Cadastro de Projetos.');
            }}
          >
            <Typography className={activeRegisterProject ? classes.iconActive : classes.iconColor}>
              Cadastro de Projetos
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeRegisterOrgans,
            })}
            onClick={() => {
              permissionsReducer?.organs?.read
                ? dispatch(setActiveRegisterOrgan()) && history.push('/cadastro-delegacias')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o Cadastro de Delegacias/Varas.',
                  );
            }}
          >
            <Typography className={activeRegisterOrgans ? classes.iconActive : classes.iconColor}>
              Cadastro de Delegacias/Varas
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeRequestService,
            })}
            onClick={() => {
              permissionsReducer?.solicitationMaterialAndService?.read
                ? dispatch(activeVisualizarRegistroRequestService()) &&
                  dispatch(setSelectedTabIndexMaterialServiceSolicitation(0)) &&
                  history.push('/solicitacao-servico')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar a Solicitação de Serviços e Materiais.',
                  );
            }}
          >
            <Typography className={activeRequestService ? classes.iconActive : classes.iconColor}>
              Solicitação de Serviços e Materiais
            </Typography>
          </ListItem>

          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.INQUIRY_MANUAL_REGISTRATION}>
            <RouterLink
              to={
                permissionsReducer.registerInquiries?.read ||
                permissionsReducer.registerInquiries?.write
                  ? `/inquerito/cadastro`
                  : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeAddInquiry,
                })}
                onClick={() =>
                  permissionsReducer.registerInquiries?.read ||
                  permissionsReducer.registerInquiries?.write
                    ? dispatch(setActiveAddInquerito())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Cadastro de Inquéritos.',
                      )
                }
              >
                <Typography className={activeAddInquiry ? classes.iconActive : classes.iconColor}>
                  Cadastro de Inquéritos
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>

          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.INQUIRY_IMPORT}>
            <RouterLink
              to={
                permissionsReducer.registerInquiries?.read ||
                permissionsReducer.registerInquiries?.write
                  ? `/inquerito/importar`
                  : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeImportInquiry,
                })}
                onClick={() =>
                  permissionsReducer.registerInquiries?.read ||
                  permissionsReducer.registerInquiries?.write
                    ? dispatch(setActiveImportInquerito())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Importação de Inquéritos.',
                      )
                }
              >
                <Typography
                  className={activeImportInquiry ? classes.iconActive : classes.iconColor}
                >
                  Importação de Inquéritos
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>

          <RouterLink
            to={
              permissionsReducer.lawsPermission.read || permissionsReducer.lawsPermission.write
                ? `/cadastro-lei`
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeRegisterLaw,
              })}
              onClick={() =>
                permissionsReducer.lawsPermission.read || permissionsReducer.lawsPermission.write
                  ? dispatch(setActiveRegisterLaw())
                  : handleOpenSnackbar('Você não tem permissão para acessar a tela de Leis.')
              }
            >
              <Typography className={activeRegisterLaw ? classes.iconActive : classes.iconColor}>
                Cadastro de Leis
              </Typography>
            </ListItem>
          </RouterLink>

          <RouterLink
            to={
              permissionsReducer?.crimeSubjectPermission?.read ||
              permissionsReducer?.crimeSubjectPermission?.write
                ? '/cadastro-assuntos'
                : '#'
            }
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeRegisterSubject,
              })}
              onClick={() =>
                permissionsReducer.crimeSubjectPermission.read ||
                permissionsReducer.crimeSubjectPermission.write
                  ? dispatch(setActiveRegisterSubject())
                  : handleOpenSnackbar('Você não tem permissão para acessar a tela de Assuntos.')
              }
            >
              <Typography
                className={activeRegisterSubject ? classes.iconActive : classes.iconColor}
              >
                Cadastro de Assuntos
              </Typography>
            </ListItem>
          </RouterLink>

          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION}
          >
            <RouterLink
              to={
                permissionsReducer?.semaAndCemaAssociationsPermission?.read ||
                permissionsReducer?.semaAndCemaAssociationsPermission?.write
                  ? '/cadastro-sema-cema'
                  : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeRegisterSemaAndCemaAssociations,
                })}
                onClick={() =>
                  permissionsReducer?.semaAndCemaAssociationsPermission?.read ||
                  permissionsReducer?.semaAndCemaAssociationsPermission?.write
                    ? dispatch(setActiveRegisterSemaAndCemaAssociations())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Instituições SEMA/CEMA.',
                      )
                }
              >
                <Typography
                  className={
                    activeRegisterSemaAndCemaAssociations ? classes.iconActive : classes.iconColor
                  }
                >
                  Cadastro de Instituições SEMA/CEMA
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>

          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ENABLE_PARTNER_ASSOCIATION_REGISTRATION}
          >
            <RouterLink
              to={
                permissionsReducer?.partnerAssociationsPermission?.read ||
                permissionsReducer?.partnerAssociationsPermission?.write
                  ? '/cadastro-parceiros'
                  : '#'
              }
            >
              <ListItem
                button
                className={clsx(...classes.nested, {
                  [classes.selectedMenu]: activeRegisterPartnerAssociations,
                })}
                onClick={() =>
                  permissionsReducer?.partnerAssociationsPermission?.read ||
                  permissionsReducer?.partnerAssociationsPermission?.write
                    ? dispatch(setActiveRegisterPartnerAssociations())
                    : handleOpenSnackbar(
                        'Você não tem permissão para acessar a tela de Instituições Parceiras.',
                      )
                }
              >
                <Typography
                  className={activeRegisterSubject ? classes.iconActive : classes.iconColor}
                >
                  Cadastro de Instituições Parceiras
                </Typography>
              </ListItem>
            </RouterLink>
          </CustomerDisplayValidator>
        </Collapse>

        <ListItem button onClick={handleOpen4} className={classes.containerListItem}>
          <div className={classes.containerDivListItem}>
            <ListItemIcon className={classes.iconColor}>
              <AssessmentIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Relatórios</Typography>
          </div>
          <div className={classes.containerDivListItem}>
            {open4 ? (
              <ExpandMore className={classes.iconColor} />
            ) : (
              <KeyboardArrowRightIcon className={classes.iconColor} />
            )}
          </div>
        </ListItem>
        <Collapse
          in={open4}
          timeout="auto"
          unmountOnExit
          className={open4 ? classes.activebg : classes.bg}
        >
          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReport,
            })}
            onClick={() => {
              permissionsReducer?.prosecutorOfficeGroupPerformanceReport?.read
                ? dispatch(activeVisualizarRelatorio()) &&
                  history.push('/relatorio/performance-grupo-oficio')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Performance Grupo Ofício.',
                  );
            }}
          >
            <Typography className={activeReport ? classes.iconActive : classes.iconColor}>
              Performance Grupo Ofício
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportInquiry,
            })}
            onClick={() => {
              permissionsReducer?.inquiryReport?.read
                ? dispatch(activeVisualizarRelatorioInquerito()) &&
                  history.push('/relatorio/inqueritos-investigados')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Inquéritos (investigados).',
                  );
            }}
          >
            <Typography className={activeReportInquiry ? classes.iconActive : classes.iconColor}>
              Inquéritos (investigados)
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportCenterInquiry,
            })}
            onClick={() => {
              permissionsReducer?.prosecutorOfficePerformanceReport?.read
                ? dispatch(activeRelatorioCentralInquerito()) &&
                  history.push('/relatorio/inqueritos-central')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Performance da Comarca.',
                  );
            }}
          >
            <Typography
              className={activeReportCenterInquiry ? classes.iconActive : classes.iconColor}
            >
              Performance da Comarca
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportApfd,
            })}
            onClick={() => {
              permissionsReducer?.apfdDeadlineReport?.read
                ? dispatch(activeRelatorioApfd()) && history.push('/relatorio/prazos-apfd')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Prazos APFD.',
                  );
            }}
          >
            <Typography className={activeReportApfd ? classes.iconActive : classes.iconColor}>
              Prazos APFD
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeApfdCustodyReport,
            })}
            onClick={() => {
              permissionsReducer?.apfdCustodyReport?.read
                ? dispatch(setActiveApfdCustodyReport()) && history.push('/relatorio/custodia-apfd')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Custódia APFD.',
                  );
            }}
          >
            <Typography
              className={activeApfdCustodyReport ? classes.iconActive : classes.iconColor}
            >
              Custódia APFD
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportOutOfDateRequests,
            })}
            onClick={() =>
              permissionsReducer?.relatorioRequisitoriosForaDoPrazo?.read
                ? dispatch(activeRelatorioRequisitorioForaDoPrazo()) &&
                  history.push('/relatorio/requisitorios-fora-do-prazo')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Requisitórios fora do prazo.',
                  )
            }
          >
            <Typography
              className={activeReportOutOfDateRequests ? classes.iconActive : classes.iconColor}
            >
              Requisitórios fora do prazo
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportDistributionsMove,
            })}
            onClick={() =>
              permissionsReducer?.relatorioDistribuiçõesEmovimentações?.read
                ? dispatch(setActiveReportDistributionsMove()) &&
                  history.push('/relatorio/distribuicoes-e-movimentacoes')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Distribuições e movimentações.',
                  )
            }
          >
            <Typography
              className={activeReportDistributionsMove ? classes.iconActive : classes.iconColor}
            >
              Distribuições e movimentações
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportReturnedProcedures,
            })}
            onClick={() =>
              permissionsReducer?.relatorioProcedimentosDevolvidos?.read
                ? dispatch(setActiveReportReturnedProcedures()) &&
                  history.push('/relatorio/procedimentos-devolvidos')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Procedimentos Devolvidos.',
                  )
            }
          >
            <Typography
              className={activeReportReturnedProcedures ? classes.iconActive : classes.iconColor}
            >
              Procedimentos Devolvidos
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportPendingNonpending,
            })}
            onClick={() =>
              permissionsReducer?.relatorioCadastradosEpendentes?.read
                ? dispatch(activeRelatorioPendentesCadastrados()) &&
                  history.push('/relatorio/cadastrados-pendentes')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de IPs/APFDs Cadastrados e Pendentes.',
                  )
            }
          >
            <Typography
              className={activeReportPendingNonpending ? classes.iconActive : classes.iconColor}
            >
              IPs/APFDs Cadastrados e Pendentes
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportPendingOrTriage,
            })}
            onClick={() =>
              permissionsReducer?.relatorioPendentesOuEmTriagem?.read
                ? dispatch(setActiveReportPendingOrTriage()) &&
                  history.push('/relatorio/pendentes-ou-em-triagem')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Procedimentos pendentes ou em triagem.',
                  )
            }
          >
            <Typography
              className={activeReportPendingOrTriage ? classes.iconActive : classes.iconColor}
            >
              Procedimentos pendentes ou em triagem
            </Typography>
          </ListItem>

          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.VIEW_PCPE_INTEGRATION_REPORT}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeReportPcpeIntegration,
              })}
              onClick={() =>
                permissionsReducer?.pcpeIntegrationReport?.read
                  ? dispatch(setActiveReportPcpeIntegration()) &&
                    history.push('/relatorio/integracao-pcpe')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Inquéritos da base de integração PCPE.',
                    )
              }
            >
              <Typography
                className={activeReportPcpeIntegration ? classes.iconActive : classes.iconColor}
              >
                Inquéritos da base de integração PCPE
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          
          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeReportInternalAffairs,
            })}
            onClick={() =>
              permissionsReducer?.internalAffairsReport?.read
                ? dispatch(setActiveReportInternalAffairs()) &&
                  history.push('/relatorio/corregedoria')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de corregedoria.',
                  )
            }
          >
            <Typography
              className={activeReportInternalAffairs ? classes.iconActive : classes.iconColor}
            >
              Corregedoria
            </Typography>
          </ListItem>
          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.VIEW_PROCEDURE_AUDIT_REPORT}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeProcedureAuditReport,
              })}
              onClick={() =>
                permissionsReducer?.procedureAuditReport?.read
                  ? dispatch(setActiveProcedureAuditReport()) &&
                    history.push('/relatorio/auditoria-procedimento')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Auditoria de procedimento.',
                    )
              }
            >
              <Typography
                className={activeProcedureAuditReport ? classes.iconActive : classes.iconColor}
              >
                Auditoria de procedimento
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ENABLE_INQUIRIES_MIGRATION_REPORT}
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeInquiriesMigrationReport,
              })}
              onClick={() =>
                permissionsReducer?.inquiriesMigrationReport?.read
                  ? dispatch(setActiveInquiriesMigrationReport()) &&
                    history.push('/relatorio/inqueritos-migrados')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Inquéritos Migrados.',
                    )
              }
            >
              <Typography
                className={activeInquiriesMigrationReport ? classes.iconActive : classes.iconColor}
              >
                Inquéritos Migrados
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          <CustomerDisplayValidator
            customerConfig={CUSTOMER_CONFIG.ENABLE_ERROR_IMPORT_ARQUIMEDES_INTEGRATION_REPORT_VIEW}
          >
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeDecuplingArquimedesErrorReport,
              })}
              onClick={() =>
                permissionsReducer?.errorImportArquimedesIntegrationReportView?.read
                  ? dispatch(setActiveDecuplingArquimedesErrorReport()) &&
                    history.push('/relatorio/erros-importacao-arquimedes')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Erros de Importação no Arquimedes.',
                    )
              }
            >
              <Typography
                className={
                  activeDecuplingArquimedesErrorReport ? classes.iconActive : classes.iconColor
                }
              >
                Erros Importação Arquimedes
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.CONNECT_WITH_AI_SERVICE}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAIAuditReport,
              })}
              onClick={() =>
                permissionsReducer?.aiAuditReport?.read
                  ? dispatch(setActiveAIAuditReport()) && history.push('/relatorio/auditoria-ia')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Auditoria da IA.',
                    )
              }
            >
              <Typography className={activeAIAuditReport ? classes.iconActive : classes.iconColor}>
                Auditoria da IA
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          
          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.CONNECT_WITH_AI_SERVICE}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeAIAuditPandoraReport,
              })}
              onClick={() => {
                permissionsReducer?.aiAuditPandoraReport?.read
                  ? dispatch(setActiveAIAuditPandoraReport()) &&
                    history.push('/relatorio/auditoria-ai-pandora')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Auditoria da IA Pandora.',
                    );
              }}
            >
              <Typography
                className={activeAIAuditPandoraReport ? classes.iconActive : classes.iconColor}
              >
                Auditoria da IA Pandora
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>

          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.VIEW_USER_AUDIT_REPORT}>
            <ListItem
              button
              className={clsx(...classes.nested, {
                [classes.selectedMenu]: activeUserAuditReport,
              })}
              onClick={() =>
                permissionsReducer?.userAuditReport?.read
                  ? dispatch(setActiveUserAuditReport()) &&
                    history.push('/relatorio/auditoria-usuario')
                  : handleOpenSnackbar(
                      'Você não tem permissão para acessar o relatório de Auditoria de usuário.',
                    )
              }
            >
              <Typography
                className={activeUserAuditReport ? classes.iconActive : classes.iconColor}
              >
                Auditoria de usuário
              </Typography>
            </ListItem>
          </CustomerDisplayValidator>
          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeProsecutorOfficeBalanceReport,
            })}
            onClick={() =>
              permissionsReducer?.prosecutorOfficeBalanceReport?.read
                ? dispatch(setActiveProsecutorOfficeBalanceReport()) &&
                  history.push('/relatorio/saldo-promotoria')
                : handleOpenSnackbar(
                    'Você não tem permissão para acessar o relatório de Saldo da Promotoria.',
                  )
            }
          >
            <Typography
              className={
                activeProsecutorOfficeBalanceReport ? classes.iconActive : classes.iconColor
              }
            >
              Saldo da Promotoria
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeCvliReport,
            })}
            onClick={() => {
              permissionsReducer?.cvliReport?.read
                ? dispatch(setActiveCvliReport()) && history.push('/relatorio/cvli')
                : handleOpenSnackbar('Você não tem permissão para acessar o relatório CVLI.');
            }}
          >
            <Typography className={activeCvliReport ? classes.iconActive : classes.iconColor}>
              Relatório CVLI
            </Typography>
          </ListItem>

          <ListItem
            button
            className={clsx(...classes.nested, {
              [classes.selectedMenu]: activeCnmpReport,
            })}
            onClick={() => {
              permissionsReducer?.cnmpReport?.read
                ? dispatch(setActiveCnmpReport()) && history.push('/relatorio/cnmp')
                : handleOpenSnackbar('Você não tem permissão para acessar o relatório CNMP.');
            }}
          >
            <Typography className={activeCnmpReport ? classes.iconActive : classes.iconColor}>
              Relatório CNMP
            </Typography>
          </ListItem>
        </Collapse>
        <ListItem
          button
          onClick={() => {
            if (!permissionsReducer?.batchDistribution?.read) {
              toast.warn('Você não tem permissão para acessar a tela de Distribuição em Lote.');
              return;
            } else {
              dispatch(activeBatchDistribution());
              history.push('/distribuicao/lote');
            }
          }}
          className={batchDistribution ? classes.selectedMenu : null}
        >
          <ListItemIcon className={batchDistribution ? classes.iconActive : classes.iconColor}>
            <LineWeightIcon fontSize="small" />
          </ListItemIcon>
          <Typography className={batchDistribution ? classes.iconActive : classes.iconColor}>
            Distribuição em Lote
          </Typography>
        </ListItem>
        <ListItem
          button
          onClick={() => {
            if (!permissionsReducer?.batchReturn?.write) {
              toast.warn('Você não tem permissão para acessar a tela de Devolução em Lote.');
              return;
            } else {
              dispatch(activeBatchDevolution());
              history.push('/devolucao/lote');
            }
          }}
          className={batchDevolution ? classes.selectedMenu : null}
        >
          <ListItemIcon className={batchDevolution ? classes.iconActive : classes.iconColor}>
            <AssignmentReturn fontSize="small" />
          </ListItemIcon>
          <Typography className={batchDevolution ? classes.iconActive : classes.iconColor}>
            Devolução em Lote
          </Typography>
        </ListItem>

        <ListItem
          button
          onClick={() =>
            permissionsReducer?.discharge?.read
              ? dispatch(setActiveBaixaDeCarga()) && history.push('/baixa-de-carga')
              : handleOpenSnackbar('Você não tem permissão para acessar a tela de baixa de carga.')
          }
          className={activeBaixaDeCarga ? classes.selectedMenu : null}
        >
          <ListItemIcon className={activeBaixaDeCarga ? classes.iconActive : classes.iconColor}>
            <PlayForWorkIcon fontSize="small" />
          </ListItemIcon>
          <Typography className={activeBaixaDeCarga ? classes.iconActive : classes.iconColor}>
            Baixa de Carga
          </Typography>
        </ListItem>
        <RouterLink to={`/`}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.iconColor}>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography className={classes.iconColor}>Sair</Typography>
          </ListItem>
        </RouterLink>
      </List>
    </>
  );
}
