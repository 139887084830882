import * as actionTypes from 'actions';
import { OLD_URLS } from 'constants/old-urls';
import { TOKENS_AUTHENTICATION } from 'constants/tokens-authentication';
import { handleAllPermissions, handleAllRoles } from 'utils/permission.utils';

const initialState = {
  loged: false,
  userId: '',
  name: '',
  type: '',
  token: '',
  issuedAt: '',
  expiresAt: '',
  email: '',
  groups: [],
  prosecutorOffices: [],
  permissions: handleAllPermissions(),
  hasManualInvestigatedSync: false,
  advisorProsecutor: null,
  roles: [],
};

const logedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN:
      return {
        ...state,
        loged: action.payload.loged,
        userId: action.payload.id,
        name: action.payload.name,
        type: action.payload.type,
        email: action.payload.email,
        groups: action.payload.groups,
        prosecutorOffices: action.payload.prosecutorOffices,
        institution: action.payload.institution,
        association: action.payload.association,
        termsOfUse: action.payload.termsOfUse,
        releasedVersions: action.payload.releasedVersions,
        advisorProsecutor: action.payload.advisorProsecutor,
        roles: handleAllRoles(action.payload.roles),
      };

    case actionTypes.SESSION_LOGOUT:
      localStorage.removeItem(OLD_URLS.user_and_prosecutor);
      localStorage.removeItem(TOKENS_AUTHENTICATION.user_and_prosecutor);
      return {
        ...initialState,
        loged: action.payload,
      };

    case actionTypes.SESSION_LOGOUT_INVESTIGATED:
      localStorage.removeItem(OLD_URLS.investigated);
      localStorage.removeItem(TOKENS_AUTHENTICATION.investigated);
      return { ...initialState };

    case actionTypes.SESSION_LOGOUT_PM:
      localStorage.removeItem(OLD_URLS.pm);
      localStorage.removeItem(TOKENS_AUTHENTICATION.pm);
      return { ...initialState };

    case actionTypes.SET_FULL_USER:
      return {
        ...state,
        loged: true,
        userId: action.payload.userId,
        name: action.payload.name,
        type: action.payload.type,
        token: action.payload.token,
        issuedAt: action.payload.issuedAt,
        expiresAt: action.payload.expiresAt,
        email: action.payload.email,
        groups: action.payload.groups,
        prosecutorOffices: action.payload.prosecutorOffices,
        permissions: action.payload.permissions,
        advisorProsecutor: action.payload.advisorProsecutor,
        roles: handleAllRoles(action.payload.roles),
      };

    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case actionTypes.SET_ISSUED_AT:
      return {
        ...state,
        issuedAt: action.payload,
      };

    case actionTypes.SET_EXPIRES_AT:
      return {
        ...state,
        expiresAt: action.payload,
      };

    case actionTypes.SET_PERMISSIONS_USER:
      return {
        ...state,
        permissions: action.payload,
      };

    case actionTypes.CLEAR_PERMISSIONS_USER:
      return {
        ...state,
        permissions: initialState.permissions,
      };

    case actionTypes.SET_HAS_MANUAL_INVESTIGATED_SYNC:
      return {
        ...state,
        hasManualInvestigatedSync: action.payload,
      };

    default:
      return state;
  }
};

export default logedUserReducer;
