export const SET_PAGINATION_REGISTER_PARAMETERS = 'SET_PAGINATION_REGISTER_PARAMETERS';
export const CLEAR_PAGINATION_REGISTER_PARAMETERS = 'CLEAR_PAGINATION_REGISTER_PARAMETERS';

export const setPaginationRegisterParameters = payload => ({
  type: SET_PAGINATION_REGISTER_PARAMETERS,
  payload,
});

export const clearPaginationRegisterParameters = payload => ({
  type: CLEAR_PAGINATION_REGISTER_PARAMETERS,
  payload,
});
