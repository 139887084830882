import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, TextareaAutosize, Typography, CircularProgress } from '@material-ui/core';
import { ButtonConsensus, InputConsensus, KeyboardDatePicker } from 'components';
import { toast } from 'react-toastify';
import associationService from 'services/association/associationService';
import agreementService from 'services/agreement/agreementService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { colors } from 'utils/colorsSteps';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';
import LazyAutocomplete from 'components/LazyAutocomplete';
import projectService from 'services/project/projectService';
import { AutoCompleteConfig } from 'constants/autocomplete';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SocioeducationalLectures = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    lectures,
    setLectures,
    institutionType,
    institutionSelected,
    judicialDistrictId,
  } = props;

  const classes = useStyles();

  const [row, setRow] = useState(null);

  const { isMPDFT } = useCustomerConfig();

  const initialValues = {
    link: '',
    description: '',
    expectedDate: '',
    penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
    type: '',
    project: null,
    association: null,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: selectedIndex !== null ? { ...lectures[selectedIndex.index] } : { ...initialValues },
    }));
  }, [selectedIndex]);

  const { enablePenaltyTypes } = useCustomerConfig();

  const handleChangeType = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        type: newValue,
      },
    }));
  };

  const fetchTypes = async () => {
    const result = await agreementService.getPenaltyTypes();
    return {
      data: result.data.lectureTypes,
    };
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  const fetchAssociations = async (search, page) => {
    const params = {
      forward: false,
      includeAssociationsWithNullSituation: true,
      judicialDistrictId: judicialDistrictId,
      name: search,
      page,
      size: AutoCompleteConfig.PAGINATION_SIZE,
    };

    if (isMPDFT) {
      delete params.includeAssociationsWithNullSituation;
      params.situation = PARTNER_SITUATIONS.ACCREDITED;
      delete params.judicialDistrictId;
    }

    const result = await associationService.getAssociationsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  const fetchProjects = async (search, page) => {
    const params = {
      associationId: formState.values.association?.id,
      onlyActiveProjects: true,
      name: search,
      page,
      size: AutoCompleteConfig.PAGINATION_SIZE,
    };

    const result = await projectService.getProjectsService(params);

    return {
      data: result.data.data,
      lastPage: result.data.lastPage,
    };
  };

  const handleChangeCompany = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        association: newValue,
        project: null,
      },
    }));
  };

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row },
      }));
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
      },
    }));
  }, [row, institutionSelected]);

  const handleSubmit = event => {
    event.preventDefault();

    const { description, expectedDate, type } = formState.values;

    if (description === '' || expectedDate === '' || (!type && enablePenaltyTypes)) {
      toast.warn('Todos os campos são obrigatórios com exceção do link para acesso');
      return;
    }

    if (selectedIndex !== null) {
      let newArray = [...lectures];
      newArray[selectedIndex.index] = {
        penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
        ...formState.values,
      };
      setLectures(newArray);
    } else {
      setLectures([
        ...lectures,
        {
          penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
          ...formState.values,
        },
      ]);
    }

    handleClearFormStat();
  };

  const handleToRead = () => {
    handleClearFormStat();
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {enablePenaltyTypes && (
              <LazyAutocomplete
                autoHighlight
                onChange={handleChangeType}
                value={formState.values.type}
                renderOption={option => <React.Fragment>{option || ''}</React.Fragment>}
                enablePagination
                fetchOptions={fetchTypes}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Tipo*"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {institutionType === 'final' && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <LazyAutocomplete
                  autoHighlight
                  onChange={handleChangeCompany}
                  value={formState.values.association}
                  getOptionLabel={option => option.name || ''}
                  renderOption={option => <React.Fragment>{option.name || ''}</React.Fragment>}
                  enablePagination
                  fetchOptions={fetchAssociations}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label="Instituição*"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {institutionType === 'final' && formState.values.association?.id && (
                  <LazyAutocomplete
                    value={formState.values.project}
                    getOptionLabel={option => option.name}
                    onChange={handleChangeProject}
                    renderOption={option => <>{option.name}</>}
                    fetchOptions={fetchProjects}
                    enablePagination
                    refreshKey={formState.values.association?.id}
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        name="project"
                        label="Projeto"
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.loading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="expectedDate"
              label="Data prevista para cumprimento *"
              value={formState.values.expectedDate || null}
              minDate={!selectedIndex ? new Date() : undefined}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    expectedDate: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputConsensus
              fullWidth
              label="Link para acesso"
              name="link"
              onChange={handleChange}
              value={formState.values.link || ''}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box style={{ marginTop: 5, marginBottom: 5 }}>
              <Typography variant="h5">Descrever detalhes:</Typography>
            </Box>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Descrever como será cumprido *"
              onChange={handleChange}
              name="description"
              value={formState.values.description || ''}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SocioeducationalLectures;
