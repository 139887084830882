import React, { useState, createContext, useContext } from 'react';

const FilterInquiryContext = createContext({});

export const FilterInquiryProvider = ({ children }) => {
  //filtro de inquéritos nas fases
  const [prosecutorOfficeIdContext, setProsecutorOfficeIdContext] = useState(null);
  const [prosecutorContext, setProsecutorContext] = useState(null);
  const [prosecutorOfficeGroupIdsContext, setProsecutorOfficeGroupIdsContext] = useState(null);
  const [inquiryStepContext, setInquiryStepContext] = useState(null);
  const [policeInquiryNumberContext, setPoliceInquiryNumberContext] = useState(null);
  const [internalSystemNumberContext, setInternalSystemNumberContext] = useState(null);
  const [arquimedesAutoContext, setArquimedesAutoContext] = useState(null);
  const [investigatedNameContext, setInvestigatedNameContext] = useState(null);
  const [investigatedCpfContext, setInvestigatedCpfContext] = useState(null);
  const [victimNameContext, setVictimNameContext] = useState(null);
  const [victimCpfContext, setVictimCpfContext] = useState(null);
  const [startDateContext, setStartDateContext] = useState(null);
  const [endDateContext, setEndDateContext] = useState(null);
  const [crimeNomeContext, setCrimeNomeContext] = useState(null);
  const [crimeIdContext, setCrimeIdContext] = useState(null);
  const [crimeContext, setCrimeContext] = useState(null);
  const [lawContext, setLawContext] = useState(null);
  const [excludePersecutionContext, setExcludePersecutionContext] = useState(true);
  const [excludeNonPersecutionContext, setExcludeNonPersecutionContext] = useState(true);
  const [inquiryStatusContext, setInquiryStatusContext] = useState(null);
  const [onlyConcludedIn24hContext, setOnlyConcludedIn24hContext] = useState(null);
  const [isArrestedContext, setIsArrestedContext] = useState(null);
  const [awaitingInstitutionResponseContext, setAwaitingInstitutionResponseContext] = useState(
    null,
  );
  const [isFlagrantContext, setIsFlagrantContext] = useState(null);
  const [isArchivalScienceContext, setIsArchivalScienceContext] = useState(null);
  const [intimationTypeContext, setIntimationTypeContext] = useState(null);
  const [priorityContext, setPriorityContext] = useState(null);
  const [hasPendingInquiryArrivedContext, setHasPendingInquiryArrivedContext] = useState(null);
  const [returnedFromPmContext, setReturnedFromPmContext] = useState(null);
  const [responsibleUserContext, setResponsibleUserContext] = useState(null);
  const [hasDiligenceResponseContext, setHasDiligenceResponseContext] = useState(null);
  const [hasActivatedTaskContext, setHasActivatedTaskContext] = useState(null);
  const [classInquiryContext, setClassInquiryContext] = useState(null);
  const [finalInstitutionContext, setFinalInstitutionContext] = useState(null);
  const [forwarderInstitutionContext, setForwarderInstitutionContext] = useState(null);
  const [hasPendenciesInAgreement, setHasPendenciesInAgreement] = useState(null);
  const [agreementStatus, setAgreementStatus] = useState(null);
  const [npuContext, setNpuContext] = useState(null);
  const [tasksDeadline, setTasksDeadline] = useState(null);
  const [victimIntimationDeadline, setVictimIntimationDeadline] = useState(null);
  const [requestFromAiContext, setIsRequesFromAiContext] = useState(null);

  //filtro de inquéritos pendentes
  const [policeInquiryPendingNumberContext, setPoliceInquiryPendingNumberContext] = useState(null);
  const [startDatePendingContext, setStartDatePendingContext] = useState(null);
  const [endDatePendingContext, setEndDatePendingContext] = useState(null);
  const [isArrestedPendingContext, setIsArrestedPendingContext] = useState(null);
  const [returnedPendingContext, setReturnedPendingContext] = useState(null);
  const [judicialDistrictPendingContext, setJudicialDistrictPendingContext] = useState(null);
  const [classInquiryPendingContext, setClassInquiryPendingContext] = useState(null);
  const [sourceOrganPendingContext, setSourceOrganPendingContext] = useState(null);

  const [pageContext, setPageContext] = useState(1);
  const [pageLinkingContext, setPageLinkingContext] = useState(1);

  const clearStatesFilterInquiryContext = () => {
    setProsecutorContext(null);
    setProsecutorOfficeGroupIdsContext(null);
    setInquiryStepContext(null);
    setPoliceInquiryNumberContext(null);
    setInternalSystemNumberContext(null);
    setArquimedesAutoContext(null);
    setInvestigatedNameContext(null);
    setInvestigatedCpfContext(null);
    setVictimNameContext(null);
    setVictimCpfContext(null);
    setStartDateContext(null);
    setEndDateContext(null);
    setCrimeNomeContext(null);
    setCrimeIdContext(null);
    setInquiryStatusContext(null);
    setOnlyConcludedIn24hContext(null);
    setIsArrestedContext(null);
    setAwaitingInstitutionResponseContext(null);
    setIsFlagrantContext(null);
    setPriorityContext(null);
    setHasPendingInquiryArrivedContext(null);
    setCrimeContext(null);
    setLawContext(null);
    setResponsibleUserContext(null);
    setHasDiligenceResponseContext(null);
    setHasActivatedTaskContext(null);
    setClassInquiryContext(null);
    setProsecutorOfficeIdContext(null);
    setFinalInstitutionContext(null);
    setForwarderInstitutionContext(null);
    setHasPendenciesInAgreement(null);
    setAgreementStatus(null);
    setNpuContext(null);
    setTasksDeadline(null);
    setVictimIntimationDeadline(null);
    setIsArchivalScienceContext(null);
    setIntimationTypeContext(null);
    setIsRequesFromAiContext(null);

    setPoliceInquiryPendingNumberContext(null);
    setStartDatePendingContext(null);
    setEndDatePendingContext(null);
    setIsArrestedPendingContext(null);
    setReturnedPendingContext(null);
    setJudicialDistrictPendingContext(null);
    setClassInquiryPendingContext(null);
    setSourceOrganPendingContext(null);

    setPageContext(1);
    setPageLinkingContext(1);
  };

  return (
    <FilterInquiryContext.Provider
      value={{
        clearStatesFilterInquiryContext,
        prosecutorOfficeIdContext,
        setProsecutorOfficeIdContext,
        prosecutorOfficeGroupIdsContext,
        setProsecutorOfficeGroupIdsContext,
        prosecutorContext,
        setProsecutorContext,
        inquiryStepContext,
        setInquiryStepContext,
        policeInquiryNumberContext,
        setPoliceInquiryNumberContext,
        internalSystemNumberContext,
        setInternalSystemNumberContext,
        arquimedesAutoContext,
        setArquimedesAutoContext,
        investigatedNameContext,
        setInvestigatedNameContext,
        investigatedCpfContext,
        setInvestigatedCpfContext,
        victimNameContext,
        setVictimNameContext,
        victimCpfContext,
        setVictimCpfContext,
        startDateContext,
        setStartDateContext,
        endDateContext,
        setEndDateContext,
        crimeNomeContext,
        setCrimeNomeContext,
        crimeIdContext,
        setCrimeIdContext,
        excludePersecutionContext,
        setExcludePersecutionContext,
        excludeNonPersecutionContext,
        setExcludeNonPersecutionContext,
        inquiryStatusContext,
        setInquiryStatusContext,
        onlyConcludedIn24hContext,
        setOnlyConcludedIn24hContext,
        isArrestedContext,
        setIsArrestedContext,
        policeInquiryPendingNumberContext,
        setPoliceInquiryPendingNumberContext,
        startDatePendingContext,
        setStartDatePendingContext,
        endDatePendingContext,
        setEndDatePendingContext,
        isArrestedPendingContext,
        setIsArrestedPendingContext,
        returnedPendingContext,
        setReturnedPendingContext,
        judicialDistrictPendingContext,
        setJudicialDistrictPendingContext,
        pageContext,
        setPageContext,
        awaitingInstitutionResponseContext,
        setAwaitingInstitutionResponseContext,
        classInquiryPendingContext,
        setClassInquiryPendingContext,
        sourceOrganPendingContext,
        setSourceOrganPendingContext,
        isFlagrantContext,
        setIsFlagrantContext,
        priorityContext,
        setPriorityContext,
        hasPendingInquiryArrivedContext,
        setHasPendingInquiryArrivedContext,
        returnedFromPmContext,
        setReturnedFromPmContext,
        crimeContext,
        setCrimeContext,
        lawContext,
        setLawContext,
        responsibleUserContext,
        setResponsibleUserContext,
        pageLinkingContext,
        setPageLinkingContext,
        hasDiligenceResponseContext,
        setHasDiligenceResponseContext,
        hasActivatedTaskContext,
        setHasActivatedTaskContext,
        classInquiryContext,
        setClassInquiryContext,
        finalInstitutionContext,
        setFinalInstitutionContext,
        forwarderInstitutionContext,
        setForwarderInstitutionContext,
        hasPendenciesInAgreement,
        setHasPendenciesInAgreement,
        agreementStatus,
        setAgreementStatus,
        npuContext,
        setNpuContext,
        tasksDeadline,
        setTasksDeadline,
        victimIntimationDeadline,
        setVictimIntimationDeadline,
        isArchivalScienceContext,
        setIsArchivalScienceContext,
        intimationTypeContext,
        setIntimationTypeContext,
        requestFromAiContext,
        setIsRequesFromAiContext
      }}
    >
      {children}
    </FilterInquiryContext.Provider>
  );
};

export const useFilterInquiry = () => {
  const context = useContext(FilterInquiryContext);
  return context;
};
