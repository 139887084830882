/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  IconButton,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CachedIcon from '@material-ui/icons/Cached';
import {
  listClassesAtosInfracionais,
  listEstadoCivil,
  listOrgaoEmissorRg,
} from 'utils/constsFormInvestigateds';
import { listUf } from 'utils/citysPE';
import { ModalConfirm } from './components';
import {
  InputConsensus,
  ButtonConsensus,
  AlternativePhones,
  ComboBoxMultiSelect,
} from 'components';
import { Autocomplete } from '@material-ui/lab';
import { ModalInvestigatedsArquimedes } from './components';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import { formatedTaxpayerRegister } from 'utils/genericFunctions/formatedTazpayerRegisterNumber';
import debounce from 'lodash.debounce';
import personService from 'services/person/personService';
import organService from 'services/organ/organService';
import judicialDistrictsService from 'services/judicial-district/judicialDistrictsService';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { clearSpecialCharacterName } from 'utils/genericFunctions/clearSpecialCharacterName';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
  },
  containerLabelInputCrime: {
    padding: 5,
  },
  containerLabelInput: {
    padding: 5,
  },
  selectForm: {
    height: 25,
    outline: 0,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: '#ededed',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  form: {
    heigth: '100%',
    width: '100%',
    padding: '0 10px 0 10px',
  },
  labelForm: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
  },
  labelFormCrime: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
    color: '#00597b',
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  inputFormCrime: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
  inputFormCrimeEdit: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    borderBottom: '1px solid #000',
  },
  inputMaterial: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  containerForm: {
    //padding: 15
  },
}));

const InvestigatedsPending = props => {
  const { peoples = [], setPeoples, write } = props;

  const classes = useStyles();

  const initialValues = {
    id: null,
    isArrested: false,
    crimes: [],
    investigatedIds: [],
    rg: {
      code: '',
      issuingBody: '',
      issueDate: '',
      uf: '',
    },
    address: {
      publicPlace: '',
      address: '',
      neighborhood: '',
      city: '',
      number: '',
      zipCode: '',
      block: '',
      apartment: '',
      house: '',
      court: '',
      lot: '',
      complement: '',
      uf: '',
      latitude: '',
      longitude: '',
      full: '',
      street: '',
    },
    placeOfBirth: {
      country: '',
      city: '',
      uf: '',
    },
    email: '',
    motherName: '',
    birthDate: '',
    phone: '',
    nationality: '',
    maritalStatus: '',
    taxpayerRegistry: '',
    peopleType: 'investigated',
    npu: '',
    judgmentPlace: '',
    //crimeNome: '',
    name: '',
    type: 'PF',
    phones: [],
  };

  const classesInquiryReducer = useSelector(state => state.classesInquiry);

  const [formState, setFormState] = useState({
    isValid: false,
    investigated: [],
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [showForm, setShowForm] = useState(false);
  const [selectedInvestigated, setSelectedInvestigated] = useState(null);
  const [editInvestigatedInArray, setEditInvestigatedInArray] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [showFormInvestigated, setShowFormInvestigated] = useState(false);
  const [disabledBtnCep, setDisabledBtnCep] = useState(false);
  const [disabledBtnSds, setDisabledBtnSds] = useState(false);
  const [disabledBtnArquimedes, setDisabledBtnArquimedes] = useState(false);
  const [investigateds, setInvestigateds] = useState([]);
  const [investigated, setInvestigated] = useState(null);
  const [openModalInvestigateds, setOpenModalInvestigateds] = useState(false);

  const [loadingDelegacias, setLoadingDelegacias] = useState(false);
  const [inputSearchDelegacias, setInputSearchDelegacias] = useState('');
  const [pageDelegacias, setPageDelegacias] = useState(1);
  const [lastPageDelegacias, setLastPageDelegacias] = useState(1);
  const [options, setOptions] = useState([]);

  const [showInputPhones, setShowInputPhones] = useState(false);
  const [phones, setPhones] = useState([]);

  const [cities, setCities] = useState([]);
  const [labelCities, setLabelCities] = useState('Cidade');
  const [cityObject, setCityObject] = useState(null);

  const getCities = () => {
    let params = {
      uf: formState.values.address.uf,
    };
    judicialDistrictsService.getJudicialDistrictsService(params).then(response => {
      setCities(response.data.data);
      if (formState?.values?.address?.city) {
        setCityObject(
          response.data.data.find(
            comarca =>
              comarca?.name?.toLowerCase() === formState?.values?.address?.city.toLowerCase(),
          ) || null,
        );
      }
      if (response.data.data.length < 1) {
        setLabelCities('Cidades não disponíveis');
      } else {
        setLabelCities('Cidade ');
      }
    });
  };

  const checkIfInvestigatedAlreadyHasALegalRepresentative = investigatedId => {
    return _.some(
      peoples.filter(people => people.peopleType === 'legalRepresentative'),
      people => {
        return _.includes(people.investigatedIds, investigatedId);
      },
    );
  };

  const handleChangeJudicialDistrict = (event, newValue) => {
    setCityObject(newValue);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleAddInvestigated = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setShowForm(true);
    setEditInvestigatedInArray(false);
    setShowInputPhones(true);
    setPhones([]);
  };

  const handleChangeStateEditToFalse = () => {
    setShowForm(false);
    setEditInvestigatedInArray(false);
  };

  const handleUpdateInvestigated = (investigated, index) => {
    setSelectedIndex(index);
    setEditInvestigatedInArray(true);
    setSelectedInvestigated(investigated);
    setShowForm(true);
    setFormState(formState => ({
      ...formState,
      values: {
        id: investigated.id,
        isArrested: investigated.isArrested,
        crimes: investigated.crimes || [],
        investigatedIds: investigated.investigatedIds || [],
        rg: {
          code: investigated?.rg?.code || '',
          issuingBody: investigated?.rg?.issuingBody || '',
          issueDate: investigated?.rg?.issueDate || '',
          uf: investigated.rg.uf || '',
        },
        address: {
          publicPlace: '',
          address: '',
          neighborhood: investigated?.address?.neighborhood || '',
          city: investigated?.address?.city || '',
          number: investigated?.address?.number || '',
          zipCode: investigated?.address?.zipCode || '',
          block: '',
          apartment: '',
          house: '',
          court: '',
          lot: '',
          complement: investigated?.address?.complement || '',
          uf: investigated?.address?.uf || '',
          latitude: '',
          longitude: '',
          full: '',
          street: investigated?.address?.street || '',
        },
        placeOfBirth: {
          country: investigated?.placeOfBirth?.country || '',
          city: investigated?.placeOfBirth?.city || '',
          uf: investigated?.placeOfBirth?.uf || '',
        },
        judgmentPlace: investigated?.judgmentPlace || '',
        npu: investigated?.npu || '',
        email: investigated?.email || '',
        motherName: investigated?.motherName || '',
        birthDate: investigated?.birthDate || '',
        phone: investigated?.phone || '',
        phones: investigated?.phones || [],
        nationality: investigated?.nationality || '',
        maritalStatus: investigated?.maritalStatus || '',
        taxpayerRegistry: investigated?.taxpayerRegistry || '',
        name: investigated?.name || '',
        type: investigated?.type || 'PF',
        peopleType: investigated?.peopleType,
      },
    }));
    if (investigated.phones) {
      setPhones(investigated.phones);
    } else {
      setPhones([]);
    }
    setShowInputPhones(false);
  };

  const handleChangeName = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          clearSpecialCharacterName(event.target.value)?.toUpperCase() ?? event.target.value,
      },
    }));
  };

  const handleChangeMaritalStatus = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeComboBoxMultiSelect = newValue => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        investigatedIds: newValue,
      },
    }));
  };

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        peopleType: 'investigated',
      },
    }));
  }, [classesInquiryReducer]);

  const checkIfTheClassIsPartOfTheInfractionsGroup = () => {
    return isInfraction(classesInquiryReducer.code);
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value &&
              typeof event.target.value !== 'boolean' &&
              event.target.value !== 'investigated' &&
              event.target.value !== 'victim' &&
              event.target.value !== 'legalRepresentative'
            ? event.target.value.toUpperCase()
            : event.target.value,
      },
    }));
  };

  const handleChangeAddress = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        address: {
          ...formState.values.address,
          [event.target.name]: event.target.value
            ? event.target.value.toUpperCase()
            : event.target.value,
        },
      },
    }));
  };

  const handleChangeRg = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        rg: {
          ...formState.values.rg,
          [event.target.name]: event.target.value,
        },
      },
    }));
  };

  const handleChangeplaceOfBirth = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        placeOfBirth: {
          ...formState.values.placeOfBirth,
          [event.target.name]: event.target.value
            ? event.target.value.toUpperCase()
            : event.target.value,
        },
      },
    }));
  };

  const requestSds = () => {
    let params = {
      rg: formState.values?.rg?.code,
      uf: formState.values?.rg?.uf,
    };

    if (!params.rg || !params.uf) {
      toast.warn('Informe o número e uf do RG!');
      return;
    }

    if (params.uf !== 'PE') {
      toast.warn(`No momento não disponibilizamos buscas à SDS do UF ${params.uf}.`);
      return;
    }

    setDisabledBtnSds(true);

    personService
      .getSdsDataService(params)
      .then(response => {
        if (selectedInvestigated) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              name: response.data?.name || selectedInvestigated.name,
              taxpayerRegistry:
                response.data?.taxpayerRegistry || selectedInvestigated.taxpayerRegistry,
              motherName: response.data?.motherName || selectedInvestigated.motherName,
              birthDate: response.data?.birthDate || selectedInvestigated.birthDate,
              phone: response.data?.phone || selectedInvestigated.phone,
              maritalStatus: response.data?.maritalStatus || selectedInvestigated.maritalStatus,
              nationality: response.data?.nationality || selectedInvestigated.nationality,
              rg: {
                ...formState.values.rg,
                issueDate: response.data?.rg?.issueDate || selectedInvestigated.rg?.issueDate,
                uf: response.data?.rg?.uf || selectedInvestigated.rg?.uf,
                code: response.data?.rg?.code || selectedInvestigated.rg?.code,
                issuingBody: response.data?.rg?.issuingBody || selectedInvestigated.rg?.issuingBody,
              },
              placeOfBirth: {
                ...formState.values.placeOfBirth,
                city: response.data?.placeOfBirth?.city || selectedInvestigated.placeOfBirth?.city,
                uf: response.data?.placeOfBirth?.uf || selectedInvestigated.placeOfBirth?.uf,
                country:
                  response.data?.placeOfBirth?.country ||
                  selectedInvestigated.placeOfBirth?.country,
              },
            },
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              name: response.data?.name || '',
              taxpayerRegistry: response.data?.taxpayerRegistry || '',
              motherName: response.data?.motherName || '',
              birthDate: response.data?.birthDate || '',
              phone: response.data?.phone || '',
              maritalStatus: response.data?.maritalStatus || '',
              nationality: response.data?.nationality || '',
              rg: {
                ...formState.values.rg,
                issueDate: response.data?.rg?.issueDate || '',
                issuingBody: response.data?.rg?.issuingBody || '',
                uf: response.data?.rg?.uf || '',
              },
              placeOfBirth: {
                ...formState.values.placeOfBirth,
                city: response.data?.placeOfBirth?.city || '',
                uf: response.data?.placeOfBirth?.uf || '',
              },
              // address: {
              //     ...formState.values.address,
              //     street: typeof response.data.address !== 'undefined' ? response.data.address.full : ''
              // },
            },
          }));
        }

        toast.success('Dados recebidos com sucesso!');
      })
      .finally(() => {
        setDisabledBtnSds(false);
      });
  };

  const requestZipCode = () => {
    const { zipCode } = formState.values.address;

    if (zipCode === '') {
      toast.warn('Informe um CEP');
      return;
    }

    setDisabledBtnCep(true);

    personService
      .getZipCodeService(zipCode)
      .then(response => {
        const res = response.data;
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            address: {
              ...formState.values.address,
              city: res.city !== '' ? res.city : formState.values.address?.city || '',
              neighborhood:
                res.neighborhood !== ''
                  ? res.neighborhood.toUpperCase()
                  : formState.values.address?.neighborhood,
              street:
                res.street !== '' ? res.street.toUpperCase() : formState.values.address?.street,
              uf: res.uf !== '' ? res.uf : formState.values.address?.uf,
              //complement: res.complement !== "" ? res.complement : formState.values.address?.complement
            },
          },
        }));
        setCityObject(
          cities.find(comarca => comarca.name.toLowerCase() === res.city.toLowerCase()) || null,
        );
      })
      .finally(() => {
        setDisabledBtnCep(false);
      });
  };

  const requestArquimedes = () => {
    const { name } = formState.values;

    if (!name || name === '') {
      toast.warn('Informe o nome do investigado');
      return;
    }

    setDisabledBtnArquimedes(true);

    let params = { name };

    personService
      .getPersonInArquimedesService(params)
      .then(response => {
        setInvestigateds(response.data);
        setOpenModalInvestigateds(true);
      })
      .finally(() => {
        setDisabledBtnArquimedes(false);
      });
  };

  const getDelegacias = (input = '') => {
    setLoadingDelegacias(true);

    setPageDelegacias(1);

    let params = {
      page: 1,
      size: 20,
      search: input,
      category: 'JUDICIARY',
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions(response.data.data);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const handleChangeJudgmentPlace = (event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        judgmentPlace: newValue ? newValue : null,
      },
    }));
  };

  const handleDeleteInvestigated = (event, investigated, indexInvestigated) => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    if (investigated.peopleType === 'investigated') {
      if (checkIfInvestigatedAlreadyHasALegalRepresentative(investigated.id)) {
        toast.warn('Investigado está associado a um representante legal');
        return;
      }
    }
    setSelectedIndex(indexInvestigated);
    setSelectedInvestigated(investigated);
    setOpenModalConfirm(true);
  };

  const deleteVictim = () => {
    setPeoples(peoples.filter((value, index) => index !== selectedIndex));
    toast.success('Investigado removido com sucesso');
    setOpenModalConfirm(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }

    const { name, rg, taxpayerRegistry, peopleType, type, investigatedIds, id } = formState.values;

    if (taxpayerRegistry.length) {
      if (type === 'PF' && clearInquiryNumber(taxpayerRegistry).length !== 11) {
        toast.warn('CPF precisa ter 11 caracteres');
        return;
      }

      if (type === 'PJ' && clearInquiryNumber(taxpayerRegistry).length !== 14) {
        toast.warn('CNPJ precisa ter 14 caracteres');
        return;
      }
    }

    if (name === '') {
      toast.warn('O campo Nome é obrigatório');
      return;
    }

    if (peopleType === '') {
      toast.warn('O campo Tipo é obrigatório');
      return;
    }

    if (peopleType === 'legalRepresentative') {
      if (!investigatedIds.length) {
        toast.warn('Selecione ao menos um investigado.');
        return;
      }
    }

    let indexRg = -1;
    if (rg?.code) {
      indexRg = peoples.findIndex(
        item => item.peopleType === peopleType && item?.rg?.code === rg?.code,
      );
    }

    let indexRegistry = -1;
    if (taxpayerRegistry !== '') {
      indexRegistry = peoples.findIndex(
        item =>
          item.peopleType === peopleType &&
          item.taxpayerRegistry === clearInquiryNumber(taxpayerRegistry),
      );
    }

    let existRg = indexRg !== -1 && indexRg !== selectedIndex;
    let existRegistry = indexRegistry !== -1 && indexRegistry !== selectedIndex;

    if (existRg || existRegistry) {
      toast.warn(
        `Pessoa com o RG ${rg?.code || '-'} ou CPF/CNPJ ${taxpayerRegistry || '-'} já adicionada.`,
      );
      return;
    }

    if (!id) {
      formState.values.id = uuid();
    }

    updatePhones();
    formState.values.phones = phones;

    if (formState.values.taxpayerRegistry) {
      const taxpayerRegistryOnlyNumbers = clearInquiryNumber(formState.values.taxpayerRegistry);
      formState.values.taxpayerRegistry = taxpayerRegistryOnlyNumbers;
    }

    if (editInvestigatedInArray) {
      let oldArray = [...peoples];
      oldArray[selectedIndex] = formState.values;
      setPeoples(oldArray);
      toast.success('Pessoa alterada com sucesso');
    } else {
      setPeoples(people => [...people, formState.values]);
      toast.success('Pessoa inserida com sucesso');
    }

    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setShowForm(false);
    setSelectedIndex(null);
    setEditInvestigatedInArray(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetDelegacias = useCallback(
    debounce(newValue => getDelegacias(newValue), 500),
    [],
  );

  const loadMoreDelegaciasResults = () => {
    const nextPage = pageDelegacias + 1;

    setPageDelegacias(nextPage);

    setLoadingDelegacias(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchDelegacias,
      category: 'JUDICIARY',
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions([...options, ...response.data.data]);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const handleScrollDelegacias = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageDelegacias < lastPageDelegacias &&
      !loadingDelegacias
    ) {
      loadMoreDelegaciasResults();
    }
  };

  const updateValueDelegacias = newValue => {
    if (!options.find(delegacia => delegacia.name === newValue)) {
      debouncedGetDelegacias(newValue);
      setInputSearchDelegacias(newValue);
    }
  };

  const handleChangeShowInputPhonesToTrue = () => {
    setShowInputPhones(true);
  };

  const handleChangeShowInputPhonesToFalse = () => {
    setShowInputPhones(false);
  };

  const updatePhones = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        phones: phones,
      },
    }));
    setPhones(phones);
    setShowInputPhones(false);
  };

  const handleAddPhone = () => {
    const newPhone = formState.values.phones;
    if (!newPhone) {
      toast.warn('Insira um telefone alternativo');
      return;
    }
    if (newPhone.length > 0) {
      const index = phones.findIndex(item => item === newPhone);
      if (index === -1) {
        setPhones(phones => [...phones, newPhone]);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            phones: [],
          },
        }));
      } else {
        toast.warn(`Telefone ${newPhone} já adicionado`);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            phones: [],
          },
        }));
        return;
      }
    }
  };

  const handleRemovePhone = phone => {
    const index = phones.findIndex(item => item === phone);
    if (index !== -1) {
      setPhones(phones.filter((value, _index, _arr) => value !== phone));
    }
  };

  useEffect(() => {
    if (!investigated) return;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        name: investigated?.name?.toUpperCase() || '',
        motherName: investigated?.motherName?.toUpperCase() || '',
        birthDate: investigated?.birthDate || '',
      },
    }));
  }, [investigated]);

  useEffect(() => {
    formState.values.peopleType === 'investigated'
      ? setShowFormInvestigated(true)
      : setShowFormInvestigated(false);
  }, [formState.values.peopleType]);

  useEffect(() => {
    if (showForm) {
      debouncedGetDelegacias(inputSearchDelegacias);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  useEffect(() => {
    if (formState.values.address.uf !== '') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          address: {
            ...formState.values.address,
            city: cityObject ? cityObject.name.toUpperCase() : '',
          },
        },
      }));
    }
  }, [cityObject]);

  useEffect(() => {
    if (formState.values.address.uf !== '') {
      getCities();
      setCityObject(null);
    }
  }, [formState.values.address.uf]);

  return (
    <Box>
      <Box display="flex" alignItems="center" pl={1.5}>
        <Typography className={classes.titulo}>Dados Pessoais</Typography>
      </Box>

      <Grid container spacing={2} className={classes.containerForm}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {showForm ? (
              <Tooltip title="Fechar formulário" arrow placement="top">
                <IconButton
                  color="primary"
                  onClick={handleChangeStateEditToFalse}
                  style={{ marginRight: 10 }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Adicionar investigado" arrow placement="top">
                <IconButton
                  color="primary"
                  onClick={handleAddInvestigated}
                  style={{ marginRight: 10 }}
                >
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
        {showForm && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <form className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        select
                        label="Tipo de pessoa"
                        fullWidth
                        name="peopleType"
                        onChange={handleChange}
                        value={formState.values.peopleType || ''}
                        className={classes.inputMaterial}
                      >
                        <MenuItem value="investigated">Investigado</MenuItem>
                        <MenuItem value="victim">Vítima</MenuItem>
                        {checkIfTheClassIsPartOfTheInfractionsGroup() && (
                          <MenuItem value="legalRepresentative">Representante Legal</MenuItem>
                        )}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        select
                        label="Pessoa Física/Jurídica"
                        fullWidth
                        name="type"
                        onChange={handleChange}
                        value={formState.values.type || ''}
                        className={classes.inputMaterial}
                      >
                        <MenuItem value="PF">Pessoa Física</MenuItem>
                        <MenuItem value="PJ">Pessoa Jurídica</MenuItem>
                      </InputConsensus>
                    </div>
                  </Grid>
                  {formState.values.peopleType === 'legalRepresentative' && (
                    <Grid style={{ marginTop: 5 }} item xs>
                      <ComboBoxMultiSelect
                        options={peoples?.filter(
                          people =>
                            people.peopleType === 'investigated' &&
                            !checkIfInvestigatedAlreadyHasALegalRepresentative(people.id),
                        )}
                        label="Selecione os investigados *"
                        selectedIds={formState.values.investigatedIds}
                        onChange={handleChangeComboBoxMultiSelect}
                      />
                    </Grid>
                  )}
                  {showFormInvestigated && (
                    <Grid item xs>
                      <div className={classes.containerLabelInput}>
                        <FormControlLabel
                          label="O investigado encontra-se preso?"
                          control={
                            <Checkbox
                              checked={formState.values.isArrested}
                              onChange={handleChange}
                              name="isArrested"
                              color="primary"
                            />
                          }
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
                    <div className={classes.containerLabelInput}>
                      <InputMask
                        onChange={handleChangeRg}
                        value={formState.values.rg.code || ''}
                        mask="9999999999999"
                        maskChar={null}
                      >
                        {inputProps => (
                          <InputConsensus
                            {...inputProps}
                            label="RG *"
                            name="code"
                            variant="standard"
                            type="text"
                            autoComplete="no"
                            className={classes.inputMaterial}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                    {disabledBtnSds ? (
                      <Tooltip placement="top" arrow title="Buscando...">
                        <IconButton color="primary">
                          <CircularProgress size={20} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Consultar SDS pelo RG">
                        <IconButton color="primary" aria-label="cachedIcon" onClick={requestSds}>
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={5} sm={3} md={2} lg={3} xl={3}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        label="Orgão Emissor"
                        select
                        name="issuingBody"
                        onChange={handleChangeRg}
                        value={formState.values.rg.issuingBody || ''}
                        className={classes.inputMaterial}
                      >
                        {listOrgaoEmissorRg().map((v, i) => (
                          <MenuItem key={i} value={v.value}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={3} md={4} lg={3} xl={2}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        id="custom-css-standard-input-emissao"
                        label="Data Emissão"
                        InputLabelProps={{ shrink: true }}
                        value={formState.values.rg.issueDate || ''}
                        onChange={handleChangeRg}
                        name="issueDate"
                        type="date"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div className={classes.containerLabelInput} class="notranslate">
                      <InputConsensus
                        className={classes.inputMaterial}
                        id="standard-select-currency-uf-ufgr"
                        select
                        label="UF RG"
                        name="uf"
                        value={formState.values.rg.uf || ''}
                        onChange={handleChangeRg}
                      >
                        {listUf().map((v, i) => (
                          <MenuItem key={i} value={v.value}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={10}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        label="Nome Completo sem abreviações *"
                        name="name"
                        autoComplete="no"
                        onChange={handleChangeName}
                        value={formState.values.name || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    {disabledBtnArquimedes ? (
                      <Tooltip placement="top" arrow title="Buscando...">
                        <IconButton color="primary">
                          <CircularProgress size={20} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="top" arrow title="Buscar no Arquimedes">
                        <IconButton color="primary" onClick={requestArquimedes}>
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        label="Nome da Mãe"
                        name="motherName"
                        autoComplete="no"
                        onChange={handleChangeName}
                        value={formState.values.motherName || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={10} sm={5} md={2} lg={2} xl={2}>
                    <div className={classes.containerLabelInput}>
                      <InputMask
                        onChange={handleChangeAddress}
                        value={formState.values.address.zipCode || ''}
                        mask="99999-999"
                        maskChar={null}
                      >
                        {inputProps => (
                          <InputConsensus
                            {...inputProps}
                            className={classes.inputMaterial}
                            label="CEP"
                            name="zipCode"
                          />
                        )}
                      </InputMask>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                    {disabledBtnCep ? (
                      <Tooltip placement="top" arrow title="Buscando...">
                        <IconButton color="primary">
                          <CircularProgress size={20} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Preencher endereço">
                        <IconButton
                          color="primary"
                          aria-label="cachedIcon"
                          onClick={requestZipCode}
                        >
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        label="Endereço"
                        name="street"
                        autoComplete="no"
                        onChange={handleChangeAddress}
                        value={formState.values.address.street || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        label="Número"
                        className={classes.inputMaterial}
                        type="number"
                        name="number"
                        autoComplete="no"
                        onChange={handleChangeAddress}
                        value={formState.values.address.number || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={5} lg={5} xl={5}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        name="complement"
                        label="Complemento"
                        autoComplete="no"
                        onChange={handleChangeAddress}
                        value={formState.values.address.complement || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={9} sm={4} md={5} lg={5} xl={5}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        label="Bairro"
                        name="neighborhood"
                        autoComplete="no"
                        onChange={handleChangeAddress}
                        value={formState.values.address.neighborhood || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        id="standard-select-currency-uf-address"
                        select
                        label="UF"
                        name="uf"
                        value={formState.values.address.uf || ''}
                        onChange={handleChangeAddress}
                      >
                        {listUf().map((v, i) => (
                          <MenuItem key={i} value={v.value}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.containerLabelInput}>
                      <Autocomplete
                        className={classes.inputMaterial}
                        options={cities}
                        fullWidth
                        autoHighlight
                        onChange={handleChangeJudicialDistrict}
                        clearOnBlur={false}
                        noOptionsText="Não encontrado"
                        value={cityObject || null}
                        getOptionLabel={option => option.name || ''}
                        renderOption={option => <>{option.name}</>}
                        renderInput={params => (
                          <InputConsensus
                            {...params}
                            id="standard-select-currency-uf-address-city"
                            className={classes.inputMaterial}
                            name="city"
                            label={labelCities}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        label="Naturalidade"
                        className={classes.inputMaterial}
                        name="city"
                        autoComplete="no"
                        onChange={handleChangeplaceOfBirth}
                        value={formState.values.placeOfBirth.city || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        id="standard-select-currency-uf-placeOfBirth"
                        select
                        label="UF Naturalidade"
                        name="uf"
                        value={formState.values.placeOfBirth.uf || ''}
                        onChange={handleChangeplaceOfBirth}
                      >
                        {listUf().map((v, i) => (
                          <MenuItem key={i} value={v.value}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        id="custom-css-standard-input-date-birth"
                        label="Nascimento"
                        InputLabelProps={{ shrink: true }}
                        value={formState.values.birthDate || ''}
                        onChange={handleChange}
                        name="birthDate"
                        type="date"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        className={classes.inputMaterial}
                        label="Nacionalidade"
                        name="nationality"
                        onChange={handleChange}
                        value={formState.values.nationality || ''}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        select
                        className={classes.inputMaterial}
                        label="Estado Civil"
                        name="maritalStatus"
                        onChange={handleChangeMaritalStatus}
                        value={formState.values.maritalStatus || ''}
                      >
                        {listEstadoCivil().map((v, i) => (
                          <MenuItem value={v.value} key={i}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </InputConsensus>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.containerLabelInput}>
                      <InputMask
                        onChange={handleChange}
                        value={formState.values.taxpayerRegistry || ''}
                        mask={
                          formState.values.type === 'PJ' ? '99.999.999/9999-99' : '999.999.999-99'
                        }
                        maskChar={null}
                      >
                        {inputProps => (
                          <InputConsensus
                            {...inputProps}
                            label={formState.values.type === 'PJ' ? 'CNPJ' : 'CPF'}
                            name="taxpayerRegistry"
                            variant="standard"
                            autoComplete="no"
                            className={classes.inputMaterial}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className={classes.containerLabelInput}>
                      <InputMask
                        onChange={handleChange}
                        value={formState.values.phone || ''}
                        mask="(99) 99999-9999"
                        maskChar={null}
                      >
                        {inputProps => (
                          <InputConsensus
                            {...inputProps}
                            label="Telefone/Whatsapp"
                            name="phone"
                            type="phone"
                            variant="standard"
                            autoComplete="no"
                            className={classes.inputMaterial}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <AlternativePhones
                      phones={phones}
                      editInvestigatedInArray={editInvestigatedInArray}
                      handleChangeShowInputPhonesToFalse={handleChangeShowInputPhonesToFalse}
                      handleRemovePhone={handleRemovePhone}
                      handleAddPhone={handleAddPhone}
                      handleChangeShowInputPhonesToTrue={handleChangeShowInputPhonesToTrue}
                      showInputPhones={showInputPhones}
                      handleChange={handleChange}
                      formState={formState}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.containerLabelInput}>
                      <InputConsensus
                        label="E-mail"
                        className={classes.inputMaterial}
                        type="email"
                        name="email"
                        autoComplete="no"
                        onChange={handleChange}
                        value={formState.values.email || ''}
                      />
                    </div>
                  </Grid>

                  {showFormInvestigated && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className={classes.containerLabelInput}>
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.npu || ''}
                            mask="9999999-99.9999.9.99.9999"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="NPU"
                                name="npu"
                                variant="standard"
                                className={classes.inputMaterial}
                              />
                            )}
                          </InputMask>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className={classes.containerLabelInput}>
                          <Autocomplete
                            value={formState.values.judgmentPlace}
                            onInputChange={(event, newInputValue) =>
                              updateValueDelegacias(newInputValue)
                            }
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={option => option.name}
                            filterOptions={x => x}
                            fullWidth
                            noOptionsText="Nada foi encontrado"
                            loadingText="Buscando..."
                            loading={loadingDelegacias}
                            clearOnBlur={false}
                            options={options}
                            onChange={handleChangeJudgmentPlace}
                            renderOption={option => <>{option.name}</>}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                label="Vara"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingDelegacias ? (
                                        <CircularProgress color="primary" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            ListboxProps={{
                              onScroll: handleScrollDelegacias,
                            }}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: 25,
              }}
            >
              <ButtonConsensus
                title={editInvestigatedInArray ? 'Alterar Pessoa' : 'Adicionar Pessoa'}
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                size="small"
                onClick={handleSubmit}
              />
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 25, paddingRight: 25 }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">CPF/CNPJ</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">RG</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {peoples.map((people, index) => (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      {people.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {people.taxpayerRegistry
                        ? formatedTaxpayerRegister(people.taxpayerRegistry)
                        : 'não informado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {people.peopleType === 'investigated'
                        ? 'Investigado'
                        : people.peopleType === 'victim'
                        ? 'Vítima'
                        : 'Representante Legal'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {people?.rg?.code || 'Não informado'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box style={{ marginRight: 10 }}>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => handleUpdateInvestigated(people, index)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={event => handleDeleteInvestigated(event, people, index)}
                          /*onClick={() => !permissionsReducer.inquiriesPapeis.write ?
                                                    handleOpenSnackbar() : openConfirm(investigated)}*/
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {openModalConfirm && (
          <ModalConfirm
            open={openModalConfirm}
            onClose={handleCloseModalConfirm}
            people={selectedInvestigated}
            onClick={deleteVictim}
          />
        )}
        <ModalInvestigatedsArquimedes
          open={openModalInvestigateds}
          onClose={() => setOpenModalInvestigateds(false)}
          setInvestigated={setInvestigated}
          investigateds={investigateds}
        />
      </Grid>
    </Box>
  );
};

export default InvestigatedsPending;
